export const objectDefault = {
  cant: 4,
  Combustible: "57",
  pistola: 32,
  valor: 37.11,
  TGalones: 0.791,
  fecha: "2025-04-02",
  totInicio: 177.18,
  totFinal: 191.07,
  nombreCombustible: "GASOLINA EXTRA",
  numeroTanque: 2,
};
export const columns = [
  {
    id: "nombreCombustible",
    label: "Nombre Combustible",
    minWidth: 25,
    tipo: "string",
    propiedad: "nombreCombustible",
  },
  {
    id: "numeroTanque",
    label: "Número Tanque",
    minWidth: 25,
    tipo: "number",
    propiedad: "numeroTanque",
  },
  {
    id: "cant",
    label: "Cantidad",
    minWidth: 25,
    tipo: "number",
    propiedad: "cant",
  },
  {
    id: "Combustible",
    label: "Código Combustible",
    minWidth: 25,
    tipo: "string",
    propiedad: "Combustible",
  },
  {
    id: "pistola",
    label: "Pistola",
    minWidth: 25,
    tipo: "number",
    propiedad: "pistola",
  },
  {
    id: "valor",
    label: "Valor",
    minWidth: 25,
    tipo: "number",
    propiedad: "valor",
  },
  {
    id: "TGalones",
    label: "Total Galones",
    minWidth: 25,
    tipo: "number",
    propiedad: "TGalones",
  },
  {
    id: "fecha",
    label: "Fecha",
    minWidth: 25,
    tipo: "string",
    propiedad: "fecha",
  },
  {
    id: "totInicio",
    label: "Total Inicio",
    minWidth: 25,
    tipo: "number",
    propiedad: "totInicio",
  },
  {
    id: "totFinal",
    label: "Total Final",
    minWidth: 25,
    tipo: "number",
    propiedad: "totFinal",
  },
];
export const columnasHijo = [
  {
    id: "pistola",
    align: "left",
    minWidth: 150,
    tipo: "string",
    label: "pistola",
  },
  {
    id: "producto",
    align: "left",
    minWidth: 150,
    tipo: "string",
    label: "producto",
  },
];
