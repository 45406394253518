import React, { memo, useState, useContext, useEffect, useRef } from "react";
import { Box } from "@mui/material";
import Tabla from "./Componentes/Tabla";
// import ModalB from "./Componentes/ModalB";
// import BarraTablas from "../../../components/BarraTablas/BarraTablas";
import BarraTablas from "../../components/Tablas/BarraTablas";
import ModalB from "../../components/Modal/ModalB";
import DialogOpciones from "../../components/Dialogs/DialogOpciones";
import { Add, Search } from "@mui/icons-material";
import { usePermiso } from "../../../hooks/usePermiso";
import AuthContext from "../../../Context/Auth/AuthContext";
import clienteAxios from "../../../config/axios";
import AlertaContext from "../../../Context/Alerta/AlertaContext";
import io from "socket.io-client";
import { socketUrl } from "../../../config/const";
const Categorias = (props) => {
  // const { llamadoDeOtroLugar, setopenMT } = props;
  const {
    idmascota,
    settab,
    setarrayExportHijos,
    desdeOtroLugarMascota,
    // puedeExportar,
    // itemRelacionadoPadre,
  } = props;
  // DATA DEL STATE
  useEffect(() => {
    if (desdeOtroLugarMascota) {
      settab("");
      setarrayExportHijos(arrayExport.map((item) => item[campoExport]));
    }
    // eslint-disable-next-line
  }, [arrayExport]);
  const [rows, setrows] = useState(null);
  const [openDialogOpciones, setopenDialogOpciones] = useState(false);
  const { tienePermiso, alertaPermiso } = usePermiso("CajaChica");
  const defaultFullBuscador = () => {
    const json = {};
    [
      ...columns,
      {
        id: "actaFechaInstalacion",
        label: "Fec. Ins.",
        minWidth: 25,
        tipo: "fecha",
        orden: 1,
        propiedad: "actaFechaInstalacion",
        align: "center",
        ocultar: false,
      },
    ].forEach((itemPropiedad) => {
      Object.entries({ itemPropiedad }).forEach((item) => {
        if (item.ocultar) {
          return;
        }
        if (itemPropiedad.tipo === "string") {
          json[`${item[1].propiedad}`] = "";
        } else if (itemPropiedad.tipo === "numero") {
          json[`${item[1].propiedad}1`] = "";
          json[`${item[1].propiedad}2`] = "";
        } else if (itemPropiedad.tipo === "fecha") {
          json[`${item[1].propiedad}1`] = "";
          json[`${item[1].propiedad}2`] = "";
        }
      });
    });
    return json;
  };
  const columns = [
    {
      id: "cuadUsuario",
      label: "Usuario",
      minWidth: 50,
      tipo: "string",

      propiedad: "cuadUsuario",
    },
    {
      id: "cuadfecha",
      label: "Fecha",
      minWidth: 50,
      tipo: "string",

      propiedad: "cuadfecha",
    },
    {
      id: "cuadMoneda1c",
      label: "1¢",
      minWidth: 50,
      tipo: "string",

      propiedad: "cuadMoneda1c",
    },
    {
      id: "cuadMoneda5c",
      label: "5¢",
      minWidth: 50,
      tipo: "string",

      propiedad: "cuadMoneda5c",
    },
    {
      id: "cuadMoneda10c",
      label: "10¢",
      minWidth: 50,
      tipo: "string",

      propiedad: "cuadMoneda10c",
    },
    {
      id: "cuadMoneda25c",
      label: "25¢",
      minWidth: 50,
      tipo: "string",

      propiedad: "cuadMoneda25c",
    },
    {
      id: "cuadMoneda50c",
      label: "50¢",
      minWidth: 50,
      tipo: "string",

      propiedad: "cuadMoneda50c",
    },
    {
      id: "cuadMoneda1d",
      label: "1$",
      minWidth: 50,
      tipo: "string",

      propiedad: "cuadMoneda1d",
    },
    {
      id: "cuadMoneda5d",
      label: "5$",
      minWidth: 50,
      tipo: "string",

      propiedad: "cuadMoneda5d",
    },
    {
      id: "cuadMoneda10d",
      label: "10$",
      minWidth: 50,
      tipo: "string",

      propiedad: "cuadMoneda10d",
    },
    {
      id: "cuadMoneda20d",
      label: "20$",
      minWidth: 50,
      tipo: "string",

      propiedad: "cuadMoneda20d",
    },
    {
      id: "cuadMoneda50d",
      label: "50$",
      minWidth: 50,
      tipo: "string",

      propiedad: "cuadMoneda50d",
    },
    {
      id: "cuadMoneda100d",
      label: "100$",
      minWidth: 50,
      tipo: "string",

      propiedad: "cuadMoneda100d",
    },
    {
      id: "cuadTotal",
      label: "Total",
      minWidth: 50,
      tipo: "string",

      propiedad: "cuadTotal",
    },
    {
      id: "cuadTotalCaja",
      label: "T. Caja",
      minWidth: 50,
      tipo: "string",

      propiedad: "cuadTotalCaja",
    },
    {
      id: "cuadDiferencia",
      label: "Diferencia",
      minWidth: 50,
      tipo: "string",

      propiedad: "cuadDiferencia",
    },
    {
      id: "cuadFecReg",
      label: "Fec Reg",
      minWidth: 50,
      tipo: "string",

      propiedad: "cuadFecReg",
    },
  ];

  const [ObjectoActivo, setObjetoActivo] = useState({
    cuadUsuario: "",
    cuadfecha: `${new Date().getFullYear()}-${(
      "0" +
      (new Date().getMonth() + 1)
    ).slice(-2)}-${("0" + new Date().getDate()).slice(-2)}`,
    cuadMoneda1c: "",
    cuadMoneda5c: "",
    cuadMoneda10c: "",
    cuadMoneda25c: "",
    cuadMoneda50c: "",
    cuadMoneda1d: "",
    cuadMoneda5d: "",
    cuadMoneda10d: "",
    cuadMoneda20d: "",
    cuadMoneda50d: "",
    cuadMoneda100d: "",
    cuadTotal: "",
    cuadTotalCaja: "",
    cuadDiferencia: "",
    cuadEmpresa: "",
    cuadUser: "",
    cuadFecReg: "",
    Todos: "",
  });
  const [pagination, setpagination] = useState(null);
  // FUNCIONES STATE
  const getDataState = async (
    cantidad,
    page,
    search,
    input,
    orden,
    datos,
    rucempresa,
    estado
  ) => {
    const res = await clienteAxios.get(
      `/cuadrecaja/?cantidad=${cantidad}&page=${page}&search=${
        search ? search : ""
      }&input=${input ? input : ""}&orden=${orden ? orden : ""}&datos=${
        datos ? datos : ""
      }&rucempresa=${rucempresa ? rucempresa : ""}&estado${
        estado ? estado : ""
      }`
    );

    return res;
  };

  const obtenerState = async (
    cantidad = 10,
    page = 0,
    search,
    input,
    orden,
    datos,
    rucempresa,
    estado
  ) => {
    const res = await getDataState(
      cantidad,
      page,
      search,
      input,
      orden,
      datos,
      rucempresa,
      estado
    );
    setrows(res.data.data.results);
    setpagination(res.data.data.pagination);
  };
  const addRowsState = async (items) => {
    setrows([...rows, ...items]);
  };
  const remplazarRowsState = async (
    cantidad,
    page,
    search,
    input,
    orden,
    datos,
    rucempresa,
    estado
  ) => {
    const res = await getDataState(
      cantidad,
      page,
      search,
      input,
      orden,
      datos,
      rucempresa,
      estado
    );
    setrows(res.data.data.results);
    setpagination(res.data.data.pagination);
    // dispatch({
    //   type: OBTENER,
    //   payload: {
    //     results: res.data.data.results,
    //     pagination: res.data.data.pagination,
    //   },
    // });
  };
  const agregarState = async (item) => {
    try {
      setrows([item, ...rows]);

      setpagination({
        ...pagination,
        numRows: pagination.numRows + 1,
      });
    } catch (error) {}
  };

  const editarState = async (item) => {
    try {
      const resRows = rows.map((itemR) => {
        if (itemR.cuadId === item.cuadId) {
          return item;
        } else {
          return itemR;
        }
      });

      setrows(resRows);
    } catch (error) {}
  };

  const eliminarState = async (_id) => {
    try {
      const resRows = rows.filter((row) => {
        return row.cuadId !== _id;
      });
      setrows(resRows);
      setpagination({
        ...pagination,
        numRows: pagination.numRows - 1,
      });
    } catch (error) {}
  };
  const eliminarSeleccionState = async (_ids) => {
    const resRows = rows.filter((row) => {
      return !_ids.some((item) => {
        return item === row.cuadId;
      });
    });
    setpagination({
      ...pagination,
      numRows: pagination.numRows - _ids.length,
    });
    setrows(resRows);
  };

  const changeObjetoActivoState = (item) => {
    setObjetoActivo(item);
    // dispatch({
    //   type: CHANGE_ACTIVO,
    //   payload: item,
    // });
  };

  const rowsImportState = (rows) => {
    // dispatch({
    //   type: IMPORTAR_ROWS,
    //   payload: rows,
    // });
    setrows(rows);
  };
  // FIN FUNCIONES STATE
  // FIN DATA STATE

  // FUNCIONES SOCKET
  const [tabla] = useState("cuadrecaja");
  const [itemSocket, setitemSocket] = useState({
    tipo: "",
    item: {},
  });
  const socket = useRef();
  useEffect(() => {
    // Creates a WebSocket connection
    socket.current = io(socketUrl);
    socket.current.on("server:guardadoExitoso", (data) => {
      setitemSocket({
        tipo: "agregar",
        item: data,
      });
    });
    socket.current.on("server:actualizadoExitoso", (data) => {
      setitemSocket({
        tipo: "editar",
        item: data,
      });
    });
    socket.current.on("server:eliminadoExitoso", (data) => {
      setitemSocket({
        tipo: "eliminar",
        item: data,
      });
    });
    socket.current.on("server:eliminadoExitosoSeleccionado", (data) => {
      setitemSocket({
        tipo: "eliminarSeleccion",
        item: data,
      });
    });

    socket.current.on("server:error", (data) => {
      mostrarAlerta(
        data.msg ? data.msg : "Hubo un error",
        data.alert ? data.alert : "error"
      );
    });
    return () => {
      socket.current.disconnect();
    };
    // Destroys the socket reference
    // when the connection is closed
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    socket.current.on("server:guardadoExitoso", (data) => {
      setitemSocket({
        tipo: "agregar",
        item: data,
      });
    });
    socket.current.on("server:actualizadoExitoso", (data) => {
      setitemSocket({
        tipo: "editar",
        item: data,
      });
    });
    socket.current.on("server:eliminadoExitoso", (data) => {
      setitemSocket({
        tipo: "eliminar",
        item: data,
      });
    });
    socket.current.on("server:eliminadoExitosoSeleccionado", (data) => {
      setitemSocket({
        tipo: "eliminarSeleccion",
        item: data,
      });
    });

    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    const { item, tipo } = itemSocket;

    if (tipo === "") {
      return;
    }
    if (!rows) {
      return;
    }
    if (usuario.rucempresa !== item.rucempresa) {
      return;
    }
    if (item.tabla !== tabla) {
      return;
    }

    if (desdeOtroLugarMascota && Number(item.hcliMascId) !== idmascota) {
      return;
    }
    const funcionAalerta = () => {
      if (item.msg === "") {
        return null;
      }
      if (!item.msg) {
        return null;
      }
      mostrarAlerta(item.msg, item.alert ? item.alert : "success");
    };

    if (tipo === "agregar") {
      agregarState(item);
    }
    if (tipo === "editar") {
      editarState(item);
    }
    if (tipo === "eliminar") {
      eliminarState(item.cuadId);
    }
    if (tipo === "eliminarSeleccion") {
      eliminarSeleccionState(item.lista);
    }
    funcionAalerta();
    setitemSocket({ tipo: "", item: {} });
    // eslint-disable-next-line
  }, [itemSocket]);

  const {
    usuario,
    usuario: { rucempresa },
  } = useContext(AuthContext);
  const { mostrarAlerta } = useContext(AlertaContext);
  const [arrayExport, setarrayExport] = useState([]);
  const [campoExport] = useState("cuadId");
  const [buscando, setbuscando] = useState(false);
  const [search, setsearch] = useState("");
  const [cargando, setcargando] = useState(false);
  const [agregar, setagregar] = useState(false);
  const [input, setinput] = useState({
    id: "cuadId",
    label: "ID",
    minWidth: 150,
    tipo: "string",

    propiedad: "cuadId",
  });
  const [orden, setorden] = useState(false);
  const [openMB, setopenMB] = useState(false);
  const [fullBuscador, setfullBuscador] = useState({
    cuadFecReg1: "",
    cuadFecReg2: "",
  });

  //pagina actual
  const [page, setPage] = React.useState(0);
  //filas por pagina
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  // input
  //
  const handleChange = (e) => {
    setfullBuscador({ ...fullBuscador, [e.target.name]: e.target.value });
  };
  const funcionB = async () => {
    setcargando(true);
    await remplazarRowsState(
      rowsPerPage,
      0,
      search,
      input.id,
      orden,
      JSON.stringify(fullBuscador),
      rucempresa
    );
    setPage(0);
    setbuscando(true);
    setopenMB(false);
    setcargando(false);
  };

  const handleSearch = async () => {
    setcargando(true);
    setPage(0);
    await remplazarRowsState(
      rowsPerPage,
      0,
      search,
      input.id,
      orden,
      JSON.stringify(fullBuscador),
      rucempresa
    );
    setcargando(false);
  };
  const funcionDefault = async () => {
    setcargando(true);
    setfullBuscador({
      cuadFecReg1: "",
      cuadFecReg2: "",
    });
    await remplazarRowsState(
      rowsPerPage,
      0,
      "",
      input.id,
      orden,
      JSON.stringify({
        cuadFecReg1: "",
        cuadFecReg2: "",
      }),
      rucempresa
    );
    setPage(0);
    setsearch("");
    setbuscando(false);
    setcargando(false);
    setopenMB(false);
  };

  return (
    <Box>
      <DialogOpciones
        open={openDialogOpciones}
        setopen={setopenDialogOpciones}
        botones={[
          {
            tooltip: "Agregar",
            funcion: () => {
              setagregar(true);
            },
            Icon: Add,
            color: "primary",
            id: 1,
            disabled: false,
          },
        ]}
        titulo={"Mas Opciones"}
      />
      <BarraTablas
        search={search}
        setsearch={setsearch}
        funcion={handleSearch}
        buscando={buscando}
        setbuscando={setbuscando}
        LabelBuscador="Buscar por todos los campos"
        funcionDefault={funcionDefault}
        ocultarMigasDePan={false}
        botones={[
          {
            tooltip: "Agregar",
            texto: "Agregar",
            funcion: () => {
              setagregar(true);
            },
            disabled: false,
            Icon: Add,
            color: "primary",
            id: 1,
            ocultar: true,
            tipo: "icono",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Agregar",
            texto: "Agregar",
            funcion: () => {
              if (!tienePermiso("agregar")) {
                return alertaPermiso("agregar");
              }
              setagregar(true);
            },
            disabled: false,
            Icon: Add,
            color: "primary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
          {
            tooltip: "Busqueda avanzada",
            texto: "",
            funcion: () => {
              setopenMB(true);
            },
            disabled: false,
            Icon: Search,
            color: "success",
            id: 2,
            ocultar: false,
            tipo: "icono",
            variante: "contained",
            size: "medium",
            sx: {
              ml: 1,
            },
          },
        ]}
      />
      <ModalB
        defaultFullBuscador={defaultFullBuscador}
        openMB={openMB}
        setopenMB={setopenMB}
        titulo={"Busqueda avanzada (Caja General)"}
        fullBuscador={fullBuscador}
        setfullBuscador={setfullBuscador}
        arrayProps={[...columns]}
        handleChange={handleChange}
        funcionB={funcionB}
        buscando={buscando}
        funcionDefault={funcionDefault}
      />
      {/* <BarraTablas
        setopen={setagregar}
        ocultarAgregar={false}
        search={search}
        setsearch={setsearch}
        funcion={handleSearch}
        buscando={buscando}
        setbuscando={setbuscando}
        disabled={false}
        setopenMB={setopenMB}
        LabelBuscador={"Buscar"}
        ocultarBuscar={false}
        ocultarMigasDePan={false}
        funcionDefault={funcionDefault}
      />{" "}
      <ModalB
        openMB={openMB}
        setopenMB={setopenMB}
        titulo={"Busqueda avanzada (Categoría)"}
        columns={columns}
        fullBuscador={fullBuscador}
        setfullBuscador={setfullBuscador}
        handleChange={handleChange}
        funcionB={funcionB}
        buscando={buscando}
        setbuscando={setbuscando}
        funcionDefault={funcionDefault}
      />{" "} */}
      <Tabla
        agregar={agregar}
        setagregar={setagregar}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        rows={rows}
        search={search}
        input={input}
        orden={orden}
        setinput={setinput}
        setorden={setorden}
        cargando={cargando}
        setcargando={setcargando}
        fullBuscador={fullBuscador}
        // STATE
        addRowsState={addRowsState}
        getDataState={getDataState}
        remplazarRowsState={remplazarRowsState}
        obtenerState={obtenerState}
        pagination={pagination}
        columns={columns}
        ObjectoActivo={ObjectoActivo}
        changeObjetoActivoState={changeObjetoActivoState}
        rowsImportState={rowsImportState}
        // export
        arrayExport={arrayExport}
        setarrayExport={setarrayExport}
        campoExport={campoExport}
        socket={socket}
      />
    </Box>
  );
};

export default memo(Categorias);
