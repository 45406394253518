import React, { useState, useContext, useEffect, memo } from "react";

import Draggable from "react-draggable";
// eslint-disable-next-line
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
import AuthContext from "../../../../Context/Auth/AuthContext";
import { trimPropsItem } from "../../../../config/const";
// MATERIAL
import { makeStyles } from "@mui/styles";
import {
  Modal,
  Box,
  Button,
  Divider,
  Typography,
  TextField,
  MenuItem,
  FormControlLabel,
  Switch,
  IconButton,
} from "@mui/material";
import { Cancel } from "@mui/icons-material";

function getModalStyle() {
  return {
    borderRadius: "15px",
    position: "absolute",
    overflow: "scroll",
    maxHeight: "95vh",
  };
}
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "60%",
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    borderRadius: "15px",
    marginTop: "5vh",
    [theme.breakpoints.down("sm")]: { width: "90%", marginTop: "2vh" },
  },
  margin: {
    margin: theme.spacing(1),
    width: "45%",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  ocultar: { display: "none" },
  inputPadding: {
    padding: theme.spacing(0.5),
    margin: theme.spacing(0),
    fontSize: "1rem",
  },
  formD: {
    flexGrow: 1,
    width: "45%",
    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  formAllW: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "95%",
    [theme.breakpoints.down("md")]: { padding: "0", margin: "2px" },
    [theme.breakpoints.down("sm")]: { padding: "0", margin: "2px" },
  },
  large: {
    width: theme.spacing(13),
    height: theme.spacing(13),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    "&:hover": { opacity: ".7" },
  },
  center: { justifyContent: "center", alignItems: "center" },
  root: { width: "100%", marginTop: theme.spacing(1) },
  correo: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "45%",
    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  error: { color: "red", fontWeight: "bold" },
}));
const ModalAsistencia = (props) => {
  const { mostrarAlerta } = useContext(AlertaContext);
  const { usuario } = useContext(AuthContext); //props
  const {
    open,
    setopen,
    editar,
    seteditar,
    ObjectoActivo,
    changeObjetoActivoState,
    socket,
  } = props;
  // eslint-disable-next-line
  const [item, setitem] = useState(ObjectoActivo);

  const [cargando, setcargando] = useState(false);

  const {
    cajaTipoCaja,
    cajaTipoDocumento,
    cajaMonto,
    cajaAutorizacion,
    cajaDetalle,
  } = item;
  // eslint-disable-next-line
  const [error, seterror] = useState({
    contaTipo: false,
    contaRuc: false,
    contaNombre: false,
    contaCuenta: false,
    contaDebe: false,
    contaHaber: false,
  }); // TABLA

  useEffect(() => {
    setitem(ObjectoActivo); // eslint-disable-next-line
  }, [open]);
  const defaultActive = () => {
    changeObjetoActivoState({
      cajaNumero: "",
      cajaFecha: `${new Date().getFullYear()}-${(
        "0" +
        (new Date().getMonth() + 1)
      ).slice(-2)}-${("0" + new Date().getDate()).slice(-2)}`,
      cajaDetalle: "",
      cajaIngreso: "",
      cajaEgreso: "",
      cajaCredito: "",
      cajaMonto: "",
      cajaTipoCaja: "EFECTIVO",
      cajaTipoDocumento: "INGRESO",
      cajaEstado: "ACTIVO",
      cajaProcedencia: "",
      cajaAutorizacion: "",
      cajaEmpresa: "",
      cajaUser: "",
      cajaFecReg: "",
      Todos: "",
    });
    setopen(false);
    seteditar(false);
    seterror({});

    setcargando(false);
  };
  const handleChange = (e) => {
    setitem({
      ...item,
      [e.target.name]: e.target.value,
    });
  };

  //hook de estilos
  const clases = useStyles();
  //definiendo los estilos del modal

  const [modalStyle] = useState(getModalStyle);
  // obteniendo los años
  const validacion = () => {
    if (
      cajaTipoCaja.trim() === "" ||
      cajaTipoDocumento.trim() === "" ||
      cajaMonto.trim() === "" ||
      cajaDetalle.trim() === ""
    ) {
      seterror({
        cajaTipoCaja: cajaTipoCaja.trim() === "" ? true : false,
        cajaTipoDocumento: cajaTipoDocumento.trim() === "" ? true : false,
        cajaMonto: cajaMonto.trim() === "" ? true : false,
        cajaDetalle: cajaDetalle.trim() === "" ? true : false,
      });
      return true;
    } else {
      return false;
    }
  };
  const handleCrear = async () => {
    if (validacion()) {
      return mostrarAlerta("Faltan campos obligatorios", "error");
    }
    try {
      setcargando(true);
      console.log({
        ...item,
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        tabla: "caja",
        cajaUser: usuario.usuario,
        cajaEmpresa: usuario.rucempresa,
      });

      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...item,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          tabla: "caja",
          cajaUser: usuario.usuario,
          cajaEmpresa: usuario.rucempresa,
        })
      );
      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);
      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  const handleEditar = async () => {
    if (validacion()) {
      return mostrarAlerta("Faltan campos obligatorios", "error");
    }
    try {
      setcargando(true);
      socket.current.emit(
        "client:actualizarData",
        trimPropsItem({
          ...item,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          tabla: "caja",
          cajaUser: usuario.usuario,
          cajaEmpresa: usuario.rucempresa,
        })
      );
      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);
      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  //filas por pagina

  // 1 + 2 + 3 + 4

  if (!open) {
    return null;
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <Draggable handle="#handle">
          <Box display="flex" justifyContent="center">
            <div style={modalStyle} className={clases.paper}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography
                  style={{ cursor: "-webkit-grab" }}
                  id="handle"
                  variant="button"
                  color="initial"
                  align="center"
                >
                  {editar ? "Editar" : "Agregar"}
                  {" Caja General"}
                </Typography>
                <Box display="flex" alignItems="center">
                  <IconButton
                    aria-label=""
                    style={{ margin: "0px" }}
                    onClick={() => {
                      defaultActive();
                    }}
                  >
                    <Cancel color="secondary" />
                  </IconButton>
                </Box>
              </Box>
              <Divider />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems=" stretch"
                width="100%"
              >
                <Box> </Box>

                <FormControlLabel
                  control={
                    <Switch
                      checked={
                        item.cajaEstado === "ACTIVO" ||
                        item.cajaEstado === "ACTIVA"
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        setitem({
                          ...item,
                          cajaEstado: e.target.checked ? "ACTIVO" : "INACTIVO",
                        });
                      }}
                      name="antoine"
                    />
                  }
                  label="Estado"
                />
              </Box>
              <Divider />
              <Box
                display="flex"
                flexWrap="wrap"
                alignItems="flex-end"
                borderRadius="0px 50px 50px 0px"
              >
                <>
                  <TextField
                    disabled={editar}
                    value={cajaTipoCaja}
                    error={error.cajaTipoCaja}
                    name="cajaTipoCaja"
                    className={clases.formD}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    size="small"
                    label="Tipo"
                    fullWidth
                    select
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ displayEmpty: true }}
                  >
                    <MenuItem value="">Seleccione </MenuItem>
                    {["EFECTIVO", "CHEQUE", "TRANSFERENCIA", "DEPOSITO"].map(
                      (item) => {
                        return <MenuItem value={item}>{item} </MenuItem>;
                      }
                    )}
                  </TextField>
                  <TextField
                    disabled={editar}
                    value={cajaTipoDocumento}
                    error={error.cajaTipoDocumento}
                    name="cajaTipoDocumento"
                    className={clases.formD}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    size="small"
                    label="Tipo Documento"
                    fullWidth
                    select
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ displayEmpty: true }}
                  >
                    <MenuItem value="">Seleccione </MenuItem>
                    {[
                      "APERTURA",
                      "INGRESO",
                      "EGRESO",
                      "DEPOSITO",
                      "CAJA CHICA",
                      "TRANSFERENCIA",
                    ].map((item) => {
                      return <MenuItem value={item}>{item} </MenuItem>;
                    })}
                  </TextField>
                  <TextField
                    disabled={editar}
                    type="number"
                    value={cajaMonto}
                    error={error.cajaMonto}
                    name="cajaMonto"
                    className={clases.formD}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    size="small"
                    label="Monto"
                    fullWidth
                    autoComplete="false"
                  />
                  <TextField
                    disabled={editar}
                    value={cajaAutorizacion}
                    error={error.cajaAutorizacion}
                    name="cajaAutorizacion"
                    className={clases.formD}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    size="small"
                    label="Autorización"
                    fullWidth
                  />
                  <TextField
                    disabled={editar}
                    variant="outlined"
                    multiline
                    rows={3}
                    value={cajaDetalle}
                    error={error.cajaDetalle}
                    name="cajaDetalle"
                    className={clases.formD}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    size="small"
                    label="Detalle"
                    fullWidth
                  />
                </>
              </Box>
              {/* <Divider /> */}
              <Box
                display="flex"
                width="100%"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box
                  width="100%"
                  display="flex"
                  justifyContent="flex-end"
                  mt={1}
                >
                  <Button
                    style={{ width: "30%" }}
                    variant="contained"
                    color="primary"
                    disabled={cargando}
                    onClick={() => {
                      if (editar) {
                        handleEditar();
                      } else {
                        handleCrear();
                      }
                    }}
                  >
                    Guardar
                  </Button>
                </Box>
              </Box>
            </div>
          </Box>
        </Draggable>
      </Box>
    </Modal>
  );
};

export default memo(ModalAsistencia);
