import React, { memo, useContext } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  IconButton,
  MenuItem,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";

import { Cancel, Check } from "@mui/icons-material";

import { useState } from "react";

import AuthContext from "../../../../Context/Auth/AuthContext";
import { replaceCaracteres, trimPropsItem } from "../../../../config/const";
const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  margin: {
    margin: "0px",
    padding: "2px",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));

const RowAgregarItem = (props) => {
  // eslint-disable-next-line
  const { bancos, item, setitem, setagregar, socket } = props;
  const [itemNuevo, setItemNuevo] = useState({
    cpTipo: "EFECTIVO",
    cpBanco: "",
    cpNumero: "0",
    cpDeposito: "NO",
    cpValor: "1.00",
    cpFechaAbono: `${new Date().getFullYear()}-${(
      "0" +
      (new Date().getMonth() + 1)
    ).slice(-2)}-${("0" + new Date().getDate()).slice(-2)}`,
    cpDetalle: "",
    cpProcedencia: "",
    cpEstado: "ACTIVO",
    imprimir: false,
  });

  const classes = useRowStyles();
  //gql eliminar user

  const { usuario } = useContext(AuthContext);
  const handleAgregar = async () => {
    socket.current.emit(
      "client:actualizarData",
      trimPropsItem({
        ...item,
        cpPagos: [itemNuevo, ...item.cpPagos],
        tabla: "cuentasporpagar",
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        cpUser: usuario.usuario,
        cpEmpresa: usuario.rucempresa,
      })
    );

    try {
    } catch (error) {}
  };
  const handleChange = (e) => {
    const res = replaceCaracteres(e.target.value);
    setItemNuevo({
      ...itemNuevo,
      [e.target.name]: res,
    });
  };
  const botonDisabled = () => {
    if (
      itemNuevo.cpTipo === "" ||
      itemNuevo.cpDeposito === "" ||
      itemNuevo.cpValor === "" ||
      itemNuevo.cpFechaAbono === ""
    ) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            alignItems="center"
          >
            <IconButton
              className={classes.margin}
              onClick={() => {
                setagregar(false);
                setItemNuevo({
                  cpTipo: "EFECTIVO",
                  cpBanco: "",
                  cpNumero: "0",
                  cpDeposito: "NO",
                  cpValor: "1.00",
                  cpFechaAbono: `${new Date().getFullYear()}-${(
                    "0" +
                    (new Date().getMonth() + 1)
                  ).slice(-2)}-${("0" + new Date().getDate()).slice(-2)}`,
                  cpDetalle: "",
                  cpProcedencia: "",
                  cpEstado: "ACTIVO",
                  imprimir: false,
                });
              }}
            >
              <Cancel fontSize="small" color="secondary" />
            </IconButton>
            <IconButton
              color={
                botonDisabled
                  ? botonDisabled()
                    ? "inherit"
                    : "secondary"
                  : "secondary"
              }
              disabled={botonDisabled()}
              className={classes.margin}
              onClick={() => {
                handleAgregar();
                setagregar(false);
                setItemNuevo({
                  cpTipo: "EFECTIVO",
                  cpBanco: "",
                  cpNumero: "0",
                  cpDeposito: "NO",
                  cpValor: "1.00",
                  cpFechaAbono: `${new Date().getFullYear()}-${(
                    "0" +
                    (new Date().getMonth() + 1)
                  ).slice(-2)}-${("0" + new Date().getDate()).slice(-2)}`,
                  cpDetalle: "",
                  cpProcedencia: "",
                  cpEstado: "ACTIVO",
                  imprimir: false,
                });
              }}
            >
              <Check fontSize="small" />
            </IconButton>{" "}
          </Box>
        </TableCell>
        {/* las demas columnas */}{" "}
        <TableCell fontSize="small" padding="none" align="center">
          <TextField
            id=""
            value={itemNuevo.cpTipo}
            name="cpTipo"
            onChange={(e) => handleChange(e)}
            helperText="Agregando"
            size="small"
            select
            InputLabelProps={{ shrink: true }}
            SelectProps={{ displayEmpty: true }}
            fullWidth
          >
            <MenuItem value="">Seleccione Tipo</MenuItem>
            {[
              "EFECTIVO",
              "CHEQUE",
              "TRANSFERENCIA",
              "DEPOSITO",
              "TARJETA",
            "CRUCE COMPRA", "DEBITO BANCARIO", "DEBITO BANCARIO",
              "DONACION",
            ].map((itemTipo, index) => {
              return (
                <MenuItem key={index} value={itemTipo}>
                  {itemTipo}{" "}
                </MenuItem>
              );
            })}
          </TextField>{" "}
        </TableCell>
        <TableCell fontSize="small" padding="none" align="center">
          <TextField
            type="number"
            id=""
            inputProps={{ style: { textAlign: "center" } }}
            value={itemNuevo.cpNumero}
            name="cpNumero"
            onChange={(e) => handleChange(e)}
            helperText="Agregando"
            size="small"
            fullWidth
          />
        </TableCell>
        <TableCell fontSize="small" padding="none" align="center">
          <TextField
            id=""
            value={itemNuevo.cpBanco}
            name="cpBanco"
            onChange={(e) => handleChange(e)}
            helperText="Agregando"
            size="small"
            select
            InputLabelProps={{ shrink: true }}
            SelectProps={{ displayEmpty: true }}
            fullWidth
          >
            <MenuItem value="">Seleccione Banco</MenuItem>
            {bancos.map((cpPago) => {
              return (
                <MenuItem key={cpPago.banId} value={cpPago.banNombre}>
                  {cpPago.banNombre}{" "}
                </MenuItem>
              );
            })}
          </TextField>{" "}
        </TableCell>
        <TableCell fontSize="small" padding="none" align="center">
          <TextField
            id=""
            value={itemNuevo.cpDeposito}
            name="cpDeposito"
            onChange={(e) => handleChange(e)}
            helperText="Agregando"
            size="small"
            select
            InputLabelProps={{ shrink: true }}
            SelectProps={{ displayEmpty: true }}
            fullWidth
          >
            <MenuItem value="">Seleccione Tipo</MenuItem>
            {["SI", "NO"].map((itemcpDeposito, index) => {
              return (
                <MenuItem key={index} value={itemcpDeposito}>
                  {itemcpDeposito}{" "}
                </MenuItem>
              );
            })}
          </TextField>{" "}
        </TableCell>
        <TableCell fontSize="small" padding="none" align="center">
          <TextField
            id=""
            type="date"
            inputProps={{ style: { textAlign: "center" } }}
            value={itemNuevo.cpFechaAbono}
            name="cpFechaAbono"
            onChange={(e) => handleChange(e)}
            helperText="Agregando"
            size="small"
            fullWidth
          />
        </TableCell>
        <TableCell fontSize="small" padding="none" align="center">
          <TextField
            id=""
            inputProps={{ style: { textAlign: "center" } }}
            type="number"
            value={itemNuevo.cpValor}
            name="cpValor"
            onChange={(e) => handleChange(e)}
            helperText="Agregando"
            size="small"
            fullWidth
          />
        </TableCell>
        <TableCell fontSize="small" padding="none" align="center">
          <TextField
            id=""
            value={itemNuevo.cpDetalle}
            name="cpDetalle"
            onChange={(e) => handleChange(e)}
            helperText="Agregando"
            size="small"
            fullWidth
          />
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default memo(RowAgregarItem);
