const dosDecimales = (numero) => {
  return Number(numero.toFixed(2));
};

const tresDecimales = (numero) => {
  return Number(numero.toFixed(3));
};

function sumarCantidad(acumulador, valorActual) {
  let venSub0y12 =
    valorActual.llevaIva === "SI"
      ? {
          venSubTotal12: dosDecimales(
            acumulador.venSubTotal12 +
              Number(valorActual.precioSubTotalProducto)
          ),
          venSubtotal0: acumulador.venSubtotal0,
        }
      : {
          venSubTotal12: acumulador.venSubTotal12,
          venSubtotal0: dosDecimales(
            acumulador.venSubtotal0 + Number(valorActual.precioSubTotalProducto)
          ),
        };
  return {
    ...venSub0y12,
    venDescuento: dosDecimales(
      acumulador.venDescuento +
      Number(valorActual.descuento) * valorActual.cantidad
    ),
    venSubTotal: dosDecimales(
      acumulador.venSubTotal + Number(valorActual.precioSubTotalProducto)
    ),
    venTotalIva: dosDecimales(
      acumulador.venTotalIva + Number(valorActual.valorIva)
    ),
    venTotal: dosDecimales(
      acumulador.venTotal +
        Number(valorActual.precioSubTotalProducto) +
        Number(valorActual.valorIva)
    ),
    venCostoProduccion: dosDecimales(
      acumulador.venCostoProduccion + Number(valorActual.costoProduccion)
    ),
  };
}

export const calcularTotales = (productos) => {
  let inicial = {
    venSubTotal12: 0,
    venSubtotal0: 0,
    venDescuento: 0,
    venSubTotal: 0,
    venTotalIva: 0,
    venTotal: 0,
    venCostoProduccion: 0,
  };
  let resultado = productos.reduce(sumarCantidad, inicial);

  const resvenSubTotal12 = dosDecimales(Number(resultado.venSubTotal12));
  const resvenSubtotal0 = dosDecimales(Number(resultado.venSubtotal0));
  const resvenDescuento = dosDecimales(Number(resultado.venDescuento));
  const resvenSubTotal = dosDecimales(Number(resultado.venSubTotal));
  const resvenTotalIva = dosDecimales(Number(resultado.venTotalIva));
  const resvenTotal = dosDecimales(
    resvenSubTotal12 + resvenTotalIva + resvenSubtotal0
  );
  const resvenCostoProduccion = dosDecimales(Number(resultado.venCostoProduccion));

  return {
    venSubTotal12: resvenSubTotal12,
    venSubtotal0: resvenSubtotal0,
    venDescuento: resvenDescuento,
    venSubTotal: resvenSubTotal,
    venTotalIva: resvenTotalIva,
    venTotal: resvenTotal,
    venCostoProduccion: resvenCostoProduccion,
  };
};