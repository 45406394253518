import React, {
  memo,
  useState,
  useContext,
  useEffect,
  useRef,
  createRef,
} from "react";
import { Box, Button, Divider } from "@mui/material";
import Tabla from "./Componentes/Tabla";
import Modal from "./Componentes/Modal";
import ModalB from "../../../components/Extra/ModalB";
import clienteAxios from "../../../config/axios";
import AlertaContext from "../../../Context/Alerta/AlertaContext";
// import ModalATS from "./Componentes/ModalATS";
import AuthContext from "../../../Context/Auth/AuthContext";
import ModalInventario from "../../Configuración/Inventario/Componentes/Modal";
import ModalProveedor from "../../Compras/Proveedores/Personas_Contable/Componentes/Modal";
import ModalProveedorISP from "../../Compras/Proveedores/Personas_ISP/Componentes/Modal";
import ModalProveedorVet from "../../Veterinaria/Personas_Veterinaria/Componentes/Modal";
import ModalProveedorGas from "../../Gasolinera/Personas_Gasolinera/Componentes/Modal";
import ModalTraido from "./ModalTraido";
import Categorias from "../../Configuración/Categorias//Categorias";
import Subcategorias from "../../Configuración/Subcategorias/Subcategorias";
import Axios from "axios";
import io from "socket.io-client";
import { socketUrl } from "../../../config/const";
import { usePermiso } from "../../../hooks/usePermiso";
import BarraTablas from "../../components/Tablas/BarraTablas";
import { Add, Menu, Outbox, Search } from "@mui/icons-material";
import DialogOpciones from "../../components/Dialogs/DialogOpciones";

const Facturacion = (props) => {
  const { tienePermiso } = usePermiso("Facturacion");
  const {
    documento,
    settab,
    setarrayExportHijos,
    desdeOtroLugarPersona,
    itemRelacionadoPadre,
  } = props;

  useEffect(() => {
    if (desdeOtroLugarPersona) {
      settab("");
      setarrayExportHijos(arrayExport.map((item) => item[campoExport]));
    }
    // eslint-disable-next-line
  }, [arrayExport]);
  // DATA DEL STATE

  const [rows, setrows] = useState(null);

  const columns = [
    {
      id: "venNumFactura",
      label: "Documento",
      minWidth: 100,
      tipo: "string",
      propiedad: "venNumFactura",
    },
    {
      id: "venRucCliente",
      label: "Ruc. Cliente",
      minWidth: 100,
      tipo: "string",
      propiedad: "venRucCliente",
    },
    {
      id: "venNomCliente",
      label: "Cliente",
      minWidth: 100,
      tipo: "string",
      propiedad: "venNomCliente",
    },
    {
      id: "venFormaPago",
      label: "F. Pago",
      minWidth: 100,
      tipo: "string",
      variante: "autocompleteDataDefault",
      propiedadesMostrar: 1,
      campoData1: "fpagoNombre",
      getData: async () => {
        const res = await clienteAxios.get("/formaPagos/filtro/0");
        return res;
      },
      propiedad: "venFormaPago",
    },
    {
      id: "venFechaFactura",
      label: "Fecha",
      minWidth: 100,
      tipo: "fecha",
      propiedad: "venFechaFactura",
    },
    {
      id: "venFacturaCredito",
      label: "Crédito",
      minWidth: 100,
      tipo: "string",
      variante: "autocompleteComun",
      data: ["SI", "NO"],
      propiedad: "venFacturaCredito",
    },
    {
      id: "venTotal",
      label: "Total",
      minWidth: 100,
      tipo: "numero",
      propiedad: "venTotal",
    },
    {
      id: "venEstado",
      label: "Estado",
      minWidth: 100,
      tipo: "string",
      propiedad: "venEstado",
    },
    {
      id: "venAutorizacion",
      label: "Autorización",
      minWidth: 100,
      tipo: "string",
      propiedad: "venAutorizacion",
    },
    {
      id: "venEnvio",
      label: "Envio",
      minWidth: 100,
      tipo: "string",
      propiedad: "venEnvio",
    },
    {
      id: "venObservacion",
      label: "Observación",
      minWidth: 100,
      tipo: "string",
      propiedad: "venObservacion",
    },
    {
      id: "venUser",
      label: "User",
      minWidth: 100,
      tipo: "string",
      propiedad: "venUser",
    },
  ];
  const defaultFullBuscador = () => {
    const json = {};
    columns.forEach((itemPropiedad) => {
      Object.entries({ itemPropiedad }).forEach((item) => {
        if (itemPropiedad.tipo === "string") {
          json[`${item[1].propiedad}`] = "";
        } else if (
          itemPropiedad.tipo === "numero" ||
          itemPropiedad.tipo === "fecha"
        ) {
          json[`${item[1].propiedad}1`] = "";
          json[`${item[1].propiedad}2`] = "";
        }
      });
    });

    return json;
  };

  const [ObjectoActivo, setObjetoActivo] = useState({
    venKilometrajeInicio: "",
    venKilometrajeFinal: "",
    venEmpRegimen: "",
    venProductosAntiguos: [],
    optionDocumento: "",
    venTotalRetencion: "0.00",
    venOption: "1",
    venTipoDocumento: "F",
    venIdCliente: "1",
    venRucCliente: "9999999999999",
    venTipoDocuCliente: "RUC",
    venNomCliente: "CONSUMIDOR FINAL",
    venEmailCliente: ["sin@sincorreo.com"],
    venTelfCliente: "0000000001",
    venCeluCliente: "0000000001",
    venDirCliente: "s/n",
    venEmpRuc: "",
    venEmpNombre: "",
    venEmpComercial: "",
    venEmpDireccion: "",
    venEmpTelefono: "",
    venEmpEmail: "",
    venEmpObligado: "",
    venFormaPago: "TRANSFERENCIA",
    venNumero: "0",
    venFacturaCredito: "NO",
    venDias: "0",
    venAbono: "0",
    venDescPorcentaje: "0",
    venOtrosDetalles: "",
    venObservacion: "",
    venSubTotal12: "",
    venSubtotal0: "",
    venDescuento: "",
    venSubTotal: "",
    venTotalIva: "",
    venTotal: "0.00",
    venCostoProduccion: "",
    venUser: "",
    venFechaFactura: `${new Date().getFullYear()}-${(
      "0" +
      (new Date().getMonth() + 1)
    ).slice(-2)}-${("0" + new Date().getDate()).slice(-2)}`,
    venNumFactura: "",
    venNumFacturaAnterior: "",
    venAutorizacion: "0",
    venFechaAutorizacion: "",
    venErrorAutorizacion: "NO",
    venEstado: "ACTIVA",
    venEnvio: "NO",
    fechaSustentoFactura: "",
    venEmpresa: "",
    venProductos: [
      // {
      //   id: "a77eaa76-4e8e-4a3a-97af-58b599edbd08",
      //   cantidad: "1",
      //   codigo: "7",
      //   descripcion: "DESPARASITACIÓN INTERNA 1-15 KG",
      //   valUnitarioInterno: "7.00",
      //   valorUnitario: "7.00",
      //   recargoPorcentaje: "0.00",
      //   recargo: "0.00",
      //   descPorcentaje: "0",
      //   descuento: "0.00",
      //   precioSubTotalProducto: "7.00",
      //   valorIva: "0.00",
      //   costoProduccion: "7.00",
      //   llevaIva: "NO",
      //   incluyeIva: "NO",
      // },
    ],
  });
  const [pagination, setpagination] = useState(null);
  // FUNCIONES STATE
  const getDataState = async (
    cantidad,
    page,
    search,
    input,
    orden,
    datos,
    rucempresa,
    estado
  ) => {
    console.log(
      `/ventas/?cantidad=${cantidad}&page=${page}&search=${
        search ? search : ""
      }&input=${input ? input : ""}&orden=${
        orden ? orden : ""
      }&datos=${datos}&rucempresa=${rucempresa ? rucempresa : ""}&estado=${
        estado ? estado : ""
      }&documento=${documento ? documento : ""}&allData=${tienePermiso(
        "allData"
      )}`
    );

    const res = await clienteAxios.get(
      `/ventas/?cantidad=${cantidad}&page=${page}&search=${
        search ? search : ""
      }&input=${input ? input : ""}&orden=${orden ? orden : ""}&datos=${
        datos ? datos : ""
      }&rucempresa=${rucempresa ? rucempresa : ""}&estado=${
        estado ? estado : ""
      }&documento=${documento ? documento : ""}&allData=${tienePermiso(
        "allData"
      )}`
    );

    return res;
  };

  const obtenerState = async (
    cantidad = 10,
    page = 0,
    search,
    input,
    orden,
    datos,
    rucempresa,
    estado
  ) => {
    const res = await getDataState(
      cantidad,
      page,
      search,
      input,
      orden,
      datos,
      rucempresa,
      estado
    );
    setrows(res.data.data.results);
    setpagination(res.data.data.pagination);
  };
  const addRowsState = async (items) => {
    setrows([...rows, ...items]);
  };
  const remplazarRowsState = async (
    cantidad,
    page,
    search,
    input,
    orden,
    datos,
    rucempresa,
    estado
  ) => {
    const res = await getDataState(
      cantidad,
      page,
      search,
      input,
      orden,
      datos,
      rucempresa,
      estado
    );
    setrows(res.data.data.results);
    setpagination(res.data.data.pagination);
    // dispatch({
    //   type: OBTENER,
    //   payload: {
    //     results: res.data.data.results,
    //     pagination: res.data.data.pagination,
    //   },
    // });
  };
  const agregarState = async (item) => {
    try {
      setrows([item, ...rows]);

      setpagination({
        ...pagination,
        numRows: pagination.numRows + 1,
      });
    } catch (error) {}
  };

  const editarState = async (item) => {
    try {
      const resRows = rows.map((itemR) => {
        if (itemR.venId === item.venId) {
          return item;
        } else {
          return itemR;
        }
      });

      setrows(resRows);
    } catch (error) {}
  };

  const eliminarState = async (_id) => {
    try {
      const resRows = rows.filter((row) => {
        return row.venId !== _id;
      });
      setrows(resRows);
      setpagination({
        ...pagination,
        numRows: pagination.numRows - 1,
      });
    } catch (error) {}
  };
  const eliminarSeleccionState = async (_ids) => {
    const resRows = rows.filter((row) => {
      return !_ids.some((item) => {
        return item === row.venId;
      });
    });
    setpagination({
      ...pagination,
      numRows: pagination.numRows - _ids.length,
    });
    setrows(resRows);
  };

  const changeObjetoActivoState = (item) => {
    setObjetoActivo(item);
    // dispatch({
    //   type: CHANGE_ACTIVO,
    //   payload: item,
    // });
  };

  const rowsImportState = (rows) => {
    // dispatch({
    //   type: IMPORTAR_ROWS,
    //   payload: rows,
    // });
    setrows(rows);
  };
  // FIN FUNCIONES STATE
  // FIN DATA STATE

  // FUNCIONES SOCKET
  const [tabla] = useState("venta");
  const [itemSocket, setitemSocket] = useState({
    tipo: "",
    item: {},
  });

  const socket = useRef();
  useEffect(() => {
    // Creates a WebSocket connection
    socket.current = io(socketUrl);
    socket.current.on("server:guardadoExitoso", (data) => {
      if (data.tabla !== tabla) {
        return;
      }

      setitemSocket({
        tipo: "agregar",
        item: data,
      });
    });
    socket.current.on("server:actualizadoExitoso", (data) => {
      if (data.tabla !== tabla) {
        return;
      }

      setitemSocket({
        tipo: "editar",
        item: data,
      });
    });
    socket.current.on("server:eliminadoExitoso", (data) => {
      if (data.tabla !== tabla) {
        return;
      }
      setitemSocket({
        tipo: "eliminar",
        item: data,
      });
    });
    socket.current.on("server:eliminadoExitosoSeleccionado", (data) => {
      if (data.tabla !== tabla) {
        return;
      }
      setitemSocket({
        tipo: "eliminarSeleccion",
        item: data,
      });
    });
    socket.current.on("server:idRegistro", (data) => {
      window.open(`${data.ruta}`, "_blank");
    });

    socket.current.on("server:error", (data) => {
      mostrarAlertaSocket(
        data.msg ? data.msg : "Hubo un error",
        data.alert ? data.alert : "error",
        data.rucempresa
      );
    });
    socket.current.on("server:descagar_xmls", (data) => {
      mostrarAlertaSocket(
        data.msg ? data.msg : "Hubo un error",
        data.alert ? data.alert : "error",
        data.rucempresa
      );
    });
    return () => {
      socket.current.disconnect();
    };
    // Destroys the socket reference
    // when the connection is closed
    // eslint-disable-next-line
  }, []);
  const mostrarAlertaSocket = (a, b, empresa) => {
    if (empresa === rucempresa) {
      mostrarAlerta(a, b);
    }
  };
  useEffect(() => {
    const { item, tipo } = itemSocket;

    if (tipo === "") {
      return;
    }
    if (!rows) {
      return;
    }
    if (usuario.rucempresa !== item.rucempresa) {
      return;
    }

    if (desdeOtroLugarPersona && item.venRucCliente !== documento) {
      return;
    }
    const funcionAalerta = () => {
      if (item.msg === "") {
        return null;
      }
      if (!item.msg) {
        return null;
      }
      mostrarAlerta(item.msg, item.alert ? item.alert : "success");
    };

    if (tipo === "agregar") {
      if (
        usuario.empCategoria === "GASOLINERA" &&
        item.venTipoDocumento === "F" &&
        item.venUser === usuario.usuario
      ) {
        // Axios.post(
        //   `http://localhost/ticket_gasolinera/ticket.php`,
        //   JSON.stringify(item)
        // );
      }

      agregarState(item);
    }
    if (tipo === "editar") {
      editarState(item);
    }
    if (tipo === "eliminar") {
      eliminarState(item.venId);
    }
    if (tipo === "eliminarSeleccion") {
      eliminarSeleccionState(item.lista);
    }
    funcionAalerta();
    setitemSocket({ tipo: "", item: {} });
    // eslint-disable-next-line
  }, [itemSocket]);
  const { mostrarAlerta } = useContext(AlertaContext);

  const {
    usuario: { rucempresa, codigo },
    usuario,
  } = useContext(AuthContext);
  const [openSubCategoria, setopenSubCategoria] = useState(false);
  const [openCategoria, setopenCategoria] = useState(false);

  const [OpenProveedorModalI, setOpenProveedorModalI] = useState(false);
  const [OpenProveedor, setOpenProveedor] = useState(false);
  const [editarProveedor, seteditarProveedor] = useState(false);
  const [openInventario, setopenInventario] = useState(false);
  const [editarInventario, seteditarInventario] = useState(false);
  const [estadoRequest, setestadoRequest] = useState("FACTURAS");
  const [open, setopen] = useState(false);
  const [buscando, setbuscando] = useState(false);
  const [editar, seteditar] = useState(false);
  const [search, setsearch] = useState("");
  const [cargando, setcargando] = useState(false);
  const [input, setinput] = useState({
    id: "venId",
    label: "ID",
    minWidth: 150,
    tipo: "string",

    propiedad: "venId",
  });
  const [arrayExport, setarrayExport] = useState([]);
  const [campoExport] = useState("venId");

  const [orden, setorden] = useState(false);
  const [openMB, setopenMB] = useState(false);
  const [fullBuscador, setfullBuscador] = useState({});
  //pagina actual
  const [page, setPage] = React.useState(0);
  //filas por pagina
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  // input
  //
  const handleChange = (e) => {
    setfullBuscador({ ...fullBuscador, [e.target.name]: e.target.value });
  };
  const funcionB = async () => {
    setcargando(true);
    await remplazarRowsState(
      rowsPerPage,
      0,
      search,
      input.id,
      orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      estadoRequest
    );

    setPage(0);
    setbuscando(true);
    setopenMB(false);
    setcargando(false);
  };

  const handleSearch = async () => {
    setcargando(true);
    setPage(0);
    await remplazarRowsState(
      rowsPerPage,
      0,
      search,
      input.id,
      orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      estadoRequest
    );
    setcargando(false);
  };
  useEffect(() => {
    const fullBRes = defaultFullBuscador();

    setfullBuscador(fullBRes);
    // eslint-disable-next-line
  }, []);
  const funcionDefault = async () => {
    setcargando(true);
    const fullBRes = defaultFullBuscador();
    setfullBuscador(fullBRes);
    await remplazarRowsState(
      rowsPerPage,
      0,
      "",
      input.id,
      orden,
      JSON.stringify(fullBRes),
      rucempresa,
      estadoRequest
    );

    setPage(0);
    setsearch("");
    setbuscando(false);
    setcargando(false);
    setopenMB(false);
  };
  const funcArchivoXmlSri = async (e) => {
    if (e.target.files.length === 0) return;
    try {
      const existeUnNoXML = Object.values(e.target.files).some(
        (file) => file.type !== "text/xml"
      );
      if (existeUnNoXML) {
        return mostrarAlerta("El archivo no es XML");
      } else {
        const formDataLogos = new FormData();
        Object.values(e.target.files).forEach((file, index) => {
          formDataLogos.append(`archivo${index + 1}`, file);
        });
        formDataLogos.append("tipo", "compras");
        formDataLogos.append("rucempresa", rucempresa);
        formDataLogos.append("codigo", codigo);

        const res = await clienteAxios.post(
          "/uploads_xml/retencion",
          formDataLogos
        );
        mostrarAlerta(res.data.msg, "success");
      }
    } catch (error) {
      mostrarAlerta("Hubo un error al subir el archivo XML", "error");
    }
  };
  const funcArchivoTxtSri = async (e) => {
    if (!e.target.files[0]) {
      return;
    } else {
      if (e.target.files[0].type !== "text/plain") {
        return mostrarAlerta("El archivo no es de texto");
      } else {
        try {
          setcargando(true);
          const formDataLogos = new FormData();
          formDataLogos.append("archivo", e.target.files[0]);
          formDataLogos.append("codigo", codigo);
          const res = await clienteAxios.post(
            "/subir_txt/retencion_factura",
            formDataLogos
          );
          console.log({ res });
          socket.current.emit("client:descagar_xmls", {
            tabla: "retencion_factura",
            rucempresa: rucempresa,
            usuario: usuario.usuario,
            clavesAccesoRetencion: res.data,
          });
          // rowsImportState({ res });
          setcargando(false);
        } catch (error) {
          mostrarAlerta("Hubo un error al subir el txt", "");
        }
      }
    }
  };
  // INVENTARIO
  const [ObjectoActivoInventario, setObjetoActivoInventario] = useState({
    invSerie: "0",
    invTipo: "",
    invNombre: "",
    invDescripcion: "",
    invCosto1: "",
    invCosto2: "",
    invCosto3: "",
    invStock: "",
    invIva: "SI",
    invIncluyeIva: "SI",
    invEstado: "ACTIVA",
    invBodegas: [],
    invprecios: [],
    invProveedor: "",
    invCategoria: "",
    invSubCategoria: "",
    invUnidad: "",
    invMarca: "",
    invSubsidio: "0",
    invPlanCuenta: "",
    invEmpresa: "",
    invUser: "admin",
    invFecReg: "",
    Todos: "",
  });
  const changeObjetoActivoStateInventario = (item) => {
    setObjetoActivoInventario(item);
    // dispatch({
    //   type: CHANGE_ACTIVO,
    //   payload: item,
    // });
  };

  // Proveedor
  const [ObjectoActivoProveedor, setObjetoActivoProveedor] = useState({
    perISPContratos: [],
    perNombreComercial: "",
    perEmpresa: [],
    perPais: "",
    perProvincia: "",
    perCanton: "",
    perTipoProveedor: "BIENES",
    perTiempoCredito: "0",
    perDocTipo: "",
    perDocNumero: "",
    perPerfil: [],
    perNombre: "",
    perDireccion: "",
    perObligado: "NO",
    perCredito: "NO",
    perTelefono: "0",
    perCelular: ["+593"],
    perEstado: "ACTIVO",
    perObsevacion: "",
    perEmail: ["sin@sincorreo.com"],
    perOtros: ["ZZZ9999"],
    perNickname: "",
    perUser: "",
    perFecReg: "",
    perFoto: "",
    perUbicacion: {
      longitud: "",
      latitud: "",
    },
    perDocumento: "",
    perGenero: "M",
    perRecomendacion: "OTROS",
    perFecNacimiento: "",
    perEspecialidad: "",
    perTitulo: "",
    perSenescyt: "",
    perPersonal: "INTERNO",
    validateEmail: 1,
  });
  const changeObjetoActivoStateProveedor = (item) => {
    setObjetoActivoProveedor(item);
    // dispatch({
    //   type: CHANGE_ACTIVO,
    //   payload: item,
    // });
  };
  const [openDialogOpciones, setopenDialogOpciones] = useState(false);
  const inputTxtSri = createRef();
  const inputXmlSri = createRef();
  return (
    <Box>
      <ModalTraido
        open={openCategoria}
        setopen={setopenCategoria}
        titulo="Categoría"
        Componente={Categorias}
        socket={socket}
      />
      <ModalTraido
        open={openSubCategoria}
        setopen={setopenSubCategoria}
        titulo="Sub Categoría"
        Componente={Subcategorias}
        socket={socket}
      />
      <ModalProveedor
        estadoRequest={"PROVEEDOR"}
        open={OpenProveedorModalI}
        setopen={setOpenProveedorModalI}
        editar={editarProveedor}
        seteditar={seteditarProveedor}
        socket={socket}
        ObjectoActivo={ObjectoActivoProveedor}
        changeObjetoActivoState={changeObjetoActivoStateProveedor}
      />
      {usuario.empCategoria === "CONTABLE" && (
        <ModalProveedor
          estadoRequest={"CLIENTE"}
          open={OpenProveedor}
          setopen={setOpenProveedor}
          editar={editarProveedor}
          seteditar={seteditarProveedor}
          socket={socket}
          ObjectoActivo={ObjectoActivoProveedor}
          changeObjetoActivoState={changeObjetoActivoStateProveedor}
        />
      )}
      {usuario.empCategoria === "ISP" && (
        <ModalProveedorISP
          estadoRequest={"CLIENTE"}
          open={OpenProveedor}
          setopen={setOpenProveedor}
          editar={editarProveedor}
          seteditar={seteditarProveedor}
          socket={socket}
          ObjectoActivo={ObjectoActivoProveedor}
          changeObjetoActivoState={changeObjetoActivoStateProveedor}
        />
      )}
      {usuario.empCategoria === "VETERINARIA" && (
        <ModalProveedorVet
          estadoRequest={"CLIENTE"}
          open={OpenProveedor}
          setopen={setOpenProveedor}
          editar={editarProveedor}
          seteditar={seteditarProveedor}
          socket={socket}
          ObjectoActivo={ObjectoActivoProveedor}
          changeObjetoActivoState={changeObjetoActivoStateProveedor}
        />
      )}
      {usuario.empCategoria === "GASOLINERA" && (
        <ModalProveedor
          estadoRequest={"CLIENTE"}
          open={OpenProveedor}
          setopen={setOpenProveedor}
          editar={editarProveedor}
          seteditar={seteditarProveedor}
          socket={socket}
          ObjectoActivo={ObjectoActivoProveedor}
          changeObjetoActivoState={changeObjetoActivoStateProveedor}
        />
      )}
      {socket.current ? (
        <ModalInventario
          open={openInventario}
          setopen={setopenInventario}
          editar={editarInventario}
          seteditar={seteditarInventario}
          setopenSubCategoria={setopenSubCategoria}
          setopenCategoria={setopenCategoria}
          setOpenProveedor={setOpenProveedorModalI}
          ObjectoActivo={ObjectoActivoInventario}
          changeObjetoActivoState={changeObjetoActivoStateInventario}
          socket={socket}
          seteditarProveedor={seteditarProveedor}
          changeObjetoActivoStateProveedor={changeObjetoActivoStateProveedor}
        />
      ) : null}{" "}
      <input
        style={{ display: "none" }}
        type="file"
        name="myImage"
        accept=".txt"
        onChange={(e) => {
          funcArchivoTxtSri(e);
        }}
        ref={inputTxtSri}
      />
      <input
        multiple
        style={{ display: "none" }}
        type="file"
        name="myImage"
        onChange={(e) => {
          funcArchivoXmlSri(e);
        }}
        ref={inputXmlSri}
      />
      <DialogOpciones
        open={openDialogOpciones}
        setopen={setopenDialogOpciones}
        botones={[
          {
            tooltip: "Subir TXT SRI (Retenciones)",
            funcion: async () => {
              inputTxtSri.current.click();
            },
            Icon: Outbox,
            color: "primary",
            id: 1,
            disabled: false,
          },
          {
            tooltip: "Subir XML (Retención)",
            funcion: async () => {
              inputXmlSri.current.click();
            },
            Icon: Outbox,
            color: "primary",
            id: 1,
            disabled: false,
          },
          {
            tooltip: "Autorizar Por lote",
            funcion: async () => {
              try {
                setcargando(true);
                const res = await clienteAxios.post("/ventas");

                mostrarAlerta(res.data.msg, "success");
                funcionDefault();
              } catch (error) {
                setcargando(false);
                mostrarAlerta("Hubo un error", "error");
              }
            },
            Icon: Outbox,
            color: "primary",
            id: 1,
            disabled: false,
          },
        ]}
        titulo={"Mas Opciones"}
      />
      <BarraTablas
        search={search}
        setsearch={setsearch}
        funcion={handleSearch}
        buscando={buscando}
        setbuscando={setbuscando}
        LabelBuscador="Buscar por todos los campos"
        funcionDefault={funcionDefault}
        ocultarMigasDePan={false}
        botones={[
          {
            tooltip: "Agregar",
            texto: "Agregar",
            funcion: () => {
              if (estadoRequest === "FACTURAS") {
                if (desdeOtroLugarPersona) {
                  setObjetoActivo({
                    ...ObjectoActivo,
                    venRucCliente: itemRelacionadoPadre.perDocNumero,
                    venNomCliente: itemRelacionadoPadre.perNombre,
                    venIdCliente: itemRelacionadoPadre.perId,
                    venTipoDocuCliente: itemRelacionadoPadre.perDocTipo,
                    venEmailCliente: itemRelacionadoPadre.perEmail,
                    venTelfCliente: itemRelacionadoPadre.perTelefono,
                    venCeluCliente: itemRelacionadoPadre.perCelular,
                    venDirCliente: itemRelacionadoPadre.perDireccion,
                    venOtrosDetalles:
                      itemRelacionadoPadre.perOtros.length === 0
                        ? ""
                        : itemRelacionadoPadre.perOtros[0],
                    venTipoDocumento: "F",
                  });
                } else {
                  changeObjetoActivoState({
                    ...ObjectoActivo,
                    venTipoDocumento: "F",
                  });
                }

                return setopen(true);
              }
              if (estadoRequest === "NOTA VENTAS") {
                if (desdeOtroLugarPersona) {
                  setObjetoActivo({
                    ...ObjectoActivo,
                    venRucCliente: itemRelacionadoPadre.perDocNumero,
                    venNomCliente: itemRelacionadoPadre.perNombre,
                    venIdCliente: itemRelacionadoPadre.perId,
                    venTipoDocuCliente: itemRelacionadoPadre.perDocTipo,
                    venEmailCliente: itemRelacionadoPadre.perEmail,
                    venTelfCliente: itemRelacionadoPadre.perTelefono,
                    venCeluCliente: itemRelacionadoPadre.perCelular,
                    venDirCliente: itemRelacionadoPadre.perDireccion,
                    venOtrosDetalles:
                      itemRelacionadoPadre.perOtros.length === 0
                        ? ""
                        : itemRelacionadoPadre.perOtros[0],
                    venTipoDocumento: "N",
                  });
                } else {
                  changeObjetoActivoState({
                    ...ObjectoActivo,
                    venTipoDocumento: "N",
                  });
                  return setopen(true);
                }
              }
              if (estadoRequest === "PROFORMAS") {
                if (desdeOtroLugarPersona) {
                  setObjetoActivo({
                    ...ObjectoActivo,
                    venRucCliente: itemRelacionadoPadre.perDocNumero,
                    venNomCliente: itemRelacionadoPadre.perNombre,
                    venIdCliente: itemRelacionadoPadre.perId,
                    venTipoDocuCliente: itemRelacionadoPadre.perDocTipo,
                    venEmailCliente: itemRelacionadoPadre.perEmail,
                    venTelfCliente: itemRelacionadoPadre.perTelefono,
                    venCeluCliente: itemRelacionadoPadre.perCelular,
                    venDirCliente: itemRelacionadoPadre.perDireccion,
                    venOtrosDetalles:
                      itemRelacionadoPadre.perOtros.length === 0
                        ? ""
                        : itemRelacionadoPadre.perOtros[0],
                    venTipoDocumento: "P",
                  });
                } else {
                  changeObjetoActivoState({
                    ...ObjectoActivo,
                    venTipoDocumento: "P",
                  });
                }
                return setopen(true);
              }
              if (estadoRequest === "NOTA CREDITOS") {
                if (desdeOtroLugarPersona) {
                  setObjetoActivo({
                    ...ObjectoActivo,
                    venRucCliente: itemRelacionadoPadre.perDocNumero,
                    venNomCliente: itemRelacionadoPadre.perNombre,
                    venIdCliente: itemRelacionadoPadre.perId,
                    venTipoDocuCliente: itemRelacionadoPadre.perDocTipo,
                    venEmailCliente: itemRelacionadoPadre.perEmail,
                    venTelfCliente: itemRelacionadoPadre.perTelefono,
                    venCeluCliente: itemRelacionadoPadre.perCelular,
                    venDirCliente: itemRelacionadoPadre.perDireccion,
                    venOtrosDetalles:
                      itemRelacionadoPadre.perOtros.length === 0
                        ? ""
                        : itemRelacionadoPadre.perOtros[0],
                    venTipoDocumento: "NC",
                  });
                } else {
                  changeObjetoActivoState({
                    ...ObjectoActivo,
                    venTipoDocumento: "NC",
                  });
                }
                return setopen(true);
              }
            },
            disabled: false,
            Icon: Add,
            color: "primary",
            id: 2,
            ocultar:
              estadoRequest === "ANULADAS" ||
              estadoRequest === "DESPACHO" ||
              estadoRequest === "ALIMENTACION" ||
              estadoRequest === "COMBUSTIBLE",
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
          {
            tooltip: "Opciones",
            texto: "Opciones",
            funcion: () => {
              setopenDialogOpciones(true);
            },
            disabled: false,
            Icon: Menu,
            color: "secondary",
            id: 2,
            ocultar:
              estadoRequest === "ANULADAS" ||
              estadoRequest === "DESPACHO" ||
              estadoRequest === "ALIMENTACION" ||
              estadoRequest === "COMBUSTIBLE",
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {
              mx: 1,
            },
          },
          {
            tooltip: "Busqueda avanzada",
            texto: "",
            funcion: () => {
              setopenMB(true);
            },
            disabled: false,
            Icon: Search,
            color: "success",
            id: 2,
            ocultar: false,
            tipo: "icono",
            variante: "contained",
            size: "medium",
            sx: {
              ml: 1,
            },
          },
        ]}
      />
      {/* <BarraTablas
        search={search}
        setsearch={setsearch}
        funcion={handleSearch}
        buscando={buscando}
        setbuscando={setbuscando}
        setopen={(boolean) => {}}
        open={open}
        openMB={openMB}
        setopenMB={setopenMB}
        LabelBuscador="Buscar por todos los campos"
        funcionDefault={funcionDefault}
        ats={true}
        subirArchivoVentas={true}
        funcArchivoXmlSri={funcArchivoXmlSri}
        ocultarAgregar={estadoRequest === "ANULADAS"}
        ocultarMigasDePan={desdeOtroLugarPersona}
        autorizarPorLote={async () => {}}
      /> */}
      <ModalB
        defaultFullBuscador={defaultFullBuscador}
        openMB={openMB}
        setopenMB={setopenMB}
        titulo={`Busqueda avanzada (Ventas: ${estadoRequest})`}
        fullBuscador={fullBuscador}
        setfullBuscador={setfullBuscador}
        arrayProps={columns}
        handleChange={handleChange}
        funcionB={funcionB}
        buscando={buscando}
        funcionDefault={funcionDefault}
      />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems=" stretch"
        width="100%"
      >
        <Box>
          <Button
            size="small"
            disabled={cargando}
            variant={estadoRequest === "FACTURAS" ? "contained" : null}
            onClick={async () => {
              setcargando(true);
              await remplazarRowsState(
                rowsPerPage,
                0,
                "",
                input.id,
                orden,
                JSON.stringify(fullBuscador),
                rucempresa,
                "FACTURAS"
              );

              setPage(0);
              setestadoRequest("FACTURAS");
              setcargando(false);
            }}
            disableElevation
            style={{ padding: ".5rem", borderRadius: "15px 15px  0px 0px" }}
            color="primary"
          >
            FACTURAS
          </Button>
          <Button
            size="small"
            disabled={cargando}
            variant={estadoRequest === "NOTA VENTAS" ? "contained" : null}
            onClick={async () => {
              setcargando(true);
              await remplazarRowsState(
                rowsPerPage,
                0,
                "",
                input.id,
                orden,
                JSON.stringify(fullBuscador),
                rucempresa,
                "NOTA VENTAS"
              );

              setPage(0);
              setestadoRequest("NOTA VENTAS");
              setcargando(false);
            }}
            disableElevation
            style={{ padding: ".5rem", borderRadius: "15px 15px  0px 0px" }}
            color="primary"
          >
            PREFACTURAS
          </Button>
          <Button
            size="small"
            disabled={cargando}
            variant={estadoRequest === "PROFORMAS" ? "contained" : null}
            onClick={async () => {
              setcargando(true);
              await remplazarRowsState(
                rowsPerPage,
                0,
                "",
                input.id,
                orden,
                JSON.stringify(fullBuscador),
                rucempresa,
                "PROFORMAS"
              );

              setPage(0);
              setestadoRequest("PROFORMAS");
              setcargando(false);
            }}
            disableElevation
            style={{ padding: ".5rem", borderRadius: "15px 15px  0px 0px" }}
            color="primary"
          >
            PROFORMAS
          </Button>
          <Button
            size="small"
            disabled={cargando}
            variant={estadoRequest === "NOTA CREDITOS" ? "contained" : null}
            onClick={async () => {
              setcargando(true);
              await remplazarRowsState(
                rowsPerPage,
                0,
                "",
                input.id,
                orden,
                JSON.stringify(fullBuscador),
                rucempresa,
                "NOTA CREDITOS"
              );

              setPage(0);
              setestadoRequest("NOTA CREDITOS");
              setcargando(false);
            }}
            disableElevation
            style={{ padding: ".5rem", borderRadius: "15px 15px  0px 0px" }}
            color="primary"
          >
            NOTA CREDITOS
          </Button>
          <Button
            size="small"
            disabled={cargando}
            variant={estadoRequest === "SIN AUTORIZAR" ? "contained" : null}
            onClick={async () => {
              setcargando(true);
              await remplazarRowsState(
                rowsPerPage,
                0,
                "",
                input.id,
                orden,
                JSON.stringify(fullBuscador),
                rucempresa,
                "SIN AUTORIZAR"
              );

              setPage(0);
              setestadoRequest("SIN AUTORIZAR");
              setcargando(false);
            }}
            disableElevation
            style={{ padding: ".5rem", borderRadius: "15px 15px  0px 0px" }}
            color="primary"
          >
            SIN AUTORIZAR
          </Button>
          <Button
            size="small"
            disabled={cargando}
            variant={estadoRequest === "ANULADAS" ? "contained" : null}
            onClick={async () => {
              setcargando(true);
              await remplazarRowsState(
                rowsPerPage,
                0,
                "",
                input.id,
                orden,
                JSON.stringify(fullBuscador),
                rucempresa,
                "ANULADAS"
              );

              setPage(0);
              setestadoRequest("ANULADAS");
              setcargando(false);
            }}
            disableElevation
            style={{ padding: ".5rem", borderRadius: "15px 15px  0px 0px" }}
            color="primary"
          >
            ANULADAS
          </Button>
          {rucempresa === "NEIMAR" && (
            <>
              <Button
                size="small"
                disabled={cargando}
                variant={estadoRequest === "DESPACHO" ? "contained" : null}
                onClick={async () => {
                  setcargando(true);
                  await remplazarRowsState(
                    rowsPerPage,
                    0,
                    "",
                    input.id,
                    orden,
                    JSON.stringify(fullBuscador),
                    rucempresa,
                    "DESPACHO"
                  );

                  setPage(0);
                  setestadoRequest("DESPACHO");
                  setcargando(false);
                }}
                disableElevation
                style={{ padding: ".5rem", borderRadius: "15px 15px  0px 0px" }}
                color="primary"
              >
                DESPACHO
              </Button>
              <Button
                size="small"
                disabled={cargando}
                variant={estadoRequest === "ALIMENTACION" ? "contained" : null}
                onClick={async () => {
                  setcargando(true);
                  await remplazarRowsState(
                    rowsPerPage,
                    0,
                    "",
                    input.id,
                    orden,
                    JSON.stringify(fullBuscador),
                    rucempresa,
                    "ALIMENTACION"
                  );

                  setPage(0);
                  setestadoRequest("ALIMENTACION");
                  setcargando(false);
                }}
                disableElevation
                style={{ padding: ".5rem", borderRadius: "15px 15px  0px 0px" }}
                color="primary"
              >
                ALIMENTACION
              </Button>
              <Button
                size="small"
                disabled={cargando}
                variant={estadoRequest === "COMBUSTIBLE" ? "contained" : null}
                onClick={async () => {
                  setcargando(true);
                  await remplazarRowsState(
                    rowsPerPage,
                    0,
                    "",
                    input.id,
                    orden,
                    JSON.stringify(fullBuscador),
                    rucempresa,
                    "COMBUSTIBLE"
                  );

                  setPage(0);
                  setestadoRequest("COMBUSTIBLE");
                  setcargando(false);
                }}
                disableElevation
                style={{ padding: ".5rem", borderRadius: "15px 15px  0px 0px" }}
                color="primary"
              >
                COMBUSTIBLE
              </Button>
            </>
          )}
        </Box>
      </Box>
      <Divider />
      <Tabla
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        search={search}
        input={input}
        orden={orden}
        setinput={setinput}
        setorden={setorden}
        seteditar={seteditar}
        setopen={setopen}
        rows={rows}
        buscando={buscando}
        cargando={cargando}
        setcargando={setcargando}
        fullBuscador={fullBuscador}
        estadoRequest={estadoRequest}
        // STATE
        addRowsState={addRowsState}
        getDataState={getDataState}
        remplazarRowsState={remplazarRowsState}
        obtenerState={obtenerState}
        pagination={pagination}
        columns={columns}
        ObjectoActivo={ObjectoActivo}
        changeObjetoActivoState={changeObjetoActivoState}
        rowsImportState={rowsImportState}
        // export
        arrayExport={arrayExport}
        setarrayExport={setarrayExport}
        campoExport={campoExport}
        socket={socket}
        desdeOtroLugarPersona={desdeOtroLugarPersona}
        editarState={editarState}
      />
      {socket.current ? (
        <Modal
          estadoRequest={estadoRequest}
          editar={editar}
          seteditar={seteditar}
          setopen={setopen}
          open={open}
          setopenInventario={setopenInventario}
          setOpenProveedor={setOpenProveedor}
          ObjectoActivo={ObjectoActivo}
          changeObjetoActivoState={changeObjetoActivoState}
          socket={socket}
          seteditarInventario={seteditarInventario}
          changeObjetoActivoStateInventario={changeObjetoActivoStateInventario}
          seteditarProveedor={seteditarProveedor}
          changeObjetoActivoStateProveedor={changeObjetoActivoStateProveedor}
        />
      ) : null}
    </Box>
  );
};

export default memo(Facturacion);
