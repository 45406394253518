import clienteAxios from "../../config/axios";
import { FechaExacta } from "../../config/const";

// actaEstado
// actaCiudad
// actaSector
// actaDocCliente
// actaTipo
// actaOpcion
// actaServiciosVisita
// correo_nuevo_registro
// actaFecReg

// autocompleteDinamico
// variante: "autocompleteDinamico",
// propiedadesMostrar: 2,
// campoData1: "perDocNumero",
// campoData2: "perNombre",
// getData: async (data) => {
//   if (data.length < 3) {
//     return;
//   }
//   const res = await clienteAxios.get(
//     `/proveedores/filtro/0?empresa=${
//       usuario.rucempresa
//     }&search=${data}&estado=${"PROVEEDOR"}&tabla=compras`
//   );
//   return res;
// },
// },

// autocompleteComun
// variante: "autocompleteComun",
// data: [
//   "APERTURA",
//   "INGRESO",
//   "EGRESO",
//   "DEPOSITO",
//   "CAJA CHICA",
//   "TRANSFERENCIA",
// ],
// autocompleteDataDefault
// variante: "autocompleteDataDefault",
// propiedadesMostrar: 1,
// campoData1: "fpagoNombre",
// getData: async () => {
// const resciudadesData = await clienteAxios.get(`/ciudades/filtro/0`);

// setciudadesData(resciudadesData.data.data);
// },
// propiedad: "venFormaPago",
export const columns = [
  {
    id: "actaEstado",
    label: "Estado",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "actaEstado",
    align: "center",
    ocultar: false,
    variante: "autocompleteComun",
    data: ["PENDIENTE", "EN PROCESO", "SUSPENDIDO", "PROCESADO", "ANULADO"],
  },
  {
    id: "actaId",
    label: "#",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "actaId",
    align: "center",
    ocultar: true,
  },
  {
    id: "actaCiudad",
    label: "Ciudad",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "actaCiudad",
    align: "center",
    ocultar: false,
    variante: "autocompleteDataDefault",
    propiedadesMostrar: 1,
    campoData1: "ciuNombre",
    getData: async () => {
      const resciudadesData = await clienteAxios.get(`/ciudades/filtro/0`);

      return resciudadesData;
    },
  },
  {
    id: "actaSector",
    label: "Sector",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "actaSector",
    align: "center",
    ocultar: false,
  },
  {
    id: "actaDocCliente",
    label: "Cliente",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "actaDocCliente",
    align: "center",
    ocultar: false,
    variante: "autocompleteDinamico",
    propiedadesMostrar: 2,
    campoData1: "perDocNumero",
    campoData2: "perNombre",
    getData: async (data) => {
      const res = await clienteAxios.get(
        `/proveedores/filtro/0?search=${data}&estado=CLIENTE`
      );
      return res;
    },
  },
  {
    id: "actaTelCliente",
    label: "Tel",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "actaTelCliente",
    align: "center",
    ocultar: true,
    tipo: "",
  },
  {
    id: "actaTipo",
    label: "Tipo",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "actaTipo",
    align: "center",
    ocultar: false,
    variante: "autocompleteComun",
    data: ["HOME", "EMPRESARIAL", "CORPORATIVO"],
  },

  {
    id: "actaOpcion",
    label: "Opción",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "actaOpcion",
    align: "center",
    ocultar: false,
    variante: "autocompleteComun",
    data: ["Nueva Instalación", "Visita Técnica", "Soporte Remoto"],
  },
  {
    id: "actaServiciosVisita",
    label: "Servicios Visita",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "actaServiciosVisita",
    align: "center",
    ocultar: false,
    variante: "autocompleteDataDefault",
    propiedadesMostrar: 1,
    campoData1: "nombre",
    getData: async () => {
      const resciudadesData = await clienteAxios.get(
        `/servicios_visita/listar/todos`
      );

      return { data: resciudadesData };
    },
  },
  {
    id: "correo_nuevo_registro",
    label: "Correo",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "correo_nuevo_registro",
    align: "center",
    ocultar: false,
    variante: "autocompleteComun",
    data: ["PENDIENTE", "ENVIADO", "NO ENVIADO", "ERROR"],
  },
  {
    id: "actaUser",
    label: "User",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "actaUser",
    align: "center",
    ocultar: true,
  },
  {
    id: "actaFecReg",
    label: "Fec. Reg.",
    minWidth: 25,
    tipo: "fecha",
    orden: 1,
    propiedad: "actaFecReg",
    align: "center",
    ocultar: false,
  },
];
export const validacion = (item, setitem, seterror) => {
  const { actaDocCliente, actaEntDoc } = item;

  if (
    actaDocCliente.trim() === "" ||
    item.actaCiudad === "" ||
    (item.actaOpcion === "Nueva Instalación" &&
      (item.actaFechaInstalacion === "" ||
        item.actaDocVendedor === "" ||
        item.planNombreInternet === ""))
  ) {
    seterror({
      // PRIMER TAB
      actaDocCliente: actaDocCliente.trim() === "",
      actaCiudad: item.actaCiudad.trim() === "",
      actaFechaInstalacion:
        item.actaFechaInstalacion === "" &&
        item.actaOpcion === "Nueva Instalación",
      actaDocVendedor:
        item.actaDocVendedor === "" && item.actaOpcion === "Nueva Instalación",
      planNombreInternet:
        item.planNombreInternet === "" &&
        item.actaOpcion === "Nueva Instalación",
      // SEGUNDO TAB
    });
    return true;
  }
};
export const objectDefault = {
  // actaId: 1,
  actaIdCliente: 0,
  actaIdPersonaEntrega: 0,
  actaFecha: FechaExacta,
  // actaInstalacionFibra: "EMPRESARIAL",
  // actaVisitaTecnica: "EMPRESARIAL",
  actaServiciosVisita: "TRASLADOS",
  actaOpcion: "Nueva Instalación", // | Visita Técnica
  actaTipo: "HOME", //EMPRESARIAL "CORPORATIVO", "HOME"
  actaSeEncuentraCliente: "SI", // | NO
  actaCiudad: "",
  actaSector: [],
  actaFechaInstalacion: FechaExacta,
  actaRedWifi: "",
  actaClave: "",
  actaClienteRegulador: "NO",
  actaConfigRouter: "NO",
  actaCambioPatch: "NO",
  actaCalidadIns: "",
  actaSolucionReq: "MUY BUENO",
  actaRecNombre: "",
  actaRecFirma: "",
  actaEntFirma: "",
  actaUser: "",
  actaEmpresa: "",
  actaFecReg: "",
  actaDocCliente: "",
  actaNombreCliente: "",
  actaDireccionCliente: "",
  actaRecDoc: "",
  actaEntNombre: "",
  actaEntDoc: "",
  actaFotos: [],
  actaEquiposInstalados: [],
  actaCargosAdicionales: [],
  actaTelCliente: [],
  actaEmailCliente: [],
  actaEstado: "PENDIENTE", // ANULADO 'PENDIENTE', 'PROCESADO' ,'EN PROCESO'
  actaValorRouter: "0",
  actaValorPatch: "0",
  actaDetalleEstado: "",
  actaIdVendedor: 0,
  actaDocVendedor: "",
  actaNombreVendedor: "",
  planNombreInternet: "",
  planValorInternet: "",
  planMegasInternet: "",
  actaIdPlanInternet: 0,
  actaUbicacion: {
    longitud: "",
    latitud: "",
  },
};

export const columnsEquiposInstalados = [
  {
    id: "cantidad",
    label: "cantidad",
    minWidth: 75,
    tipo: "string",
    propiedad: "cantidad",
    align: "center",
  },
  {
    id: "material",
    label: "material",
    minWidth: 75,
    tipo: "string",
    propiedad: "material",
    align: "center",
  },
  {
    id: "marca",
    label: "marca",
    minWidth: 75,
    tipo: "string",
    propiedad: "marca",
    align: "center",
  },
  {
    id: "serie",
    label: "serie",
    minWidth: 75,
    tipo: "string",
    propiedad: "serie",
    align: "center",
  },
  {
    id: "observaciones",
    label: "observaciones",
    minWidth: 75,
    tipo: "string",
    propiedad: "observaciones",
    align: "center",
  },
];
// DESHABILITAR BOTON
export const validacionHijo = (itemHijo) => {
  return itemHijo.material.trim() === "" || itemHijo.cantidad < 0;
};
export const objectDefaultEquiposInstalado = {
  order: 1,
  id: "",
  cantidad: 1,
  material: "",
  marca: "",
  serie: "",
  observaciones: "",
};

export const columnsCargo = [
  {
    id: "item",
    label: "item",
    minWidth: 75,
    tipo: "string",
    propiedad: "item",
    align: "center",
  },
  {
    id: "costoUnitario",
    label: "Costo Unitario",
    minWidth: 75,
    tipo: "string",
    propiedad: "costoUnitario",
    align: "center",
  },
  {
    id: "cantidad",
    label: "cantidad",
    minWidth: 75,
    tipo: "string",
    propiedad: "cantidad",
    align: "center",
  },
  {
    id: "valor",
    label: "valor",
    minWidth: 75,
    tipo: "string",
    propiedad: "valor",
    align: "center",
  },
  {
    id: "descripcion",
    label: "descripcion",
    minWidth: 75,
    tipo: "string",
    propiedad: "descripcion",
    align: "center",
  },
];
// DESHABILITAR BOTON
export const validacionHijoCargo = (itemHijo) => {
  return (
    itemHijo.item.trim() === "" ||
    itemHijo.costoUnitario === 0 ||
    itemHijo.cantidad < 1 ||
    itemHijo.valor === 0
  );
};
export const objectDefaultCargo = {
  order: 1,
  id: "",
  item: "",
  costoUnitario: 0,
  cantidad: 1,
  valor: "",
  descripcion: "",
};
