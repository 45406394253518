import React, { createRef } from "react";

// MATERIAL
import { makeStyles } from "@mui/styles";
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItemButton,
} from "@mui/material";
import {
  Cancel,
  Downloading,
  Outbox,
  Search,
  SimCardDownload,
  UploadFile,
} from "@mui/icons-material";
const useStyles = makeStyles((theme) => ({
  ocultar: {
    display: "none",
  },
}));
export default function ConfirmacionDialog(props) {
  const inputEl = createRef();
  const inputTxtSri = createRef();
  const inputXmlSri = createRef();
  // const focusInput = () => {
  //   inputEl.current.click();
  // };
  const classes = useStyles();
  const {
    open,
    setopen,
    ocultarBuscar,
    setopenMB,
    subirArchivoCompras,
    funcArchivo,
    ats,
    setopenATS,
    funcArchivoTxtSri,
    funcArchivoXmlSri,
    subirArchivoVentas,
    autorizarPorLote,
    descargarDocumentosDesdeSri,
  } = props;

  const handleClose = () => {
    setopen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box>
        <Box display="flex" justifyContent="space-between">
          <DialogTitle id="alert-dialog-title">Opciones</DialogTitle>
          <IconButton aria-label="" onClick={handleClose} color="secondary">
            <Cancel />
          </IconButton>
        </Box>
        <input
          className={classes.ocultar}
          type="file"
          name="myImage"
          onChange={funcArchivo}
          ref={inputEl}
        />
        <input
          className={classes.ocultar}
          type="file"
          name="myImage"
          onChange={funcArchivoTxtSri}
          ref={inputTxtSri}
        />
        <input
          multiple
          className={classes.ocultar}
          type="file"
          name="myImage"
          onChange={(e) => {
            setopen(false);
            funcArchivoXmlSri(e);
          }}
          ref={inputXmlSri}
        />

        <Box pr={1} pl={1} pb={1}>
          <DialogContent>
            <List component="nav">
              {/* {ocultarBuscar ? null : (
                <ListItem button onClick={() => setopenMB(true)}>
                  <ListItemIcon>
                    <Search color="secondary" />
                  </ListItemIcon>
                  <ListItemText primary="Busqueda avanzada" />
                </ListItem>
              )} */}
              {ats ? (
                <ListItem
                  button
                  onClick={() => {
                    setopenATS(true);
                  }}
                >
                  <ListItemIcon>
                    <SimCardDownload color="secondary" />
                  </ListItemIcon>
                  <ListItemText primary="Generar ATS" />
                </ListItem>
              ) : null}
              {subirArchivoCompras && (
                <>
                  <ListItem
                    button
                    onClick={() => {
                      inputTxtSri.current.click();
                    }}
                  >
                    <ListItemIcon>
                      <UploadFile color="secondary" />
                    </ListItemIcon>
                    <ListItemText primary="Subir TXT SRI" />
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => {
                      inputXmlSri.current.click();
                    }}
                  >
                    <ListItemIcon>
                      <UploadFile color="secondary" />
                    </ListItemIcon>
                    <ListItemText primary="Subir XML SRI(COMPRAS)" />
                  </ListItem>
                </>
              )}
              {subirArchivoVentas && (
                <ListItem
                  button
                  onClick={() => {
                    inputXmlSri.current.click();
                  }}
                >
                  <ListItemIcon>
                    <UploadFile color="secondary" />
                  </ListItemIcon>
                  <ListItemText primary="Subir XML RETENCIÓN" />
                </ListItem>
              )}
              {autorizarPorLote && (
                <ListItemButton
                  onClick={() => {
                    setopen(false);
                    autorizarPorLote();
                  }}
                >
                  <ListItemIcon>
                    <Outbox color="secondary" />
                  </ListItemIcon>
                  <ListItemText primary="Autorizar por lote" />
                </ListItemButton>
              )}

              {descargarDocumentosDesdeSri && (
                <ListItemButton
                  onClick={() => {
                    setopen(false);
                    descargarDocumentosDesdeSri();
                  }}
                >
                  <ListItemIcon>
                    <Downloading color="secondary" />
                  </ListItemIcon>
                  <ListItemText primary="Descargar XML SRI" />
                </ListItemButton>
              )}
            </List>
          </DialogContent>
          <DialogActions></DialogActions>
        </Box>
      </Box>
    </Dialog>
  );
}
