import React, { memo, useContext, useMemo } from "react";

import {
  Box,
  Collapse,
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

import { useState } from "react";

import { withRouter } from "react-router";

import AuthContext from "../../../../Context/Auth/AuthContext";
import {
  AddCircle,
  Cancel,
  Check,
  Create,
  DeleteForever,
  ExpandLess,
  ExpandMore,
  Restore,
} from "@mui/icons-material";

import RowAgregarItem from "../Componentes/RowItems/RowAgregarItem";
import RowItem from "../Componentes/RowItems/RowItem";
import ConfirmacionDialog from "../../../components/Dialogs/ConfirmacionDialog";
import ButtonsAction from "../../../components/Tablas/ButtonsAction";
import TablaCabecera from "../../../components/Tablas/TablaCabecera";
import { trimPropsItem } from "../../../../config/const";
import { usePermiso } from "../../../../hooks/usePermiso";
import {
  useRowStyles,
  butonIconTransition,
} from "../../../../styles/stylesRows";
import { columnasHijo } from "../data";
const Row = (props) => {
  // CLASES
  const classes = useRowStyles();

  // PROPS
  const {
    arrayExport,
    setarrayExport,
    row,
    index,
    campoExport,
    socket,
  } = props;
  const claseCelda = `${
    index % 2 === 0 ? classes.celda : classes.celdaSecundario
  } ${
    row.editado === true
      ? classes.celdaEditado
      : row.nuevo === true
      ? classes.celdaNuevo
      : row.eliminado === true
      ? classes.celdaEliminado
      : ""
  }`;
  // EDITAR
  const [editar, seteditar] = useState(false);
  // HOOK DE PERMISO
  const { tienePermiso, alertaPermiso } = usePermiso("Surtidores");
  // CONTEXT
  const { usuario } = useContext(AuthContext);
  // ABRE EL DIALOG DE CONFIRMACION
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  // SI SE AGREGA UN ITEM A LA TABLA HIJA
  const [agregarItemLista, setagregarItemLista] = useState(false);

  // EXPANDIR
  const [expandir, setexpandir] = useState(false);
  // COPIA DEL REGISTRO PARA EDITAR
  const [item, setitem] = useState({ ...row });
  // COLUMNAS DE LA TABLA HIJA

  //onChange
  const handleChange = (e) => {
    setitem({ ...item, [e.target.name]: e.target.value });
  };
  // DESHABILITAR BOTON
  const botonDisabled = () => {
    // if (item.nombre_surtidor.trim() === "") {
    if (false) {
      return true;
    }
  };
  // ELIMINAR EL REGISTRO
  const handleEliminar = async (_id) => {
    try {
      socket.current.emit("client:eliminarData", {
        tabla: "surtidor",
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        user: usuario.usuario,
        ciuEmpresa: usuario.rucempresa,
        [campoExport]: row[campoExport],
      });
      setopenConfirmDialog(false);
    } catch (error) {}
  };
  const funcSubmit = (agregando) => {
    try {
      if (agregando) {
        socket.current.emit(
          "client:guardarData",
          trimPropsItem({
            ...row,

            tabla: "surtidor",
            rucempresa: usuario.rucempresa,
            rol: usuario.rol,
            user: usuario.usuario + "/+/" + row[campoExport],
            ciuEmpresa: usuario.rucempresa,
            [campoExport]: row[campoExport],
          })
        );
      } else {
        socket.current.emit(
          "client:actualizarData",
          trimPropsItem({
            ...item,
            tabla: "surtidor",
            rucempresa: usuario.rucempresa,
            rol: usuario.rol,
            user: usuario.usuario,
            ciuEmpresa: usuario.rucempresa,
          })
        );
      }

      seteditar(false);
    } catch (error) {}
  };

  // FUNCION PARA EDITAR, ABRE EL MODAL COLOCA EN EDITAR EL MODAL Y CAMBIA EL OBJETO ACTIVO
  const funcionEdit = () => {
    setitem({ ...row });
    seteditar(true);
  };

  const pistolas = useMemo(() => {
    const items = [row.estacion1, row.estacion2, row.estacion3].filter(
      (estacion) => estacion
    );
    return items;
  }, [row]);
  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        categoria="warning"
        titulo={`¿Esta seguro de eliminar este registro?`}
        botones={[
          {
            tooltip: "Se eliminaran los registros",
            texto: "SI",
            funcion: () => {
              handleEliminar();
            },
            disabled: false,
            Icon: DeleteForever,
            color: "error",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "NO",
            funcion: () => {
              setopenConfirmDialog(false);
            },
            disabled: false,
            Icon: Cancel,
            color: "secondary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <TableRow className={classes.root} component={"form"}>
        {/* boton */}
        <ButtonsAction
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          campoExport={campoExport}
          row={row}
          claseCelda={claseCelda}
          ocultarCelda
          botones={[
            {
              tooltip: "Recuperar",
              texto: "",
              funcion: () => {
                if (!tienePermiso("agregar")) {
                  return alertaPermiso("agregar");
                }
                funcSubmit(true);
              },
              // disabled: usuario.usuario !== row.user.split("/+/")[0],
              Icon: Restore,
              color: "success",
              id: 3,
              ocultar: !row.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Eliminar Registro",
              texto: "",
              funcion: () => {
                if (!tienePermiso("eliminar")) {
                  return alertaPermiso("eliminar");
                }
                setopenConfirmDialog(true);
              },
              disabled: false,
              Icon: DeleteForever,
              color: "error",
              id: 1,
              ocultar: editar || row.eliminado || true,
              tipo: "icono",
              variante: "contained",
              size: "small",
              // css
              sx: butonIconTransition,
            },
            {
              tooltip: "Editar Registro",
              texto: "",
              funcion: () => {
                if (!tienePermiso("editar")) {
                  return alertaPermiso("editar");
                }
                funcionEdit();
              },
              disabled: false,
              Icon: Create,
              color: "secondary",
              id: 2,
              ocultar: editar || row.eliminado || true,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Cancelar",
              texto: "",
              funcion: () => {
                seteditar(false);
                setitem({ ...row });
                // setItemEdit(itemHijo);
              },
              disabled: false,
              Icon: Cancel,
              color: "error",
              id: 1,
              ocultar: !editar,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Guardar Edición",
              texto: "",
              funcion: () => {
                funcSubmit();
              },
              disabled: botonDisabled(),
              Icon: Check,
              color: "success",
              id: 2,
              ocultar: !editar,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
              submit: true,
            },
            {
              tooltip: "Expandir",
              texto: pistolas.length,
              funcion: () => {
                setexpandir((prev) => !prev);
              },
              disabled: false,
              Icon: expandir ? ExpandLess : ExpandMore,
              color: "secondary",
              id: 3,
              ocultar: true,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
          ]}
        />

        {editar ? (
          <>
            <TableCell size="small" align="center" className={claseCelda}>
              <TextField
                value={item.nombre_surtidor}
                name="nombre_surtidor"
                onChange={(e) => handleChange(e)}
                size="small"
                fullWidth
              />
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              <TextField
                value={item.lado}
                name="lado"
                onChange={(e) => handleChange(e)}
                size="small"
                fullWidth
                select
                SelectProps={{ displayEmpty: true }}
              >
                <MenuItem value="" disabled>
                  Seleccione
                </MenuItem>
                {["A", "B"].map((item) => {
                  return (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  );
                })}
              </TextField>
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              {item.user}
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              {new Date(item.fecReg).toLocaleString("es-ES", {
                weekday: "short",
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                minute: "2-digit",
                hour: "2-digit",
              })}
            </TableCell>
          </>
        ) : (
          <>
            {/* <TableCell size="small" align="center" className={claseCelda}>
              {row.codigoCombustible}
            </TableCell> */}
            <TableCell size="small" align="center" className={claseCelda}>
              {new Date(row.fechaHora).toLocaleString("es-ES", {
                weekday: "short",
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                minute: "2-digit",
                hour: "2-digit",
              })}
            </TableCell>
            <TableCell
              size="small"
              align="center"
              className={claseCelda}
              sx={{
                color: !row.nombreCombustible ? "red" : "",
                fontWeight: !row.nombreCombustible ? "bold" : "",
              }}
            >
              {!row.nombreCombustible ? "SIN FACTURAR" : row.nombreCombustible}
            </TableCell>{" "}
            <TableCell size="small" align="center" className={claseCelda}>
              {row.pistola}
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              {row.numeroTanque === 1
                ? "SUPER"
                : row.numeroTanque === 2
                ? "EXTRA"
                : row.numeroTanque === 3
                ? "DIESEL"
                : "DESCONOCIDO"}
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              ${row.precioUnitario}
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              ${row.valorTotal}
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              {row.volTotal}
            </TableCell>
            {/* <TableCell size="small" align="center" className={claseCelda}>
              {row.tiempo}
            </TableCell> */}
            {/* <TableCell size="small" align="center" className={claseCelda}>
              {row.fechaHora}
            </TableCell> */}
            <TableCell size="small" align="center" className={claseCelda}>
              {row.totInicio}
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              {row.totFinal}
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              {row.facturado}
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              {row.usuario}
            </TableCell>
          </>
        )}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={21}>
          <Collapse in={expandir} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Box display="flex" alignItems="center">
                <Typography variant="body1" component="span">
                  Pistolas:
                </Typography>
                {/* <IconButton
                  size="small"
                  aria-label=""
                  color="secondary"
                  disabled={row.eliminado || pistolas.length === 3}
                  onClick={() => {
                    setagregarItemLista(true);
                  }}
                >
                  <AddCircle />
                </IconButton> */}
              </Box>
              {/* {pistolas.length} */}
              <Table size="small" aria-label="purchases">
                <TablaCabecera
                  input={{ id: "" }}
                  columns={columnasHijo}
                  habilitarOrdenar={false}
                />
                <TableBody component={"div"}>
                  {agregarItemLista ? (
                    <RowAgregarItem
                      item={item}
                      setitem={setitem}
                      setagregarItemLista={setagregarItemLista}
                      socket={socket}
                      pistolaNumero={pistolas.length}
                    />
                  ) : null}
                  {pistolas.map((itemHijo, index) => {
                    return (
                      <RowItem
                        itemHijo={itemHijo}
                        item={item}
                        setitem={setitem}
                        pistolaNumero={index + 1}
                        index={index}
                        key={index}
                        socket={socket}
                      />
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
