import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Divider,
  Typography,
  TextField,
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemAvatar,
  Avatar,
  ListItemText,
} from "@mui/material";

import { FormControlLabel } from "@mui/material";
const useStyles = makeStyles((theme) => ({
  formD: {
    flexGrow: 1,
    width: "45%",
    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  formAllW: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "95%",
    [theme.breakpoints.down("md")]: { padding: "0", margin: "2px" },
    [theme.breakpoints.down("sm")]: { padding: "0", margin: "2px" },
  },

  error: { color: "red", fontWeight: "bold" },
}));
const ListPersonas = ({
  item,
  setitem,
  data,
  propiedad,
  propiedadString,
  error,
  readOnly,
}) => {
  const [buscador, setbuscador] = useState("");

  const clases = useStyles();
  return (
    <>
      <Divider sx={{ width: "100%" }} />
      <Box display={"flex"} alignItems="center" className={clases.formAllW}>
        <Box
          display="flex"
          flexWrap="wrap"
          alignItems="center"
          justifyContent={"center"}
          mt={1}
          maxHeight={"60vh"}
          overflow="scroll"
          sx={{ overflowX: "hidden" }}
          className={clases.formD}
        >
          <Typography
            variant="body1"
            color={error[propiedadString] ? "error" : "initial"}
            className={clases.formD}
          >
            {propiedad.length === 0 || propiedad.length === 1
              ? "Seleccionado: "
              : "Seleccionados: "}
            {propiedad.length}
            <FormControlLabel
              label=""
              control={
                <Checkbox
                  disabled={readOnly}
                  checked={
                    data.length === 0
                      ? false
                      : buscador.trim() === ""
                      ? data
                          .filter((itemGuardia) => !itemGuardia.disabled)
                          .map((itemGuardia) => itemGuardia.perId)
                          .every((elemento) =>
                            propiedad
                              .map((itemelemento) => itemelemento.perId)
                              .includes(elemento)
                          )
                      : data
                          .filter((itemGuardia) => !itemGuardia.disabled)
                          .filter(
                            (itemGuardiaData) =>
                              `${itemGuardiaData.perApellidos} ${itemGuardiaData.perNombres}`
                                .toLowerCase()
                                .includes(buscador.toLowerCase()) ||
                              itemGuardiaData.perDocNumero
                                .toLowerCase()
                                .includes(buscador.toLowerCase())
                          )

                          .map((itemGuardia) => itemGuardia.perId)
                          .every((elemento) =>
                            propiedad
                              .map((itemelemento) => itemelemento.perId)
                              .includes(elemento)
                          )
                  }
                  onChange={(e) => {
                    if (buscador.trim() === "") {
                      if (e.target.checked) {
                        setitem({
                          ...item,
                          [propiedadString]: data.filter(
                            (itemGuardia) => !itemGuardia.disabled
                          ),
                        });
                      } else {
                        setitem({
                          ...item,
                          [propiedadString]: [],
                        });
                      }
                    } else {
                      if (e.target.checked) {
                        setitem({
                          ...item,
                          [propiedadString]: data
                            .filter((itemGuardia) => !itemGuardia.disabled)
                            .filter(
                              (itemGuardiaData) =>
                                `${itemGuardiaData.perApellidos} ${itemGuardiaData.perNombres}`
                                  .toLowerCase()
                                  .includes(buscador.toLowerCase()) ||
                                itemGuardiaData.perDocNumero
                                  .toLowerCase()
                                  .includes(buscador.toLowerCase())
                            ),
                        });
                      } else {
                        setitem({
                          ...item,
                          [propiedadString]: [],
                        });
                      }
                    }
                  }}
                  color="primary"
                />
              }
            />
          </Typography>
        </Box>
        <TextField
          size="small"
          variant="outlined"
          fullWidth
          label="Buscar"
          value={buscador}
          onChange={(e) => {
            setbuscador(e.target.value);
          }}
        />
      </Box>
      <Box className={clases.formAllW} maxHeight={"25vh"} overflow={"scroll"}>
        <List dense>
          {data
            .sort((a, b) => a.disabled - b.disabled)
            .filter((itemGuardiaData) => {
              if (buscador === "") {
                return true;
              } else {
                return (
                  `${itemGuardiaData.perApellidos} ${itemGuardiaData.perNombres}`
                    .toLowerCase()
                    .includes(buscador.toLowerCase()) ||
                  itemGuardiaData.perDocNumero
                    .toLowerCase()
                    .includes(buscador.toLowerCase())
                );
              }
            })
            .map((value) => {
              const checked = propiedad.some(
                (itemGuardia) => itemGuardia.perId === value.perId
              );
              if (readOnly && checked === false) {
                return null;
              }
              const labelId = `checkbox-list-secondary-label-${value}`;
              return (
                <ListItem
                  key={value.perId}
                  secondaryAction={
                    <Checkbox
                      disabled={value.disabled || readOnly}
                      edge="end"
                      onChange={(e) => {
                        if (e.target.checked) {
                          setitem({
                            ...item,
                            [propiedadString]: [...propiedad, value],
                          });
                        } else {
                          setitem({
                            ...item,
                            [propiedadString]: propiedad.filter(
                              (itemFisguardia) =>
                                itemFisguardia.perId !== value.perId
                            ),
                          });
                        }
                      }}
                      checked={checked}
                      inputProps={{
                        "aria-labelledby": labelId,
                      }}
                    />
                  }
                  disablePadding
                >
                  <ListItemButton disabled={value.disabled}>
                    <ListItemAvatar>
                      <Avatar
                        alt={`Avatar n°${value + 1}`}
                        src={`${value.perFoto}`}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      perId={labelId}
                      primary={`${value.perDocNumero} ${value.perApellidos} ${value.perNombres}`}
                      primaryTypographyProps={{
                        color: value.supervisor ? "primary" : "secondary",
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              );
            })}
        </List>
      </Box>
    </>
  );
};

export default ListPersonas;
