export const objectDefault = {
  // id_surtidor: 4,
  nombre_surtidor: "",
  lado: "A", //A O B
  user: "",
  fecreg: "",
  usuarios: [],
  // estacion1: {},

  // estacion1: {
  //   numero_pistola: 10,
  //   codigo_producto: "0101",
  //   nombre_producto: "EXTRA",
  // },
  // estacion2: {
  //   numero_pistola: 11,
  //   codigo_producto: "0185",
  //   nombre_producto: "SUPER",
  // },
  // estacion3: {
  //   numero_pistola: 12,
  //   codigo_producto: "0121",
  //   nombre_producto: "SUPER",
  // },
};

export const columns = [
  {
    id: "nombre_surtidor",
    label: "Nombre",
    minWidth: 25,
    tipo: "string",
    propiedad: "nombre_surtidor",
  },
  {
    id: "lado",
    label: "lado",
    minWidth: 25,
    tipo: "string",
    propiedad: "lado",
  },
  {
    id: "user",
    label: "user",
    minWidth: 25,
    tipo: "string",
    propiedad: "user",
  },
  {
    id: "fecreg",
    label: "Fec. Reg.",
    minWidth: 25,
    tipo: "string",
    propiedad: "fecreg",
  },
];
export const columnasHijo = [
  {
    id: "pistola",
    align: "left",
    minWidth: 150,
    tipo: "string",
    label: "pistola",
  },
  {
    id: "producto",
    align: "left",
    minWidth: 150,
    tipo: "string",
    label: "producto",
  },
  {
    id: "numero_tanque",
    align: "left",
    minWidth: 150,
    tipo: "string",
    label: "numero_tanque",
  },
];
