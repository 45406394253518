import React, { memo, useState, useContext, useEffect, useRef } from "react";

import { Box, Divider } from "@mui/material";

import Tabla from "./Componentes/Tabla";
import ModalB from "../../../components/Extra/ModalB";
import BarraTablas from "../../../components/BarraTablas/BarraTablas";
import clienteAxios from "../../../config/axios";
import AlertaContext from "../../../Context/Alerta/AlertaContext";
import AuthContext from "../../../Context/Auth/AuthContext";
import Modal from "./Componentes/Modal";
import io from "socket.io-client";
import { FechaExacta, fechaHora, socketUrl } from "../../../config/const";
import { usePermiso } from "../../../hooks/usePermiso";
import { TabsRolEliminado } from "../../components/Tablas/TabsRolEliminado";
const Contabilidad = (props) => {
  const { tienePermiso } = usePermiso("Recetas");
  const {
    idmascota,
    settab,
    setarrayExportHijos,
    desdeOtroLugarMascota,
    puedeExportar,
    itemRelacionadoPadre,
  } = props;
  // DATA DEL STATE

  const [rows, setrows] = useState(null);

  const columns = [
    {
      id: "recId",
      label: "Código",
      minWidth: 25,
      tipo: "string",
      propiedad: "recId",
    },
    {
      id: "recMascNombre",
      label: "Mascota",
      minWidth: 25,
      tipo: "string",
      propiedad: "recMascNombre",
      variante: "autocompleteDinamico",
      propiedadesMostrar: 2,
      campoData1: "mascNombre",
      campoData2: "mascPerNombre",
      getData: async (data) => {
        const res = await clienteAxios.get(`/mascotas/filtro/0?search=${data}`);
        return res;
      },
    },
    {
      id: "recPerNombrePropietario",
      label: "Propietario",
      minWidth: 25,
      tipo: "string",
      propiedad: "recPerNombrePropietario",
      variante: "autocompleteDinamico",
      propiedadesMostrar: 2,
      campoData1: "perDocNumero",
      campoData2: "perNombre",
      getData: async (data) => {
        const res = await clienteAxios.get(
          `/proveedores/filtro/0?search=${data}&estado=CLIENTE`
        );
        return res;
      },
    },
    {
      id: "recPerNombreDoc",
      label: "Doctor",
      minWidth: 250,
      tipo: "string",
      propiedad: "recPerNombreDoc",
      variante: "autocompleteDinamico",
      propiedadesMostrar: 2,
      campoData1: "perDocNumero",
      campoData2: "perNombre",
      getData: async (data) => {
        const res = await clienteAxios.get(
          `/proveedores/filtro/0?search=${data}&estado=VETERINARIO`
        );
        return res;
      },
    },
    {
      id: "recPeso",
      label: "Peso",
      minWidth: 25,
      tipo: "numero",
      propiedad: "recPeso",
    },
    {
      id: "recProxCita",
      label: "FecProx",
      minWidth: 25,
      tipo: "fecha",
      propiedad: "recProxCita",
    },
    {
      id: "recRecomendacion",
      label: "Observacion",
      minWidth: 200,
      tipo: "",
      propiedad: "recRecomendacion",
    },
    {
      id: "recCorreo",
      label: "Correo",
      minWidth: 25,
      tipo: "string",
      propiedad: "recCorreo",
    },
    {
      id: "recFecUpd",
      label: "Fec. Upd.",
      minWidth: 25,
      tipo: "fecha",
      propiedad: "recFecUpd",
    },
    {
      id: "recUser",
      label: "User",
      minWidth: 25,
      tipo: "string",
      propiedad: "recUser",
    },
  ];
  const defaultFullBuscador = () => {
    const json = {};
    columns.forEach((itemPropiedad) => {
      Object.entries({ itemPropiedad }).forEach((item) => {
        if (itemPropiedad.tipo === "string") {
          json[`${item[1].propiedad}`] = "";
        } else if (itemPropiedad.tipo === "numero") {
          json[`${item[1].propiedad}1`] = "";
          json[`${item[1].propiedad}2`] = "";
        } else if (itemPropiedad.tipo === "fecha") {
          json[`${item[1].propiedad}1`] = "";
          json[`${item[1].propiedad}2`] = "";
        }
      });
    });

    return json;
  };
  const [ObjectoActivo, setObjetoActivo] = useState({
    // recId: 1,
    recMascId: "",
    recMascNombre: "",
    recPerIdDoc: "",
    recPerNombreDoc: "",
    recPerIdPropietario: "",
    recPerNombrePropietario: "",
    recPeso: "",
    recUser: "",
    recEmpresa: "",
    recTipo: "",
    recCorreo: "PENDIENTE",
    recRecomendacion: "",
    recMascRaza: "",
    recMascSexo: "",
    recMascEdad: "",
    recPerTelefonoPropietario: "",
    recPerDocNumeroPropietario: "",
    recPerDireccionPropietario: "",
    recPerCelularPropietario: "",
    recPerEmailPropietario: "",
    recMedicamentos: {
      arrayMedicamentos: [],
    },
    recProductosAntiguos: [],
    recProxCita: FechaExacta,
    recFecReg: "",
    recObservacionProxCita: "",
    recProxCitaHora: "",
  });
  const [pagination, setpagination] = useState(null);
  // FUNCIONES STATE
  const getDataState = async (
    cantidad,
    page,
    search,
    input,
    orden,
    datos,
    rucempresa,
    eliminadoEstadoNuevo = eliminadoEstado,
    rolEstadoNuevo = rolEstado
  ) => {
    console.log(JSON.parse(datos));
    
    const res = await clienteAxios.get(
      `/recetas/?cantidad=${cantidad}&page=${page}&search=${
        search ? search : ""
      }&input=${input ? input : ""}&orden=${orden ? orden : ""}&datos=${
        datos ? datos : ""
      }&rucempresa=${rucempresa ? rucempresa : ""}&idmascota=${
        idmascota ? idmascota : ""
      }&allData=${tienePermiso(
        "allData",
        rolEstadoNuevo
      )}&rol=${rolEstadoNuevo}&eliminado=${eliminadoEstadoNuevo}`
    );

    return res;
  };

  const obtenerState = async (
    cantidad = 10,
    page = 0,
    search,
    input,
    orden,
    datos,
    rucempresa,
    eliminadoEstado,
    rolEstado
  ) => {
    const res = await getDataState(
      cantidad,
      page,
      search,
      input,
      orden,
      datos,
      rucempresa,
      eliminadoEstado,
      rolEstado
    );
    setrows(res.data.data.results);
    setpagination(res.data.data.pagination);
  };
  const addRowsState = async (items) => {
    setrows([...rows, ...items]);
  };
  const remplazarRowsState = async (
    cantidad,
    page,
    search,
    input,
    orden,
    datos,
    rucempresa,
    eliminadoEstado,
    rolEstado
  ) => {
    const res = await getDataState(
      cantidad,
      page,
      search,
      input,
      orden,
      datos,
      rucempresa,
      eliminadoEstado,
      rolEstado
    );
    setrows(res.data.data.results);
    setpagination(res.data.data.pagination);
    // dispatch({
    //   type: OBTENER,
    //   payload: {
    //     results: res.data.data.results,
    //     pagination: res.data.data.pagination,
    //   },
    // });
  };
  const agregarState = async (item) => {
    try {
      setrows([item, ...rows]);

      setpagination({
        ...pagination,
        numRows: pagination.numRows + 1,
      });
    } catch (error) {}
  };

  const editarState = async (item) => {
    try {
      const resRows = rows.map((itemR) => {
        if (itemR.recId === item.recId) {
          return item;
        } else {
          return itemR;
        }
      });

      setrows(resRows);
    } catch (error) {}
  };

  const eliminarState = async (_id) => {
    try {
      const resRows = rows.filter((row) => {
        return row.recId !== _id;
      });
      setrows(resRows);
      setpagination({
        ...pagination,
        numRows: pagination.numRows - 1,
      });
    } catch (error) {}
  };
  const eliminarSeleccionState = async (_ids) => {
    const resRows = rows.filter((row) => {
      return !_ids.some((item) => {
        return item === row.recId;
      });
    });
    setpagination({
      ...pagination,
      numRows: pagination.numRows - _ids.length,
    });
    setrows(resRows);
  };

  const changeObjetoActivoState = (item) => {
    setObjetoActivo(item);
    // dispatch({
    //   type: CHANGE_ACTIVO,
    //   payload: item,
    // });
  };

  const rowsImportState = (rows) => {
    // dispatch({
    //   type: IMPORTAR_ROWS,
    //   payload: rows,
    // });
    setrows(rows);
  };
  // FIN FUNCIONES STATE
  // FIN DATA STATE

  // FUNCIONES SOCKET
  const [tabla] = useState("receta");
  const [itemSocket, setitemSocket] = useState({
    tipo: "",
    item: {},
  });

  const socket = useRef();
  useEffect(() => {
    // Creates a WebSocket connection
    socket.current = io(socketUrl);
    socket.current.on("server:guardadoExitoso", (data) => {
      if (data.tabla !== tabla) {
        return;
      }

      setitemSocket({
        tipo: "agregar",
        item: data,
      });
    });
    socket.current.on("server:actualizadoExitoso", (data) => {
      if (data.tabla !== tabla) {
        return;
      }

      setitemSocket({
        tipo: "editar",
        item: data,
      });
    });
    socket.current.on("server:eliminadoExitoso", (data) => {
      if (data.tabla !== tabla) {
        return;
      }
      setitemSocket({
        tipo: "eliminar",
        item: data,
      });
    });
    socket.current.on("server:eliminadoExitosoSeleccionado", (data) => {
      if (data.tabla !== tabla) {
        return;
      }
      setitemSocket({
        tipo: "eliminarSeleccion",
        item: data,
      });
    });

    socket.current.on("server:error", (data) => {
      mostrarAlerta(
        data.msg ? data.msg : "Hubo un error",
        data.alert ? data.alert : "error"
      );
    });
    socket.current.on("server:idRegistro", (data) => {
      window.open(`${data.ruta}`, "_blank");
    });

    return () => {
      socket.current.disconnect();
    };
    // Destroys the socket reference
    // when the connection is closed
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    const { item, tipo } = itemSocket;

    if (tipo === "") {
      return;
    }
    if (!rows) {
      return;
    }
    if (usuario.rucempresa !== item.rucempresa) {
      return;
    }
    if (item.tabla !== tabla) {
      return;
    }
    if (desdeOtroLugarMascota && Number(item.recMascId) !== idmascota) {
      return;
    }
    const funcionAalerta = () => {
      if (item.msg === "") {
        return null;
      }
      if (!item.msg) {
        return null;
      }
      mostrarAlerta(item.msg, item.alert ? item.alert : "success");
    };

    if (tipo === "agregar") {
      agregarState(item);
    }
    if (tipo === "editar") {
      editarState(item);
    }
    if (tipo === "eliminar") {
      eliminarState(item.recId);
    }
    if (tipo === "eliminarSeleccion") {
      eliminarSeleccionState(item.lista);
    }
    funcionAalerta();
    setitemSocket({ tipo: "", item: {} });
    // eslint-disable-next-line
  }, [itemSocket]);

  const [open, setopen] = useState(false);
  const [buscando, setbuscando] = useState(false);
  const [editar, seteditar] = useState(false);
  const [search, setsearch] = useState("");
  const [cargando, setcargando] = useState(false);
  const [input, setinput] = useState({
    id: "recFecUpd",
    label: "ID",
    minWidth: 150,
    tipo: "string",

    propiedad: "recFecUpd",
  });
  const [arrayExport, setarrayExport] = useState([]);
  const [campoExport] = useState("recId");
  useEffect(() => {
    if (desdeOtroLugarMascota) {
      settab("");
      setarrayExportHijos(arrayExport.map((item) => item[campoExport]));
    }
    // eslint-disable-next-line
  }, [arrayExport]);
  const { mostrarAlerta } = useContext(AlertaContext);
  const {
    usuario: { rucempresa },
    usuario,
  } = useContext(AuthContext);
  const [orden, setorden] = useState(false);
  const [openMB, setopenMB] = useState(false);
  const [fullBuscador, setfullBuscador] = useState({});

  //pagina actual
  const [page, setPage] = React.useState(0);
  //filas por pagina
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [eliminadoEstado, seteliminadoEstado] = useState(false);
  const [rolEstado, setrolEstado] = useState(usuario.rol[0]);

  //
  const handleChange = (e) => {
    setfullBuscador({ ...fullBuscador, [e.target.name]: e.target.value });
  };
  // const objeto = {
  //   orden: 1,
  //   path: "/Compras",
  //   name: "Compras",
  //   icon: "Store",
  //   component: "Compras",
  //   layout: "/Contable",
  //   categoria: "/Compras_",
  //   tipo: "subCategoria",
  //   ruta: true,
  //   expandible: false,
  //   permisos: ["ADMIN", "USUARIO"],
  //   añadir: ["ADMIN", "USUARIO"],
  //   editar: ["ADMIN", "USUARIO"],
  //   ver: ["ADMIN", "USUARIO"],
  //   eliminar: ["ADMIN", "USUARIO"],
  //   impExp: ["ADMIN", "USUARIO"],
  //   categoriaEmpresa: ["VETERINARIA"],
  //   descripcion: "Ver sus registro de mercaderías o servicios",
  //   allData: ["ADMIN", "USUARIO"],
  //   agregar: ["ADMIN", "USUARIO"],
  //   tutoriales: [],
  //   permisosIndividuales: {
  //     agregar: [],
  //     editar: [],
  //     eliminar: [],
  //     allData: [],
  //     permisos: [],

  //   },
  // };
  const funcionB = async () => {
    setcargando(true);
    await remplazarRowsState(
      rowsPerPage,
      0,
      search,
      input.id,
      orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      eliminadoEstado,
      rolEstado
    );
    setPage(0);
    setbuscando(true);
    setopenMB(false);
    setcargando(false);
  };

  const handleSearch = async () => {
    setarrayExport([]);
    setcargando(true);
    await remplazarRowsState(
      rowsPerPage,
      0,
      search,
      input.id,
      orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      eliminadoEstado,
      rolEstado
    );

    setPage(0);
    setcargando(false);
  };
  useEffect(() => {
    const fullBRes = defaultFullBuscador();

    setfullBuscador(fullBRes);
    // eslint-disable-next-line
  }, []);
  const funcionDefault = async (
    { eliminadoEstado, rolEstado } = { eliminadoEstado, rolEstado }
  ) => {
    setcargando(true);
    const fullBRes = defaultFullBuscador();
    setfullBuscador(fullBRes);
    await remplazarRowsState(
      rowsPerPage,
      0,
      "",
      input.id,
      orden,
      JSON.stringify(fullBRes),
      rucempresa,
      eliminadoEstado,
      rolEstado
    );

    setPage(0);
    setsearch("");
    setbuscando(false);
    setcargando(false);
    setopenMB(false);
  };

  return (
    <Box>
      <BarraTablas
        search={search}
        setsearch={setsearch}
        funcion={handleSearch}
        buscando={buscando}
        setbuscando={setbuscando}
        setopen={(boolean) => {
          if (desdeOtroLugarMascota) {
            setObjetoActivo({
              ...ObjectoActivo,
              recMascId: itemRelacionadoPadre.mascId,
              recMascNombre: itemRelacionadoPadre.mascNombre,
              recPerIdPropietario: itemRelacionadoPadre.mascPerId,
              recPerNombrePropietario: itemRelacionadoPadre.mascPerNombre,
              recMascRaza: itemRelacionadoPadre.mascRaza,
              recMascSexo: itemRelacionadoPadre.mascSexo,
              recMascEdad: itemRelacionadoPadre.mascEdad,
              recPerTelefonoPropietario: itemRelacionadoPadre.mascPerTelefono,
              recPerDocNumeroPropietario: itemRelacionadoPadre.mascPerDocNumero,
              recPerDireccionPropietario: itemRelacionadoPadre.mascPerDireccion,
              recPerCelularPropietario: itemRelacionadoPadre.mascPerCelular,
              recPerEmailPropietario: itemRelacionadoPadre.mascPerEmail,
            });
          }
          setopen(boolean);
        }}
        open={open}
        openMB={openMB}
        setopenMB={setopenMB}
        LabelBuscador="Buscar por: Nombre Mascota, Nombre Propietario"
        funcionDefault={funcionDefault}
        subirArchivo={false}
        ocultarMigasDePan={desdeOtroLugarMascota}
        ocultarOpciones={false}
      />{" "}
      <ModalB
        defaultFullBuscador={defaultFullBuscador}
        openMB={openMB}
        setopenMB={setopenMB}
        titulo={"Busqueda avanzada (Recetas)"}
        fullBuscador={fullBuscador}
        setfullBuscador={setfullBuscador}
        arrayProps={columns}
        handleChange={handleChange}
        funcionB={funcionB}
        buscando={buscando}
        funcionDefault={funcionDefault}
      />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems=" stretch"
        width="100%"
      >
        <TabsRolEliminado
          setarrayExport={setarrayExport}
          filtrarEliminar={async (nuevoEstadoEliminado) => {
            seteliminadoEstado(nuevoEstadoEliminado);
            await funcionDefault({
              eliminadoEstado: nuevoEstadoEliminado,
              rolEstado,
            });
          }}
          cargando={cargando}
          filtrarRol={async (nuevoEstadoRol) => {
            setrolEstado(nuevoEstadoRol);
            await funcionDefault({
              eliminadoEstado,
              rolEstado: nuevoEstadoRol,
            });
          }}
          eliminadoEstado={eliminadoEstado}
          rolEstado={rolEstado}
        />
      </Box>
      <Divider />
      <Tabla
        eliminadoEstado={eliminadoEstado}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        search={search}
        input={input}
        orden={orden}
        setinput={setinput}
        setorden={setorden}
        open={open}
        setopen={setopen}
        buscando={buscando}
        cargando={cargando}
        setcargando={setcargando}
        fullBuscador={fullBuscador}
        //  STATE
        rows={rows}
        columns={columns}
        ObjectoActivo={ObjectoActivo}
        pagination={pagination}
        // FUNCIONES STATE
        getDataState={getDataState}
        obtenerState={obtenerState}
        addRowsState={addRowsState}
        remplazarRowsState={remplazarRowsState}
        agregarState={agregarState}
        editarState={editarState}
        eliminarState={eliminarState}
        eliminarSeleccionState={eliminarSeleccionState}
        changeObjetoActivoState={changeObjetoActivoState}
        rowsImportState={rowsImportState}
        arrayExport={arrayExport}
        setarrayExport={setarrayExport}
        puedeExportar={puedeExportar}
        desdeOtroLugarMascota={desdeOtroLugarMascota}
        seteditar={seteditar}
        socket={socket}
      />
      <Modal
        editar={editar}
        seteditar={seteditar}
        setopen={setopen}
        open={open}
        ObjectoActivo={ObjectoActivo}
        changeObjetoActivoState={changeObjetoActivoState}
        socket={socket}
      />
    </Box>
  );
};

export default memo(Contabilidad);
