import React, { memo, useEffect, createRef } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  TextField,
  FormControl,
  Popper,
  MenuItem,
  Autocomplete,
  Typography,
} from "@mui/material";

import {
  AddCircleOutlineTwoTone,
  Cancel,
  Check,
  Create,
} from "@mui/icons-material";

import { useState } from "react";

import { useContext } from "react";
import AlertaContext from "../../../../../Context/Alerta/AlertaContext";
import clienteAxios from "../../../../../config/axios";
import AuthContext from "../../../../../Context/Auth/AuthContext";
import { v4 } from "uuid";
import { trimPropsItem } from "../../../../../config/const";
import { calcularProducto } from "../helpers";
import { calcularTotales } from "../helpers/calcularTotales";
const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  celda: {
    fontSize: ".7rem !important",
    "&:hover": {
      opacity: ".7",
      cursor: "pointer",
    },
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
  margin: {
    margin: "0px",
    padding: "2px",
  },
}));
const RowAgregarProducto = (props) => {
  const {
    agregar,
    setagregar,
    setitem,
    item,
    setopenInventario,

    //PARA EL CODIGO DE BARRA SAQUE ESTOS DATOS
    producto,
    setproducto,
    itemRow,
    setItemRow,
    setfocusCantidad,
    focusCantidad,
    inputRef,
    desdePeluqueria,
    subcategoriasData,
    venProductos,
    seteditarInventario,
    changeObjetoActivoStateInventario,
    socket,
    formPorcentaje = 0,
  } = props;
  const inputEl = createRef();
  const productoDefault = {
    invSerie: "0",
    invTipo: "",
    invNombre: "",
    invDescripcion: "",
    invCosto1: "",
    invCosto2: "",
    invCosto3: "",
    invStock: "",
    invIva: "SI",
    invIncluyeIva: "SI",
    invEstado: "ACTIVA",
    invBodegas: [],
    invprecios: [],
    invProveedor: "",
    invCategoria: "",
    invSubCategoria: "",
    invUnidad: "",
    invMarca: "",
    invSubsidio: "0",
    invPlanCuenta: "",
    invEmpresa: "",
    invUser: "admin",
    invFecReg: "",
    Todos: "",
  };
  const [productoSelect, setproductoSelect] = useState(productoDefault);
  const focusInput = () => {
    inputEl.current.focus();
  };
  const { mostrarAlerta } = useContext(AlertaContext);
  const { usuario } = useContext(AuthContext);
  const [productosData, setproductosData] = useState([]);

  useEffect(() => {
    if (agregar) {
      setItemRow({
        ...itemRow,
        descPorcentaje: item.venDescPorcentaje,
      });
    } // eslint-disable-next-line
  }, [item]);
  useEffect(() => {
    socket.current.on("server:actualizadoExitoso", (data) => {
      if (data.tabla === "inventario" && data.invUser === usuario.usuario) {
        setproductoSelect(data);
        setproductosData((prev) =>
          prev.map((itemPrev) => {
            if (itemPrev.invId === data.invId) {
              return data;
            } else {
              return itemPrev;
            }
          })
        );
      }
    });

    // eslint-disable-next-line
  }, []);

  const PopperMy = function(props) {
    return (
      <Popper {...props} style={useRowStyles.popper} placement="bottom-start" />
    );
  };
  const botonDisabled = () => {
    if (
      itemRow.descripcion === "" ||
      itemRow.cantidad === "" ||
      itemRow.valUnitarioInterno === "" ||
      itemRow.descPorcentaje === ""
    ) {
      return true;
    } else {
      return false;
    }
  };

  const classes = useRowStyles();
  //gql eliminar user

  const handleAgregar = async () => {
    try {
      const venProductos = [
        trimPropsItem({
          ...itemRow,
          codigo: itemRow.codigo === "" ? v4().substring(0, 8) : itemRow.codigo,
        }),
        ...item.venProductos,
      ].map((productoMap) => {
        return calcularProducto({
          producto: productoMap,
          formPorcentaje,
          iva: usuario.iva,
        });
      });

      const {
        venSubTotal12,
        venSubtotal0,
        venDescuento,
        venSubTotal,
        venTotalIva,
        venTotal,
        venCostoProduccion,
      } = calcularTotales(venProductos);
      setitem({
        ...item,
        venProductos,
        venSubTotal12,
        venSubtotal0,
        venDescuento,
        venSubTotal,
        venTotalIva,
        venTotal,
        venCostoProduccion,
      });
      setproductoSelect(productoDefault);
      setagregar(false);
      setItemRow({
        stock: 0,
        subcategoria: "",
        limiteStock: false,
        cantidad: "1",
        codigo: "",
        descripcion: "",
        valUnitarioInterno: "",
        valorUnitario: "",
        recargoPorcentaje: "",
        recargo: "",
        descPorcentaje: "",
        descuento: "",
        precioSubTotalProducto: "",
        valorIva: "",
        costoProduccion: "",
        llevaIva: usuario.rucempresa === "DAYA" ? "SI" : "NO",
        incluyeIva: usuario.rucempresa === "DAYA" ? "SI" : "NO",
      });
      setfocusCantidad(false);
    } catch (error) {
      console.log({ error });
    }
  };

  if (!agregar) {
    return null;
  }
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell padding="none" style={{ borderRight: "1px solid #ccc" }}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-around"
            ml={1}
            mr={1}
          >
            <IconButton
              className={classes.margin}
              onClick={() => {
                setagregar(false);
                setItemRow({
                  stock: 0,
                  subcategoria: "",
                  limiteStock: false,
                  cantidad: "1",
                  codigo: "",
                  descripcion: "",
                  valUnitarioInterno: "",
                  valorUnitario: "",
                  recargoPorcentaje: "",
                  recargo: "",
                  descPorcentaje: "",
                  descuento: "",
                  precioSubTotalProducto: "",
                  valorIva: "",
                  costoProduccion: "",
                  llevaIva: usuario.rucempresa === "DAYA" ? "SI" : "NO",
                  incluyeIva: usuario.rucempresa === "DAYA" ? "SI" : "NO",
                });
              }}
            >
              <Cancel fontSize="small" color="secondary" />
            </IconButton>
            <IconButton
              className={classes.margin}
              disabled={botonDisabled ? botonDisabled() : false}
              onClick={() => {
                handleAgregar();
              }}
            >
              <Check
                fontSize="small"
                color={
                  botonDisabled
                    ? botonDisabled()
                      ? "inherit"
                      : "secondary"
                    : "secondary"
                }
              />
            </IconButton>
          </Box>
        </TableCell>
        {/* las demas columnas */}
        {desdePeluqueria || item.venObservacion.includes("DESDEPELUQUERIA") ? (
          <TableCell
            padding="checkbox"
            size="small"
            align="center"
            className={classes.celda}
          >
            <FormControl fullWidth>
              <Autocomplete
                PopperComponent={PopperMy}
                options={subcategoriasData.map((item) => {
                  return item.subNombre;
                })}
                props
                getOptionLabel={(option) => {
                  return option;
                }}
                id="controlled-demo"
                disableClearable={true}
                value={itemRow.subcategoria}
                onChange={async (event, newValue) => {
                  if (newValue) {
                    setItemRow({
                      ...itemRow,

                      subcategoria: newValue,
                      limiteStock: false,
                      stock: 0,
                      cantidad: "1",
                      codigo: "",
                      descripcion: "",
                      valUnitarioInterno: "",
                      valorUnitario: "",
                      llevaIva: usuario.rucempresa === "DAYA" ? "SI" : "NO",
                      incluyeIva: usuario.rucempresa === "DAYA" ? "SI" : "NO",
                    });
                    setproductosData([]);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    SelectProps={{
                      MenuProps: {
                        PaperProps: {
                          style: {
                            fontSize: "1px",
                            maxHeight: 48 * 4.5 + 8,
                            width: 5000,
                          },
                        },
                      },
                    }}
                    style={{ fontSize: "2px" }}
                    required
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                    }}
                  />
                )}
              />
            </FormControl>
          </TableCell>
        ) : null}
        <TableCell
          padding="checkbox"
          size="small"
          align="center"
          className={classes.celda}
        >
          <FormControl fullWidth>
            <Autocomplete
              PopperComponent={PopperMy}
              options={productosData.map((item) => {
                return (
                  item.invNombre +
                  "/+/" +
                  item.invSerie +
                  "/+/" +
                  item.invStock +
                  "/+/" +
                  item.invIncluyeIva +
                  "/+/" +
                  item.invTipo +
                  "/+/" +
                  item.invIva
                );
              })}
              props
              getOptionLabel={(option) => {
                return option
                  .replace("/+/", " ** ")
                  .replace("/+/", " ** ")
                  .split("/+/")[0];
              }}
              id="controlled-demo"
              disableClearable={true}
              value={itemRow.descripcion}
              onChange={async (event, newValue) => {
                if (newValue) {
                  const options = newValue.split("/+/");
                  const productoF = productosData.find(
                    (productoItem) => productoItem.invSerie === options[1]
                  );
                  setproductoSelect(productoF);
                  if (
                    productoF.invTipo !== "SERVICIO" &&
                    Number(productoF.invStock) === 0
                  ) {
                    return mostrarAlerta(
                      `No hay stock de ${productoF.invNombre}`,
                      "error"
                    );
                  }
                  const existe = venProductos.some(
                    (itemProducto) => itemProducto.codigo === productoF.invSerie
                  );
                  if (existe) {
                    return mostrarAlerta(
                      `Este producto ya esta agregado, si desea agregar más aumente la cantidad del mismo.`,
                      "error"
                    );
                  }
                  setproducto(productoF);

                  setItemRow({
                    ...itemRow,
                    limiteStock: productoF.invTipo !== "SERVICIO",
                    cantidad:
                      productoF.invTipo === "SERVICIO"
                        ? "1"
                        : Number(itemRow.cantidad) > Number(options[2])
                        ? options[2]
                        : itemRow.cantidad,
                    stock:
                      productoF.invTipo === "SERVICIO" ? 1 : Number(options[2]),
                    descripcion: options[0],
                    codigo: options[1],
                    incluyeIva: options[3],
                    llevaIva: options[5],
                    valorUnitario: productoF.invprecios[0],
                    valUnitarioInterno: productoF.invprecios[0],
                  });
                  focusInput();
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  autoFocus={focusCantidad ? false : true}
                  size="small"
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        style: {
                          fontSize: "1px",
                          maxHeight: 48 * 4.5 + 8,
                          width: 5000,
                        },
                      },
                    },
                  }}
                  style={{ fontSize: "2px" }}
                  required
                  fullWidth
                  onChange={async (e) => {
                    if (e.target.value.length >= 301) {
                      setItemRow({
                        ...itemRow,
                        descripcion: e.target.value.slice(0, 300),
                      });
                    } else {
                      setItemRow({
                        ...itemRow,

                        descripcion: e.target.value,
                      });
                    }

                    try {
                      if (e.target.value.length < 3) {
                        return;
                      }
                      const res = await clienteAxios.get(
                        `/inventario/filtro/0?empresa=${
                          usuario.rucempresa
                        }&search=${e.target.value}&tipo=${
                          desdePeluqueria ||
                          item.venObservacion.includes("DESDEPELUQUERIA")
                            ? "PELUQUERIA"
                            : ""
                        }&subCategoria=${
                          desdePeluqueria ||
                          item.venObservacion.includes("DESDEPELUQUERIA")
                            ? itemRow.subcategoria
                            : ""
                        }`
                      );
                      console.log({ res });

                      // invDescripcion;

                      if (!res.data.data) {
                        return mostrarAlerta(res.data.msg, "error");
                      }

                      setproductosData(res.data.data);
                    } catch (error) {}
                  }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        <Typography
                          color={
                            itemRow.descripcion.length === 300
                              ? "error"
                              : "secondary"
                          }
                        >
                          {itemRow.descripcion.length}/300
                        </Typography>
                        <IconButton
                          size="small"
                          aria-label=""
                          onClick={async () => {
                            // seteditarProveedor(false);
                            setopenInventario(true);
                          }}
                        >
                          <AddCircleOutlineTwoTone color="primary" />
                        </IconButton>
                        {productoSelect.invSerie === "0" ? null : (
                          <IconButton
                            size="small"
                            aria-label=""
                            onClick={async () => {
                              changeObjetoActivoStateInventario(productoSelect);
                              seteditarInventario(true);
                              setopenInventario(true);
                            }}
                          >
                            <Create color="primary" />
                          </IconButton>
                        )}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </FormControl>
        </TableCell>
        <TableCell
          padding="checkbox"
          size="small"
          align="center"
          className={classes.celda}
        >
          <TextField
            value={itemRow.codigo}
            inputProps={{ className: classes.input }}
            onChange={(e) =>
              setItemRow({
                ...itemRow,
                codigo: e.target.value,
              })
            }
            size="small"
            fullWidth
          />
        </TableCell>
        <TableCell
          padding="checkbox"
          size="small"
          align="center"
          className={classes.celda}
        >
          <TextField
            inputProps={{ className: classes.input }}
            value={`${itemRow.incluyeIva}`}
            onChange={(e) =>
              setItemRow({
                ...itemRow,
                incluyeIva: e.target.value,
              })
            }
            size="small"
            fullWidth
            select
            InputLabelProps={{ shrink: true }}
            SelectProps={{ displayEmpty: true }}
          >
            <MenuItem value="">Seleccione</MenuItem>
            {["SI", "NO"].map((item) => {
              return <MenuItem value={item}>{item} </MenuItem>;
            })}
          </TextField>
        </TableCell>
        <TableCell
          padding="checkbox"
          size="small"
          align="center"
          className={classes.celda}
        >
          <TextField
            inputProps={{ className: classes.input }}
            value={`${itemRow.llevaIva}`}
            onChange={(e) =>
              setItemRow({
                ...itemRow,
                llevaIva: e.target.value,
              })
            }
            size="small"
            fullWidth
            select
            InputLabelProps={{ shrink: true }}
            SelectProps={{ displayEmpty: true }}
          >
            <MenuItem value="">Seleccione</MenuItem>
            {["SI", "NO"].map((item) => {
              return <MenuItem value={item}>{item} </MenuItem>;
            })}
          </TextField>
        </TableCell>
        <TableCell
          padding="checkbox"
          size="small"
          align="center"
          className={classes.celda}
        >
          <TextField
            inputRef={inputEl}
            type="number"
            inputProps={{ className: classes.input }}
            value={itemRow.cantidad}
            autoFocus={focusCantidad}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                if (botonDisabled()) {
                  return;
                }
                handleAgregar();
                inputRef.current.focus();
              }
            }}
            onChange={(e) => {
              setItemRow({
                ...itemRow,
                cantidad: !itemRow.limiteStock
                  ? e.target.value
                  : e.target.value > itemRow.stock
                  ? String(itemRow.stock)
                  : e.target.value,
              });
            }}
            size="small"
            fullWidth
          />
        </TableCell>
        <TableCell
          padding="checkbox"
          size="small"
          align="center"
          className={classes.celda}
        >
          <FormControl fullWidth>
            <Autocomplete
              PopperComponent={PopperMy}
              options={producto.invprecios.map((item) => {
                return item;
              })}
              props
              getOptionLabel={(option) => {
                return option;
              }}
              id="controlled-demo"
              disableClearable={true}
              value={itemRow.valUnitarioInterno}
              onChange={async (event, newValue) => {
                if (newValue) {
                  setItemRow({
                    ...itemRow,
                    valorUnitario: newValue,
                    valUnitarioInterno: newValue,
                  });
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        style: {
                          fontSize: "1px",
                          maxHeight: 48 * 4.5 + 8,
                          width: 5000,
                        },
                      },
                    },
                  }}
                  style={{ fontSize: "2px" }}
                  required
                  type="number"
                  fullWidth
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      if (botonDisabled()) {
                        return;
                      }
                      handleAgregar();
                    }
                  }}
                  onChange={async (e) => {
                    if (e.target.value === "") {
                      return mostrarAlerta("Ingrese un documento", "error");
                    }
                    try {
                      setItemRow({
                        ...itemRow,
                        valorUnitario: e.target.value,
                        valUnitarioInterno: e.target.value,
                      });
                    } catch (error) {}
                  }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {/* <IconButton
                      size="small"
                      aria-label=""
                      onClick={async () => {
                        // seteditarProveedor(false);
                        // setopenProveedor(true);
                      }}
                    >
                      <AddCircleOutlineTwoTone color="primary" />
                    </IconButton> */}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </FormControl>
        </TableCell>
        <TableCell
          padding="checkbox"
          size="small"
          align="center"
          className={classes.celda}
        >
          <TextField
            type="number"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                if (botonDisabled()) {
                  return;
                }
                handleAgregar();
              }
            }}
            inputProps={{ className: classes.input }}
            value={itemRow.descPorcentaje}
            onChange={(e) =>
              setItemRow({
                ...itemRow,
                descPorcentaje: e.target.value,
              })
            }
            size="small"
            fullWidth
          />
        </TableCell>
        <TableCell
          padding="checkbox"
          size="small"
          align="center"
          className={classes.celda}
        >
          {itemRow.precioSubTotalProducto} /
          {`V. I.: ${itemRow.valUnitarioInterno}`}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default memo(RowAgregarProducto);
