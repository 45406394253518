import React, { useState } from "react";
import ModalLayout from "../../../components/Modal/ModalLayout";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
} from "@mui/material";
import TablaCabecera from "./TablaCabecera";
import RowRetencion from "../../../Compras/Compras/Componentes/RowRetencion";

export const ModalRetenciones = ({ row }) => {
  if (row.venOtros === "{}" || !row.venOtros) return null;
  return (
    <TableContainer>
      <Table stickyHeader aria-label="sticky table" id="tabla" size="small">
        {/* <ModalCarnet setopen={setopen} onChangeCarnetActivo={onChangeCarnetActivo}/> */}

        <TableHead>
          <TablaCabecera
            columns={[
              {
                id: "tipo",
                label: "Tipo",
                minWidth: 100,
                tipo: "string",

                propiedad: "tipo",
              },
              {
                id: "impuesto",
                label: "Impuesto",
                minWidth: 50,
                tipo: "string",

                propiedad: "impuesto",
              },
              {
                id: "codigo",
                label: "Codigo",
                minWidth: 150,
                tipo: "string",

                propiedad: "codigo",
              },
              {
                id: "ret",
                label: "Ret %",
                minWidth: 50,
                tipo: "string",

                propiedad: "ret",
              },
              {
                id: "imponible",
                label: "B. Imponible",
                minWidth: 50,
                tipo: "string",

                propiedad: "imponible",
              },

              {
                id: "valor",
                label: "Valor",
                minWidth: 50,
                tipo: "string",

                propiedad: "valor",
              },
            ]}
            habilitarOrdenar={false}
          />
        </TableHead>

        <TableBody>
          {JSON.parse(row.venOtros || "{}").retenciones.map((row, index) => {
            return (
              <RowRetencion
                deshabilitarbotones={true}
                item={row}
                setitem={() => {}}
                row={row}
                index={index}
                key={index}
              />
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
