import React, { memo, useContext } from "react";

import { useState } from "react";
import AuthContext from "../../../../../Context/Auth/AuthContext";
// MATERIAL
import {
  Autocomplete,
  FormControl,
  Popper,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import { Cancel, Check } from "@mui/icons-material";

import ButtonsAction from "../../../../components/Tablas/ButtonsAction";
import {
  useRowStyles,
  butonIconTransition,
} from "../../../../../styles/stylesRows";
import clienteAxios from "../../../../../config/axios";
import AlertaContext from "../../../../../Context/Alerta/AlertaContext";

const RowAgregarItem = (props) => {
  // eslint-disable-next-line
  const { item, setitem, setagregarItemLista, socket, pistolaNumero } = props;
  const { usuario } = useContext(AuthContext);
  const { mostrarAlerta } = useContext(AlertaContext);
  const itemHijoDefecto = {
    numero_pistola: "",
    codigo_producto: "",
    nombre_producto: "",
    numero_tanque: "",
  };
  const [itemNuevo, setItemNuevo] = useState(itemHijoDefecto);
  const [productosData, setproductosData] = useState([]);
  const botonDisabled = () => {
    if (
      itemNuevo.numero_pistola === "" ||
      itemNuevo.nombre_producto === "" ||
      itemNuevo.numero_tanque === ""
    ) {
      return true;
    } else {
      return false;
    }
  };

  const botones = [
    {
      tooltip: "Cancelar",
      texto: "",
      funcion: () => {
        setagregarItemLista(false);
        setItemNuevo(itemHijoDefecto);
      },
      disabled: false,
      Icon: Cancel,
      color: "secondary",
      id: 1,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
    },
    {
      tooltip: "Guardar",
      texto: "",
      funcion: () => {
        handleAgregar();
        setagregarItemLista(false);
        setItemNuevo(itemHijoDefecto);
      },
      disabled: botonDisabled(),
      Icon: Check,
      color: "success",
      id: 2,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
      submit: true,
    },
  ];

  const classes = useRowStyles();

  const handleChange = (e) => {
    setItemNuevo({
      ...itemNuevo,
      [e.target.name]: e.target.value,
    });
  };

  const handleAgregar = () => {
    // return setitem({
    //   ...item,
    //   ["estacion" + pistolaNumero]: itemNuevo,
    //   tabla: "surtidor",
    //   rucempresa: usuario.rucempresa,
    //   rol: usuario.rol,
    //   user: usuario.usuario,
    // });
    const itemF = [item.estacion1, item.estacion2, item.estacion3];

    const indexNull =
      itemF.findIndex((estacion) => !estacion?.nombre_producto) + 1;

    if (
      item.estacion1?.numero_pistola === itemNuevo.numero_pistola ||
      item.estacion2?.numero_pistola === itemNuevo.numero_pistola ||
      item.estacion3?.numero_pistola === itemNuevo.numero_pistola
    ) {
      return mostrarAlerta("El número de pistola no puede repetirse", "error");
    }
    socket.current.emit("client:actualizarData", {
      ...item,
      ["estacion" + indexNull]: itemNuevo,
      tabla: "surtidor",
      rucempresa: usuario.rucempresa,
      rol: usuario.rol,
      user: usuario.usuario,
    });

    setitem({
      ...item,
      ["estacion" + indexNull]: itemNuevo,
      tabla: "surtidor",
      rucempresa: usuario.rucempresa,
      rol: usuario.rol,
      user: usuario.usuario,
    });
    // seteditar(false);
  };
  const PopperMy = function(props) {
    return (
      <Popper {...props} style={useRowStyles.popper} placement="bottom-start" />
    );
  };
  return (
    <React.Fragment>
      <TableRow
        className={classes.root}
        component={"form"}
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <ButtonsAction botones={botones} />
        {/* las demas columnas */}
        <TableCell
          fontSize="small"
          sx={{ paddingLeft: "5px" }}
          padding="none"
          align="center"
          className={classes.celda}
        >
          <TextField
            type="number"
            value={itemNuevo.numero_pistola}
            name="numero_pistola"
            onChange={(e) => handleChange(e)}
            size="small"
            fullWidth
          />
        </TableCell>{" "}
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          <FormControl fullWidth>
            <Autocomplete
              PopperComponent={PopperMy}
              options={productosData.map((item) => {
                return (
                  item.invNombre +
                  "/+/" +
                  item.invSerie +
                  "/+/" +
                  item.invStock +
                  "/+/" +
                  item.invId
                );
              })}
              props
              getOptionLabel={(option) => {
                return option
                  .replace("/+/", " ** ")
                  .replace("/+/", " ** ")
                  .split("/+/")[0];
              }}
              id="controlled-demo"
              disableClearable={true}
              value={itemNuevo.item}
              onChange={async (event, newValue) => {
                if (newValue) {
                  const options = newValue.split("/+/");
                  const productoF = productosData.find(
                    (productoItem) => productoItem.invId === Number(options[3])
                  );

                  setItemNuevo({
                    ...itemNuevo,
                    codigo_producto: productoF.invSerie,
                    nombre_producto: productoF.invNombre,
                  });
                } else {
                  setItemNuevo({
                    ...itemNuevo,
                  });
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        style: {
                          fontSize: "1px",
                          maxHeight: 48 * 4.5 + 8,
                          width: 5000,
                        },
                      },
                    },
                  }}
                  style={{ fontSize: "2px" }}
                  required
                  fullWidth
                  onChange={async (e) => {
                    try {
                      if (e.target.value.length < 1) {
                        return;
                      }
                      const res = await clienteAxios.get(
                        `/inventario/filtro/0?empresa=${usuario.rucempresa}&search=${e.target.value}`
                      );

                      // invDescripcion;

                      if (!res.data.data) {
                        return mostrarAlerta(res.data.msg, "error");
                      }

                      setproductosData(res.data.data);
                    } catch (error) {
                      console.log(error);
                    }
                  }}
                  InputProps={{
                    ...params.InputProps,
                  }}
                />
              )}
            />
          </FormControl>
        </TableCell>{" "}
        <TableCell
          fontSize="small"
          sx={{ paddingLeft: "5px" }}
          padding="none"
          align="center"
          className={classes.celda}
        >
          <TextField
            type="number"
            value={itemNuevo.numero_tanque}
            name="numero_tanque"
            onChange={(e) => handleChange(e)}
            size="small"
            fullWidth
          />
        </TableCell>{" "}
      </TableRow>
    </React.Fragment>
  );
};

export default memo(RowAgregarItem);
