import React, { memo, useContext } from "react";

import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  IconButton,
  MenuItem,
  Popper,
  Switch,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

import { useState } from "react";
import { withRouter } from "react-router";

import {
  AddCircleOutlineTwoTone,
  Cancel,
  Check,
  Create,
  Delete,
  DeleteForever,
} from "@mui/icons-material";

import { trimPropsItem } from "../../../../../config/const";
import {
  butonIconTransition,
  useRowStyles,
} from "../../../../../styles/stylesRows";
import ButtonsAction from "../../../../components/Tablas/ButtonsAction";
import ConfirmacionDialog from "../../../../components/Dialogs/ConfirmacionDialog";
import AlertaContext from "../../../../../Context/Alerta/AlertaContext";
import clienteAxios from "../../../../../config/axios";
import AuthContext from "../../../../../Context/Auth/AuthContext";

const Row = (props) => {
  // CLASES
  const classes = useRowStyles();
  // PROPS
  const {
    setproductosData: setproductosDataPadre,
    itemPadre,
    itemHijo,
    index,
  } = props;
  const claseCelda = `${
    index % 2 === 0 ? classes.celda : classes.celdaSecundario
  } `;
  const [editar, seteditar] = useState(false);
  const [itemHijoEdit, setitemHijoEdit] = useState(itemHijo);
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  const [productosData, setproductosData] = useState([]);
  const { mostrarAlerta } = useContext(AlertaContext);
  const { usuario } = useContext(AuthContext);
  //onChange
  const handleChange = (e) => {
    setitemHijoEdit({ ...itemHijoEdit, [e.target.name]: e.target.value });
  };
  // DESHABILITAR BOTON
  const botonDisabled = () => {
    if (
      !itemHijoEdit.prodId_relacion ||
      itemHijoEdit.precio_inventario == "" ||
      itemHijoEdit.llevaIVA == ""
    ) {
      return true;
    }
  };
  // ELIMINAR EL REGISTRO
  const funcSubmit = async () => {
    try {
      console.log(trimPropsItem(itemHijoEdit), itemPadre);
      const res = await clienteAxios.put(
        "/inventario/actualizar_desde_compra",
        {
          compId: itemPadre.compId,
          producto_modificar: itemHijoEdit,
        }
      );
      console.log({ res });

      setproductosDataPadre(res.data);

      seteditar(false);
    } catch (error) {
      console.log({ error });

      mostrarAlerta("Hubo un error", "error");
    }
  };
  // const funcEliminar = () => {
  //   try {
  //     setitemPadre({
  //       ...itemPadre,
  //       mejMetodo: itemPadre.mejMetodo.filter(
  //         (item, indexItem) => indexItem !== index
  //       ),
  //     });
  //     setopenConfirmDialog(false);
  //   } catch (error) {}
  // };
  const botones = [
    {
      tooltip: "Eliminar",
      texto: "",
      funcion: () => {
        setopenConfirmDialog(true);
      },
      disabled: false,
      Icon: Delete,
      color: "error",
      id: 1,
      ocultar: true,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
      submit: true,
    },
    {
      tooltip: "Editar",
      texto: "",
      funcion: () => {
        seteditar(true);
        setitemHijoEdit(itemHijo);
      },
      disabled: itemHijo.prodId_relacion,
      Icon: Create,
      color: "secondary",
      id: 2,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: { ...butonIconTransition, padding: "0" },
    },
  ];
  const botonesEditar = [
    {
      tooltip: "Cancelar",
      texto: "",
      funcion: () => {
        seteditar(false);
        setitemHijoEdit(itemHijo);
        // setItemEdit(itemHijo);
      },
      disabled: false,
      Icon: Cancel,
      color: "error",
      id: 1,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: { ...butonIconTransition, padding: "0" },
    },
    {
      tooltip: "Guardar",
      texto: "",
      funcion: () => {
        if (itemHijoEdit.prodId_relacion) {
          setopenConfirmDialog(true);
        }
      },
      disabled: botonDisabled(),
      Icon: Check,
      color: "success",
      id: 2,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
      submit: true,
    },
  ];
  const PopperMy = function(props) {
    return (
      <Popper {...props} style={useRowStyles.popper} placement="bottom-start" />
    );
  };

  return (
    <TableRow
      className={classes.root}
      component={"form"}
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        categoria="warning"
        titulo={
          "¿Está seguro de que desea actualizar el producto con estos datos? Los cambios no se podrán deshacer."
        }
        botones={[
          {
            tooltip: "Se editará el registro",
            texto: "SI",
            funcion: () => {
              funcSubmit();
              setopenConfirmDialog(false);
            },
            disabled: false,
            Icon: Create,
            color: "success",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "NO",
            funcion: () => {
              setopenConfirmDialog(false);
            },
            disabled: false,
            Icon: Cancel,
            color: "error",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <ButtonsAction
        claseCelda={claseCelda}
        botones={editar ? botonesEditar : botones}
      />
      {editar ? (
        <>
          <TableCell
            padding="checkbox"
            size="small"
            align="center"
            className={claseCelda}
          >
            <FormControl fullWidth>
              <Autocomplete
                autoFocus
                PopperComponent={PopperMy}
                options={productosData.map((item) => {
                  return (
                    item.invNombre + "/+/" + item.invSerie
                    // "/+/" +
                    // item.invStock +
                    // "/+/" +
                    // item.invIncluyeIva +
                    // "/+/" +
                    // item.invTipo +
                    // "/+/" +
                  );
                })}
                props
                getOptionLabel={(option) => {
                  return option.replace("/+/", " ** ").split("/+/")[0];
                }}
                id="controlled-demo"
                disableClearable={true}
                value={itemHijoEdit.prodNombre_relacion}
                onChange={async (event, newValue) => {
                  if (newValue) {
                    const options = newValue.split("/+/");
                    const productoF = productosData.find(
                      (productoItem) => productoItem.invSerie === options[1]
                    );
                    console.log({ productoF });

                    setitemHijoEdit({
                      ...itemHijoEdit,
                      prodNombre_relacion: productoF.invNombre,
                      prodId_relacion: productoF.invId,
                      precio_inventario: productoF.invprecios[0] ?? "",
                      llevaIVA: productoF.invIva,
                    });
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    autoFocus
                    size="small"
                    SelectProps={{
                      MenuProps: {
                        PaperProps: {
                          style: {
                            fontSize: "1px",
                            maxHeight: 48 * 4.5 + 8,
                            width: 5000,
                          },
                        },
                      },
                    }}
                    style={{ fontSize: "2px" }}
                    required
                    fullWidth
                    onChange={async (e) => {
                      try {
                        if (e.target.value.length < 3) {
                          return;
                        }
                        const res = await clienteAxios.get(
                          `/inventario/filtro/0?empresa=${usuario.rucempresa}&search=${e.target.value}`
                        );
                        console.log({ res });

                        // invDescripcion;

                        if (!res.data.data) {
                          return mostrarAlerta(res.data.msg, "error");
                        }

                        setproductosData(res.data.data);
                        console.log({ res });
                      } catch (error) {}
                    }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {/* <Typography
                            color={
                              itemHijoEdit.descripcion.length === 300
                                ? "error"
                                : "secondary"
                            }
                          >
                            {itemHijoEdit.descripcion.length}/300
                          </Typography> */}
                          {/* <IconButton
                            size="small"
                            aria-label=""
                            onClick={async () => {
                              // seteditarProveedor(false);
                              setopenInventario(true);
                            }}
                          >
                            <AddCircleOutlineTwoTone color="primary" />
                          </IconButton> */}

                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            <TextField
              type="number"
              value={itemHijoEdit.precio_inventario}
              name="precio_inventario"
              onChange={(e) => handleChange(e)}
              size="small"
              fullWidth
            />
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {/* {itemHijoEdit.llevaIVA} */}
            <FormControlLabel
              control={
                <Switch
                  checked={itemHijoEdit.llevaIVA === "SI" ? true : false}
                  onChange={(e) => {
                    setitemHijoEdit({
                      ...itemHijoEdit,
                      llevaIVA: e.target.checked ? "SI" : "NO",
                    });
                  }}
                  name="antoine"
                />
              }
              label={itemHijoEdit.llevaIVA}
            />
            {/* <TextField
              value={itemHijoEdit.llevaIVA}
              name="llevaIVA"
              onChange={(e) => handleChange(e)}
              size="small"
              select
              InputLabelProps={{ shrink: true }}
              SelectProps={{ displayEmpty: true }}
              fullWidth
            >
              <MenuItem value="" disabled>
                Seleccione Tipo
              </MenuItem>
              {["SI", "NO"].map((itemcpDeposito, index) => {
                return (
                  <MenuItem key={index} value={itemcpDeposito}>
                    {itemcpDeposito}
                  </MenuItem>
                );
              })}
            </TextField> */}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {itemHijoEdit.cantidad}
          </TableCell>
          {/* <TableCell size="small" align="center" className={claseCelda}>
            {itemHijoEdit.codigo}
          </TableCell> */}
          <TableCell size="small" align="center" className={claseCelda}>
            {itemHijoEdit.descripcion}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {itemHijoEdit.precioSubTotalProducto}
          </TableCell>
        </>
      ) : (
        <>
          <TableCell size="small" align="center" className={claseCelda}>
            {itemHijo.prodNombre_relacion}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {itemHijo.precio_inventario}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {itemHijo.llevaIVA}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {itemHijo.cantidad}
          </TableCell>
          {/* <TableCell size="small" align="center" className={claseCelda}>
            {itemHijo.codigo}
          </TableCell> */}
          <TableCell size="small" align="center" className={claseCelda}>
            {itemHijo.descripcion}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {itemHijo.precioSubTotalProducto}
          </TableCell>
        </>
      )}
    </TableRow>
  );
};

export default withRouter(memo(Row));
