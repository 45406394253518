import React, { useState, useContext, useEffect, memo } from "react";
// MODULOS
import Draggable from "react-draggable";

// FUNCIONES
import { replaceCaracteres, trimPropsItem } from "../../../../config/const";
// MATERIAL
import {
  Modal,
  Box,
  Button,
  Divider,
  Typography,
  IconButton,
  Tooltip,
} from "@mui/material";
import { Check, Close, Save } from "@mui/icons-material";

import { objectDefault } from "../data";
import { useModalStyle } from "../../../../styles/styleModal";
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
import AuthContext from "../../../../Context/Auth/AuthContext";
import clienteAxios from "../../../../config/axios";
import ListPersonas from "../../../components/ListPersonas";
const ModalAnticipos = (props) => {
  //props
  const {
    editar,
    seteditar,
    setopen,
    open,
    ObjectoActivo,
    changeObjetoActivoState,
    socket,
  } = props;
  const [item, setitem] = useState(ObjectoActivo);
  const { usuarios } = item;
  const { mostrarAlerta } = useContext(AlertaContext);
  const { usuario } = useContext(AuthContext);
  const [openDialogClose, setopenDialogClose] = useState(false);
  // const [seccionActive, setseccionActive] = useState(seccionDefault);
  const [cargando, setcargando] = useState(false);
  const [error, seterror] = useState({});
  const clases = useModalStyle();

  useEffect(() => {
    // const getequipoData = async () => {
    //   try {
    //     const resequipoData = await clienteAxios("/maquinarias/filtro/0");
    //     setequipoData(resequipoData.data.data);
    //   } catch (error) {
    //     setequipoData([]);
    //   }
    // };
    // if (!equipoData) {
    //   getequipoData();
    // }

    setitem(ObjectoActivo);

    // eslint-disable-next-line
  }, [ObjectoActivo]);
  const setitemsPersonas = (personas) => {
    return personas.map((itemGuardia) => {
      // perDocNumero: "jorge";
      // perId: 4;
      // perNombre: "JORGE VALDIVIEZO";
      return {
        perFoto: "",
        perApellidos: "",
        perNombres: itemGuardia.perNombre,
        perDocNumero: itemGuardia.perDocNumero,
        perId: itemGuardia.perId,
      };
    });
  };
  const [usuariosData, setusuariosData] = useState(null);
  useEffect(() => {
    if (!usuariosData) {
      const getvendedoresData = async () => {
        try {
          const resusuariosData = await clienteAxios.get(
            `proveedores/listar/por/perfil?perfil=USUARIO`
          );
          console.log({ resusuariosData });

          setusuariosData(setitemsPersonas(resusuariosData.data));
        } catch (error) {
          console.log({ error });

          setusuariosData([]);
        }
      };
      getvendedoresData();
    }
  }, []);

  const defaultActive = (args) => {
    changeObjetoActivoState(objectDefault);
    setitem(objectDefault);
    // setseccionActive("DATOS PRINCIPALESDATOS");
    setopen(false);
    seteditar(false);
    seterror({});
    setcargando(false);
    // setseccionActive(seccionDefault);
    setopenDialogClose(false);
    // setimagenes([]);
    // settecnicos([]);
  };
  const handleChange = (e) => {
    const res = replaceCaracteres(e.target.value);
    setitem({
      ...item,
      [e.target.name]: res,
    });
  };

  const handleCrear = async () => {
    try {
      // if (validacion(item, setitem, seterror)) {
      //   return mostrarAlerta("Faltan campos obligatorios", "error");
      // }
      setcargando(true);

      return defaultActive({ eliminarForm: true });
    } catch (error) {
      setcargando(false);
      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  const handleEditar = async () => {
    try {
      // if (validacion(item, setitem, seterror)) {
      //   return mostrarAlerta("Faltan campos obligatorios", "error");
      // }
      setcargando(true);

      socket.current.emit("client:actualizarData", {
        ...item,
        tabla: "surtidor",
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        user: usuario.usuario,
      });
      setcargando(false);
      return defaultActive({ eliminarForm: true });
    } catch (error) {
      setcargando(false);
      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  const handleGuardar = () => {
    if (editar) {
      handleEditar();
    } else {
      handleCrear();
    }
  };
  if (!usuariosData) {
    return null;
  }
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <Draggable handle="#handle">
          <Box display="flex" justifyContent="center">
            <div
              style={{
                borderRadius: "15px",
                position: "absolute",
                overflow: "scroll",
                maxHeight: "95vh",
              }}
              className={clases.paper}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Typography
                    style={{ cursor: "-webkit-grab" }}
                    id="handle"
                    variant="button"
                    color="initial"
                    align="center"
                  >
                    {`${editar ? "Editar" : "Agregar"} Surtidor.`}
                  </Typography>
                  <Tooltip title={"Guardar"} followCursor placement="top-start">
                    <IconButton onClick={() => {}}>
                      <Save color="secondary" />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Box display={"flex"} alignItems={"center"}>
                  <IconButton
                    onClick={() => {
                      defaultActive();
                    }}
                  >
                    <Close color={"error"} />
                  </IconButton>
                </Box>
              </Box>
              <Divider />
              <Box display="flex" justifyContent="space-between" width="100%">
                {/* <Box display={"flex"} flexWrap={"wrap"}>
                  <Button
                    variant={
                      seccionActive === "DATOS PRINCIPALES"
                        ? "contained"
                        : error.actaDocCliente
                        ? "outlined"
                        : "null"
                    }
                    color={error.actaDocCliente ? "error" : "primary"}
                    disableElevation
                    className={clases.buttonTab}
                    size="small"
                    onClick={() => setseccionActive("DATOS PRINCIPALES")}
                  >
                    DATOS PRINCIPALES
                  </Button>
                </Box> */}
              </Box>
              <Divider />
              <Box className={clases.container}>
                <ListPersonas
                  error={error}
                  item={item}
                  setitem={setitem}
                  data={usuariosData}
                  propiedad={usuarios}
                  propiedadString={"usuarios"}
                />
              </Box>
              <Divider />
              <Box display="flex" width="100%" justifyContent="space-between">
                <Box width="100%" display="flex" justifyContent="flex-end">
                  <Button
                    style={{ width: "30%" }}
                    variant="contained"
                    color="primary"
                    disabled={cargando}
                    onClick={() => {
                      handleGuardar();
                    }}
                  >
                    Guardar
                  </Button>
                </Box>
              </Box>
            </div>
          </Box>
        </Draggable>
      </Box>
    </Modal>
  );
};

export default memo(ModalAnticipos);
