import React, { memo, useContext, useEffect } from "react";

import { useState } from "react";

import AuthContext from "../../../../../Context/Auth/AuthContext";

// MATERIAL
import {
  Autocomplete,
  FormControl,
  Popper,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import {
  Cancel,
  Check,
  Create,
  Delete,
  DeleteForever,
} from "@mui/icons-material";

import ButtonsAction from "../../../../components/Tablas/ButtonsAction";
import ConfirmacionDialog from "../../../../components/Dialogs/ConfirmacionDialog";
import { usePermiso } from "../../../../../hooks/usePermiso";
import {
  useRowStyles,
  butonIconTransition,
} from "../../../../../styles/stylesRows";
import clienteAxios from "../../../../../config/axios";
import AlertaContext from "../../../../../Context/Alerta/AlertaContext";

const RowAgregar = (props) => {
  const classes = useRowStyles();
  const { mostrarAlerta } = useContext(AlertaContext);
  const { itemHijo, item, setitem, index, socket, pistolaNumero } = props;
  const { usuario } = useContext(AuthContext);
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  const [itemEdit, setItemEdit] = useState({ ...itemHijo });
  const [editar, seteditar] = useState(false);
  const { tienePermiso, alertaPermiso } = usePermiso("Surtidores");
  const [productosData, setproductosData] = useState([]);
  const botonDisabled = () => {
    if (itemEdit.numero_pistola === "" || itemEdit.nombre_producto === "") {
      return true;
    } else {
      return false;
    }
  };
  const botones = [
    {
      tooltip: "Eliminar",
      texto: "",
      funcion: () => {
        if (!tienePermiso("editar")) {
          return alertaPermiso("editar");
        }

        setopenConfirmDialog(true);
      },
      disabled: false,
      Icon: Delete,
      color: "error",
      id: 1,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
    },
    {
      tooltip: "Editar",
      texto: "",
      funcion: () => {
        if (!tienePermiso("editar")) {
          return alertaPermiso("editar");
        }

        seteditar(true);
      },
      disabled: false,
      Icon: Create,
      color: "secondary",
      id: 2,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
    },
  ];
  const botonesEditar = [
    {
      tooltip: "Cancelar",
      texto: "",
      funcion: () => {
        seteditar(false);
        setItemEdit(itemHijo);
      },
      disabled: false,
      Icon: Cancel,
      color: "error",
      id: 1,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
    },
    {
      tooltip: "Guardar Edición",
      texto: "",
      funcion: () => {
        funcSubmit();
      },
      disabled: botonDisabled(),
      Icon: Check,
      color: "success",
      id: 2,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
      submit: true,
    },
  ];
  const funcSubmit = () => {
    if (
      itemHijo.estacion === 1 &&
      (item.estacion2?.numero_pistola === itemEdit.numero_pistola ||
        item.estacion3?.numero_pistola === itemEdit.numero_pistola)
    ) {
      return mostrarAlerta("El número de pistola no puede repetirse", "error");
    }

    if (
      itemHijo.estacion === 2 &&
      (item.estacion1?.numero_pistola === itemEdit.numero_pistola ||
        item.estacion3?.numero_pistola === itemEdit.numero_pistola)
    ) {
      return mostrarAlerta("El número de pistola no puede repetirse", "error");
    }
    if (
      itemHijo.estacion === 3 &&
      (item.estacion1?.numero_pistola === itemEdit.numero_pistola ||
        item.estacion2?.numero_pistola === itemEdit.numero_pistola)
    ) {
      return mostrarAlerta("El número de pistola no puede repetirse", "error");
    }

    socket.current.emit("client:actualizarData", {
      ...item,
      // ["estacion" + 1]:
      //   item.estacion1 && Object.keys(item.estacion1).length > 1
      //     ? item.estacion1
      //     : null,
      // ["estacion" + 2]:
      //   item.estacion2 && Object.keys(item.estacion2).length > 1
      //     ? item.estacion2
      //     : null,
      // ["estacion" + 3]:
      //   item.estacion3 && Object.keys(item.estacion3).length > 1
      //     ? item.estacion3
      //     : null,
      ["estacion" + itemHijo.estacion]: itemEdit,
      tabla: "surtidor",
      rucempresa: usuario.rucempresa,
      rol: usuario.rol,
      user: usuario.usuario,
    });
    setitem({
      ...item,
      // ["estacion" + 1]:
      //   item.estacion1 && Object.keys(item.estacion1).length > 1
      //     ? item.estacion1
      //     : null,
      // ["estacion" + 2]:
      //   item.estacion2 && Object.keys(item.estacion2).length > 1
      //     ? item.estacion2
      //     : null,
      // ["estacion" + 3]:
      //   item.estacion3 && Object.keys(item.estacion3).length > 1
      //     ? item.estacion3
      //     : null,
      ["estacion" + itemHijo.estacion]: itemEdit,
      tabla: "surtidor",
      rucempresa: usuario.rucempresa,
      rol: usuario.rol,
      user: usuario.usuario,
    });
    seteditar(false);
  };
  const handleChange = (e) => {
    setItemEdit({
      ...itemEdit,
      [e.target.name]: e.target.value,
    });
  };

  const handleEliminar = async (_id) => {
    socket.current.emit("client:actualizarData", {
      ...item,
      tabla: "surtidor",
      ["estacion" + itemHijo.estacion]: null,
      rucempresa: usuario.rucempresa,
      rol: usuario.rol,
      user: usuario.usuario,
      ciuEmpresa: usuario.rucempresa,
    });

    setitem({
      ...item,
      ["estacion" + itemHijo.estacion]: null,
      rucempresa: usuario.rucempresa,
      rol: usuario.rol,
      user: usuario.usuario,
      ciuEmpresa: usuario.rucempresa,
    });
    setopenConfirmDialog(false);
  };
  useEffect(() => {
    setItemEdit({ ...itemHijo });
    // eslint-disable-next-line
  }, [item]);
  const PopperMy = function(props) {
    return (
      <Popper {...props} style={useRowStyles.popper} placement="bottom-start" />
    );
  };
  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        titulo="¿Esta seguro que quiere eliminar este Item?"
        _id={index}
        funcion={handleEliminar}
        botones={[
          {
            tooltip: "Se eliminaran el sector",
            texto: "SI",
            funcion: () => {
              handleEliminar();
            },
            disabled: false,
            Icon: DeleteForever,
            color: "error",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "NO",
            funcion: () => {
              setopenConfirmDialog(false);
            },
            disabled: false,
            Icon: Cancel,
            color: "secondary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <TableRow
        className={classes.root}
        component={"form"}
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <ButtonsAction botones={editar ? botonesEditar : botones} />

        {/* las demas columnas */}
        {editar ? (
          <>
            <TableCell
              fontSize="small"
              sx={{ paddingLeft: "5px" }}
              padding="none"
              align="center"
              className={classes.celda}
            >
              <TextField
                type="number"
                value={itemEdit.numero_pistola}
                name="numero_pistola"
                onChange={(e) => handleChange(e)}
                size="small"
                fullWidth
              />
            </TableCell>{" "}
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              <FormControl fullWidth>
                <Autocomplete
                  PopperComponent={PopperMy}
                  options={productosData.map((item) => {
                    return (
                      item.invNombre +
                      "/+/" +
                      item.invSerie +
                      "/+/" +
                      item.invStock +
                      "/+/" +
                      item.invId
                    );
                  })}
                  props
                  getOptionLabel={(option) => {
                    return option
                      .replace("/+/", " ** ")
                      .replace("/+/", " ** ")
                      .split("/+/")[0];
                  }}
                  id="controlled-demo"
                  disableClearable={true}
                  value={(
                    itemEdit.codigo_producto +
                    " " +
                    itemEdit.nombre_producto
                  ).trim()}
                  onChange={async (event, newValue) => {
                    if (newValue) {
                      const options = newValue.split("/+/");
                      const productoF = productosData.find(
                        (productoItem) =>
                          productoItem.invId === Number(options[3])
                      );
                      setItemEdit({
                        ...itemEdit,
                        codigo_producto: productoF.invSerie,
                        nombre_producto: productoF.invNombre,
                      });
                    } else {
                      setItemEdit({
                        ...itemEdit,
                      });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      SelectProps={{
                        MenuProps: {
                          PaperProps: {
                            style: {
                              fontSize: "1px",
                              maxHeight: 48 * 4.5 + 8,
                              width: 5000,
                            },
                          },
                        },
                      }}
                      style={{ fontSize: "2px" }}
                      required
                      fullWidth
                      onChange={async (e) => {
                        try {
                          if (e.target.value.length < 1) {
                            return;
                          }
                          const res = await clienteAxios.get(
                            `/inventario/filtro/0?empresa=${usuario.rucempresa}&search=${e.target.value}`
                          );

                          // invDescripcion;

                          if (!res.data.data) {
                            return mostrarAlerta(res.data.msg, "error");
                          }

                          setproductosData(res.data.data);
                        } catch (error) {}
                      }}
                      InputProps={{
                        ...params.InputProps,
                      }}
                    />
                  )}
                />
              </FormControl>
            </TableCell>{" "}
            <TableCell
              fontSize="small"
              sx={{ paddingLeft: "5px" }}
              padding="none"
              align="center"
              className={classes.celda}
            >
              <TextField
                type="number"
                value={itemEdit.numero_tanque}
                name="numero_tanque"
                onChange={(e) => handleChange(e)}
                size="small"
                fullWidth
              />
            </TableCell>{" "}
          </>
        ) : (
          <>
            <TableCell
              fontSize="small"
              padding="none"
              align="left"
              className={classes.celda}
            >
              {itemHijo.numero_pistola}
            </TableCell>
            <TableCell
              fontSize="small"
              padding="none"
              align="left"
              className={classes.celda}
            >
              {itemHijo.codigo_producto}
              {itemHijo.nombre_producto}
            </TableCell>
            <TableCell
              fontSize="small"
              padding="none"
              align="left"
              className={classes.celda}
            >
              {itemHijo.numero_tanque}
            </TableCell>
          </>
        )}
      </TableRow>
    </React.Fragment>
  );
};

export default memo(RowAgregar);
