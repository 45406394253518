import React, { memo, useState, useContext, useEffect, useRef } from "react";

import { Box, Button, Divider, Menu } from "@mui/material";

import Tabla from "./Componentes/Tabla";
import Modal from "./Componentes/Modal";

import AlertaContext from "../../../Context/Alerta/AlertaContext";
import AuthContext from "../../../Context/Auth/AuthContext";

import ModalTraido from "./ModalTraido";
import Categorias from "../Categorias/Categorias";
import Subcategorias from "../Subcategorias/Subcategorias";
import ModalProveedores from "../../Compras/Proveedores/Personas_Contable/Componentes/Modal";
import clienteAxios from "../../../config/axios";
import io from "socket.io-client";
import { socketUrl } from "../../../config/const";
import ModalB from "../../components/Modal/ModalB";
import BarraTablas from "../../components/Tablas/BarraTablas";
import DialogOpciones from "../../components/Dialogs/DialogOpciones";
import { Add, MenuBook, UploadFile } from "@mui/icons-material";
const Inventario = (props) => {
  // const { llamadoDeOtroLugar, setopenMT } = props;
  const {
    idmascota,
    settab,
    setarrayExportHijos,
    desdeOtroLugarMascota,
    // puedeExportar,
    // itemRelacionadoPadre,
  } = props;
  // DATA DEL STATE

  useEffect(() => {
    if (desdeOtroLugarMascota) {
      settab("");
      setarrayExportHijos(arrayExport.map((item) => item[campoExport]));
    }
    // eslint-disable-next-line
  }, [arrayExport]);
  const [rows, setrows] = useState(null);
  const columns = [
    {
      id: "invTipo",
      label: "Tipo",
      minWidth: 50,
      tipo: "string",

      propiedad: "invTipo",
      variante: "autocompleteComun",
      data: ["BIEN", "SERVICIO", "BIEN-CARNET"],
    },

    {
      id: "invSerie",
      label: "Serie",
      minWidth: 50,
      tipo: "string",

      propiedad: "invSerie",
    },
    {
      id: "invNombre",
      label: "Nombre",
      minWidth: 100,
      tipo: "string",

      propiedad: "invNombre",
    },
    {
      id: "invCosto1",
      label: "Costo 1",
      minWidth: 50,
      tipo: "",
      ocultar: true,
      propiedad: "invCosto1",
    },
    {
      id: "invStock",
      label: "Stock",
      minWidth: 50,
      tipo: "",
      ocultar: true,
      propiedad: "invStock",
    },
    {
      id: "invIncluyeIva",
      label: "Incluye Iva",
      minWidth: 50,
      tipo: "string",
      variante: "autocompleteComun",
      data: ["SI", "NO"],
      propiedad: "invIncluyeIva",
    },
    {
      id: "invIva",
      label: "Iva",
      minWidth: 50,
      tipo: "string",
      variante: "autocompleteComun",
      data: ["SI", "NO"],
      propiedad: "invIva",
    },
    {
      id: "invprecios",
      label: "Precio 1",
      minWidth: 50,
      tipo: "",
      ocultar: true,
      propiedad: "invprecios",
    },
    {
      id: "invProveedor",
      label: "Proveedor",
      minWidth: 50,
      tipo: "",
      ocultar: true,
      propiedad: "invProveedor",
    },
    {
      id: "invCategoria",
      label: "Categoria",
      minWidth: 50,
      tipo: "string",
      // ocultar: true,
      propiedad: "invCategoria",
      variante: "autocompleteDataDefault",
      propiedadesMostrar: 1,
      campoData1: "catNombre",
      getData: async () => {
        const res = await clienteAxios.get(
          `categorias/filtro/0?empresa=${usuario.rucempresa}`
        );

        return res;
      },
    },
    {
      id: "invUser",
      label: "User",
      minWidth: 50,
      propiedad: "invUser",
      tipo: "string",
    },
    {
      id: "user_upd",
      label: "User_upd",
      minWidth: 50,
      propiedad: "user_upd",
      ocultar: true,
    },
    {
      id: "invFecUpd",
      label: "Fec Upd",
      minWidth: 50,
      tipo: "fecha",
      ocultar: true,
      propiedad: "invFecUpd",
    },
  ];
  const defaultFullBuscador = () => {
    const json = {};
    columns.forEach((itemPropiedad) => {
      Object.entries({ itemPropiedad }).forEach((item) => {
        if (itemPropiedad.tipo === "string") {
          json[`${item[1].propiedad}`] = "";
        } else if (itemPropiedad.tipo === "numero") {
          json[`${item[1].propiedad}1`] = "";
          json[`${item[1].propiedad}2`] = "";
        } else if (itemPropiedad.tipo === "fecha") {
          json[`${item[1].propiedad}1`] = "";
          json[`${item[1].propiedad}2`] = "";
        }
      });
    });
    return json;
  };
  const [ObjectoActivo, setObjetoActivo] = useState({
    invSerie: "0",
    invTipo: "",
    invNombre: "",
    invDescripcion: "",
    invCosto1: "",
    invCosto2: "",
    invCosto3: "",
    invStock: "1",
    invMinimo: "1",
    invIva: "SI",
    invIncluyeIva: "SI",
    invEstado: "ACTIVA",
    invBodegas: [],
    invprecios: [],
    invFotos: [],
    invProveedor: "",
    invCategoria: "",
    invSubCategoria: "",
    invUnidad: "",
    invMarca: "",
    invSubsidio: "0",
    invPlanCuenta: "",
    invEmpresa: "",
    invUser: "admin",
    invFecReg: "",
    Todos: "",
  });
  const [pagination, setpagination] = useState(null);

  // FUNCIONES STATE
  const getDataState = async (
    cantidad,
    page,
    search,
    input,
    orden,
    datos,
    rucempresa,
    estado
  ) => {
    console.log(
      `/inventario/?cantidad=${cantidad}&page=${page}&search=${
        search ? search : ""
      }&input=${input ? input : ""}&orden=${orden ? orden : ""}&datos=${
        datos ? datos : ""
      }&rucempresa=${rucempresa ? rucempresa : ""}&estado=${
        estado ? estado : ""
      }`
    );
    console.log(JSON.parse(datos));
    const res = await clienteAxios.get(
      `/inventario/?cantidad=${cantidad}&page=${page}&search=${
        search ? search : ""
      }&input=${input ? input : ""}&orden=${orden ? orden : ""}&datos=${
        datos ? datos : ""
      }&rucempresa=${rucempresa ? rucempresa : ""}&estado=${
        estado ? estado : ""
      }`
    );

    return res;
  };

  const obtenerState = async (
    cantidad = 10,
    page = 0,
    search,
    input,
    orden,
    datos,
    rucempresa,
    estado
  ) => {
    const res = await getDataState(
      cantidad,
      page,
      search,
      input,
      orden,
      datos,
      rucempresa,
      estado
    );
    setrows(res.data.data.results);
    setpagination(res.data.data.pagination);
  };
  const addRowsState = async (items) => {
    setrows([...rows, ...items]);
  };
  const remplazarRowsState = async (
    cantidad,
    page,
    search,
    input,
    orden,
    datos,
    rucempresa,
    estado
  ) => {
    const res = await getDataState(
      cantidad,
      page,
      search,
      input,
      orden,
      datos,
      rucempresa,
      estado
    );
    setrows(res.data.data.results);
    setpagination(res.data.data.pagination);
    // dispatch({
    //   type: OBTENER,
    //   payload: {
    //     results: res.data.data.results,
    //     pagination: res.data.data.pagination,
    //   },
    // });
  };
  const agregarState = async (item) => {
    try {
      setrows([item, ...rows]);

      setpagination({
        ...pagination,
        numRows: pagination.numRows + 1,
      });
    } catch (error) {}
  };

  const editarState = async (item) => {
    try {
      const resRows = rows.map((itemR) => {
        if (itemR.invId === item.invId) {
          return item;
        } else {
          return itemR;
        }
      });

      setrows(resRows);
    } catch (error) {}
  };

  const eliminarState = async (_id) => {
    try {
      const resRows = rows.filter((row) => {
        return row.invId !== _id;
      });
      setrows(resRows);
      setpagination({
        ...pagination,
        numRows: pagination.numRows - 1,
      });
    } catch (error) {}
  };
  const eliminarSeleccionState = async (_ids) => {
    const resRows = rows.filter((row) => {
      return !_ids.some((item) => {
        return item === row.invId;
      });
    });
    setpagination({
      ...pagination,
      numRows: pagination.numRows - _ids.length,
    });
    setrows(resRows);
  };

  const changeObjetoActivoState = (item) => {
    setObjetoActivo(item);
    // dispatch({
    //   type: CHANGE_ACTIVO,
    //   payload: item,
    // });
  };

  const rowsImportState = (rows) => {
    // dispatch({
    //   type: IMPORTAR_ROWS,
    //   payload: rows,
    // });
    setrows(rows);
  };
  // FIN FUNCIONES STATE
  // FIN DATA STATE

  // FUNCIONES SOCKET
  const [tabla] = useState("inventario");
  const [itemSocket, setitemSocket] = useState({
    tipo: "",
    item: {},
  });
  const socket = useRef();
  useEffect(() => {
    // Creates a WebSocket connection
    socket.current = io(socketUrl);
    socket.current.on("server:guardadoExitoso", (data) => {
      if (data.tabla !== tabla) {
        return;
      }

      setitemSocket({
        tipo: "agregar",
        item: data,
      });
    });
    socket.current.on("server:actualizadoExitoso", (data) => {
      if (data.tabla !== tabla) {
        return;
      }

      setitemSocket({
        tipo: "editar",
        item: data,
      });
    });
    socket.current.on("server:eliminadoExitoso", (data) => {
      if (data.tabla !== tabla) {
        return;
      }
      setitemSocket({
        tipo: "eliminar",
        item: data,
      });
    });
    socket.current.on("server:eliminadoExitosoSeleccionado", (data) => {
      if (data.tabla !== tabla) {
        return;
      }
      setitemSocket({
        tipo: "eliminarSeleccion",
        item: data,
      });
    });

    socket.current.on("server:error", (data) => {
      mostrarAlerta(
        data.msg ? data.msg : "Hubo un error",
        data.alert ? data.alert : "error"
      );
    });
    return () => {
      socket.current.disconnect();
    };
    // Destroys the socket reference
    // when the connection is closed
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    const { item, tipo } = itemSocket;

    if (tipo === "") {
      return;
    }
    if (!rows) {
      return;
    }
    if (usuario.rucempresa !== item.rucempresa) {
      return;
    }

    if (desdeOtroLugarMascota && Number(item.hcliMascId) !== idmascota) {
      return;
    }
    const funcionAalerta = () => {
      if (item.msg === "") {
        return null;
      }
      if (!item.msg) {
        return null;
      }
      mostrarAlerta(item.msg, item.alert ? item.alert : "success");
    };

    if (tipo === "agregar") {
      agregarState(item);
    }
    if (tipo === "editar") {
      editarState(item);
    }
    if (tipo === "eliminar") {
      eliminarState(item.invId);
    }
    if (tipo === "eliminarSeleccion") {
      eliminarSeleccionState(item.lista);
    }
    funcionAalerta();
    setitemSocket({ tipo: "", item: {} });
    // eslint-disable-next-line
  }, [itemSocket]);
  const { usuario } = useContext(AuthContext);
  const { mostrarAlerta } = useContext(AlertaContext);
  const [arrayExport, setarrayExport] = useState([]);
  const [campoExport] = useState("invId");
  const [estadoRequest, setestadoRequest] = useState(true);
  const [OpenProveedor, setOpenProveedor] = useState(false);
  const [editarProveedor, seteditarProveedor] = useState(false);
  const [open, setopen] = useState(false);
  const [buscando, setbuscando] = useState(false);
  const [editar, seteditar] = useState(false);
  const [search, setsearch] = useState("");
  const [cargando, setcargando] = useState(false);
  const [input, setinput] = useState({
    id: "invId",
    label: "ID",
    minWidth: 150,
    tipo: "string",

    propiedad: "invId",
  });

  const [openSubCategoria, setopenSubCategoria] = useState(false);
  const [openCategoria, setopenCategoria] = useState(false);
  const [orden, setorden] = useState(false);
  const [openMB, setopenMB] = useState(false);
  const [fullBuscador, setfullBuscador] = useState({});
  const {
    usuario: { rucempresa },
  } = useContext(AuthContext);
  //pagina actual
  const [page, setPage] = React.useState(0);
  //filas por pagina
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  // input
  //
  const handleChange = (e) => {
    setfullBuscador({ ...fullBuscador, [e.target.name]: e.target.value });
  };
  const funcionB = async () => {
    setcargando(true);
    await remplazarRowsState(
      rowsPerPage,
      0,
      search,
      input.id,
      orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      estadoRequest
    );
    setPage(0);
    setbuscando(true);
    setopenMB(false);
    setcargando(false);
  };

  const handleSearch = async () => {
    setcargando(true);
    await remplazarRowsState(
      rowsPerPage,
      0,
      search,
      input.id,
      !orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      estadoRequest
    );

    setPage(0);
    setcargando(false);
  };
  const funcionDefault = async () => {
    setcargando(true);
    setfullBuscador(defaultFullBuscador());
    await remplazarRowsState(
      rowsPerPage,
      0,
      "",
      input.id,
      orden,
      JSON.stringify(defaultFullBuscador()),
      rucempresa,
      estadoRequest
    );

    setPage(0);
    setsearch("");
    setbuscando(false);
    setcargando(false);
    setopenMB(false);
  };
  const inputEl = useRef();
  const funcArchivo = async (e) => {
    if (!e.target.files[0]) {
      return;
    } else {
      if (e.target.files[0].name.split(".")[1] !== "xlsx") {
        return mostrarAlerta("El archivo no es xlsx", "error");
      } else {
        try {
          setcargando(true);
          const formData = new FormData();
          formData.append("archivo", e.target.files[0]);
          const res = await clienteAxios.post(
            "/inventario/importar_excel",
            formData
          );
          setrows(res.data.data);
          setpagination({
            current: 0,
            next: 1,
            numRows: res.data.data.length,
            perPage: 10,
          });
          setcargando(false);
          return mostrarAlerta("Importación exitosa", "success");
        } catch (error) {
          console.log({ error });

          setcargando(false);
          return mostrarAlerta(
            error.response?.data?.msg ||
              "Error Importar Revisar que todas columna este llenas",
            "error"
          );
        }
      }
    }
  };
  const [ObjectoActivoProveedor, setObjetoActivoProveedor] = useState({
    perISPContratos: [],
    perNombreComercial: "",
    perEmpresa: [],
    perPais: "",
    perProvincia: "",
    perCanton: "",
    perTipoProveedor: "BIENES",
    perTiempoCredito: "0",
    perDocTipo: "",
    perDocNumero: "",
    perPerfil: [],
    perNombre: "",
    perDireccion: "",
    perObligado: "NO",
    perCredito: "NO",
    perTelefono: "0",
    perCelular: ["+593"],
    perEstado: "ACTIVO",
    perObsevacion: "",
    perEmail: ["sin@sincorreo.com"],
    perOtros: ["ZZZ9999"],
    perNickname: "",
    perUser: "",
    perFecReg: "",
    perFoto: "",
    perUbicacion: {
      longitud: "",
      latitud: "",
    },
    perDocumento: "",
    perGenero: "M",
    perRecomendacion: "OTROS",
    perFecNacimiento: "",
    perEspecialidad: "",
    perTitulo: "",
    perSenescyt: "",
    perPersonal: "INTERNO",
    validateEmail: 1,
  });
  const changeObjetoActivoStateProveedor = (item) => {
    setObjetoActivoProveedor(item);
    // dispatch({
    //   type: CHANGE_ACTIVO,
    //   payload: item,
    // });
  };
  const [openDialogOpciones, setopenDialogOpciones] = useState(false);
  return (
    <Box>
      <ModalTraido
        open={openCategoria}
        setopen={setopenCategoria}
        titulo="Categoría"
        Componente={Categorias}
        socket={socket}
      />
      <ModalTraido
        open={openSubCategoria}
        setopen={setopenSubCategoria}
        titulo="Sub Categoría"
        Componente={Subcategorias}
        socket={socket}
      />
      <ModalProveedores
        estadoRequest={"PROVEEDOR"}
        open={OpenProveedor}
        setopen={setOpenProveedor}
        editar={editarProveedor}
        seteditar={seteditarProveedor}
        socket={socket}
        ObjectoActivo={ObjectoActivoProveedor}
        changeObjetoActivoState={changeObjetoActivoStateProveedor}
      />
      <input
        style={{ display: "none" }}
        type="file"
        name="myImage"
        onChange={async (e) => {
          funcArchivo(e);
        }}
        ref={inputEl}
      />
      <DialogOpciones
        open={openDialogOpciones}
        setopen={setopenDialogOpciones}
        botones={[
          {
            tooltip: "Archivo Importación",
            funcion: () => {
              window.open("https://contabackend.neitor.com/productos.xlsx");
              setopenDialogOpciones(false);
            },
            Icon: MenuBook,
            color: "success",
            id: 1,
            disabled: false,
          },
          {
            tooltip: "Importar Registros",
            funcion: () => {
              inputEl.current.click();
              setopenDialogOpciones(false);
            },
            Icon: UploadFile,
            color: "primary",
            id: 1,
            disabled: false,
          },
        ]}
        titulo={"Mas Opciones"}
      />
      <BarraTablas
        search={search}
        setsearch={setsearch}
        funcion={handleSearch}
        buscando={buscando}
        setbuscando={setbuscando}
        LabelBuscador="Buscar por todos los campos"
        funcionDefault={funcionDefault}
        ocultarMigasDePan={false}
        botones={[
          {
            tooltip: "Agregar",
            texto: "Agregar",
            funcion: () => {
              setopen(true);
            },
            disabled: false,
            Icon: Add,
            color: "primary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
          {
            tooltip: "OPCIONES",
            texto: "OPCIONES",
            funcion: () => {
              setopenDialogOpciones(true);
              // window.open("https://backsafe.neitor.com/datos_personas.xlsx");
            },
            disabled: false,
            Icon: Menu,
            color: "success",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {
              ml: 1,
            },
          },
        ]}
      />
      {/* <ModalB
        defaultFullBuscador={defaultFullBuscador}
        openMB={openMB}
        setopenMB={setopenMB}
        titulo={"Busqueda avanzada (Inventario)"}
        fullBuscador={fullBuscador}
        setfullBuscador={setfullBuscador}
        arrayProps={columns}
        handleChange={handleChange}
        funcionB={funcionB}
        buscando={buscando}
        funcionDefault={funcionDefault}
        estadoRequest={estadoRequest}
      /> */}

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems=" stretch"
        width="100%"
      >
        <Box>
          <Button
            size="small"
            variant={estadoRequest ? "contained" : null}
            onClick={async () => {
              await remplazarRowsState(
                rowsPerPage,
                0,
                "",
                input.id,
                !orden,
                JSON.stringify(fullBuscador),
                rucempresa,
                true
              );

              setPage(0);
              setestadoRequest(true);
            }}
            disableElevation
            style={{ padding: ".5rem", borderRadius: "15px 15px  0px 0px" }}
            color="primary"
          >
            ACTIVAS
          </Button>
          <Button
            size="small"
            variant={!estadoRequest ? "contained" : null}
            onClick={async () => {
              await remplazarRowsState(
                rowsPerPage,
                0,
                "",
                input.id,
                !orden,
                JSON.stringify(fullBuscador),
                rucempresa,
                false
              );

              setPage(0);
              setestadoRequest(false);
            }}
            disableElevation
            style={{ padding: ".5rem", borderRadius: "15px 15px  0px 0px" }}
            color="primary"
          >
            INACTIVAS
          </Button>
        </Box>
      </Box>
      <Divider />
      <Tabla
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        search={search}
        input={input}
        orden={orden}
        setinput={setinput}
        setorden={setorden}
        seteditar={seteditar}
        setopen={setopen}
        rows={rows}
        buscando={buscando}
        cargando={cargando}
        setcargando={setcargando}
        fullBuscador={fullBuscador}
        estadoRequest={estadoRequest}
        // STATE
        addRowsState={addRowsState}
        getDataState={getDataState}
        remplazarRowsState={remplazarRowsState}
        obtenerState={obtenerState}
        pagination={pagination}
        columns={columns}
        ObjectoActivo={ObjectoActivo}
        changeObjetoActivoState={changeObjetoActivoState}
        rowsImportState={rowsImportState}
        // export
        arrayExport={arrayExport}
        setarrayExport={setarrayExport}
        campoExport={campoExport}
        socket={socket}
      />
      {socket.current ? (
        <Modal
          editar={editar}
          seteditar={seteditar}
          setopen={setopen}
          open={open}
          setopenSubCategoria={setopenSubCategoria}
          setopenCategoria={setopenCategoria}
          setOpenProveedor={setOpenProveedor}
          ObjectoActivo={ObjectoActivo}
          changeObjetoActivoState={changeObjetoActivoState}
          socket={socket}
          seteditarProveedor={seteditarProveedor}
          changeObjetoActivoStateProveedor={changeObjetoActivoStateProveedor}
        />
      ) : null}
    </Box>
  );
};

export default memo(Inventario);
