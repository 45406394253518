import React, { useState, useContext, useEffect, memo } from "react";
// MODULOS
import Draggable from "react-draggable";

// FUNCIONES
import { replaceCaracteres, trimPropsItem } from "../../../config/const";

// AXIOS
import clienteAxios from "../../../config/axios";
// CONTEXT
import AlertaContext from "../../../Context/Alerta/AlertaContext";
import AuthContext from "../../../Context/Auth/AuthContext";
import Dropzone from "./Dropzone";
import ModalPersona from "../../Compras/Proveedores/Personas_ISP/Componentes/Modal";
// MATERIAL
import {
  Modal,
  Box,
  Button,
  Divider,
  Typography,
  IconButton,
  TextField,
  FormControl,
  Autocomplete,
  Tooltip,
  MenuItem,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  useTheme,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputAdornment,
} from "@mui/material";
import {
  AddCircle,
  AddCircleOutlineTwoTone,
  Check,
  Close,
  Map,
  Save,
} from "@mui/icons-material";
import { useModalStyle } from "../../../styles/styleModal";
import {
  columnsEquiposInstalados,
  objectDefault,
  objectDefaultEquiposInstalado,
  validacion,
  objectDefaultCargo,
  columnsCargo,
} from "../data";
import { useSimpleTable } from "../../../hooks/useSimpleTable";
import ConfirmacionDialog from "../../components/Dialogs/ConfirmacionDialog";
import ToolBarTable from "../../components/Tablas/ToolBarTable";
import TablaCabecera from "../../components//Tablas/TablaCabecera";
import RowEquiposInstalado from "./EquiposInstalados/RowEquiposInstalado";
import { v4 } from "uuid";
import { useDraggable } from "../../../hooks/useDraggable";
import RowCargo from "./Cargo/RowCargo";
import useMultimediasUpload from "../../../hooks/useMultimediasUpload";
import { Firma } from "./Firma";
import CamposAcumulables from "../../components/Campos/CamposAcumulables";
import DialogMap from "../../../components/Extra/DialogMap";
import CamposAcumulablesTecnicos from "./CamposAcumulablesTecnicos";
import CamposAcumulablesSector from "./CamposAcumulablesSector";
const seccionDefault = "DATOS PRINCIPALES";
const ModalAnticipos = (props) => {
  //props
  const {
    editar,
    seteditar,
    setopen,
    open,
    ObjectoActivo,
    changeObjetoActivoState,
    socket,
  } = props;
  const [item, setitem] = useState(ObjectoActivo);
  const [tecnicos, settecnicos] = useState([]);
  const {
    // actaId,
    // actaIdCliente,
    actaDocCliente,
    actaNombreCliente,
    actaDireccionCliente,
    actaTelCliente,
    actaEmailCliente,
    // actaIdPersonaEntrega,
    actaEntFirma,
    // actaEntNombre,
    // actaEntDoc,
    // actaRecNombre,
    actaRecFirma,
    // actaRecDoc,
    // actaFecha,
    // actaInstalacionFibra,
    // actaVisitaTecnica,
    actaOpcion,
    actaTipo,
    actaSeEncuentraCliente,
    actaServiciosVisita,
    actaRedWifi,
    actaFechaInstalacion,
    actaClave,
    actaClienteRegulador,
    actaConfigRouter,
    actaCambioPatch,
    actaCalidadIns,
    // actaSolucionReq,
    actaIdVendedor,
    actaDocVendedor,
    actaNombreVendedor,
    actaFotos,
    actaEquiposInstalados,
    actaCargosAdicionales,
    actaEstado,
    actaDetalleEstado,
    actaValorRouter,
    actaValorPatch,
    // actaUser,
    // actaEmpresa,
    // actaFecReg,
    planNombreInternet,
    planValorInternet,
    planMegasInternet,
    actaIdPlanInternet,
    actaCiudad,
    actaSector,
    actaUbicacion,
  } = item;
  const {
    setmodificoImagenes,
    subirImagenes,
    imagenes,
    setimagenes,
  } = useMultimediasUpload("acta_entrega_recepcion");
  const { mostrarAlerta } = useContext(AlertaContext);
  const { usuario } = useContext(AuthContext);
  const [openDialogClose, setopenDialogClose] = useState(false);
  const [modificado, setmodificado] = useState(false);
  const [openMap, setopenMap] = useState(false);

  // HOOKS
  const {
    // agregandoEquiposInstalado,
    // setagregandoEquiposInstalado,
    pageEquiposInstalado,
    // setPageEquiposInstalado,
    rowsPerPageEquiposInstalado,
    // setRowsPerPageEquiposInstalado,
    handleChangePageEquiposInstalado,
    handleChangeRowsPerPageEquiposInstalado,
  } = useSimpleTable("EquiposInstalado");

  const { handleDragStart, handleDragEnter, handleDragEnd } = useDraggable();

  const {
    // agregandoCargo,
    // setagregandoCargo,
    pageCargo,
    // setPageCargo,
    rowsPerPageCargo,
    // setRowsPerPageCargo,
    handleChangePageCargo,
    handleChangeRowsPerPageCargo,
  } = useSimpleTable("Cargo");

  // SECCION

  const [seccionActive, setseccionActive] = useState(seccionDefault);
  const [clientesData, setclientesData] = useState([]);
  // DE SIEMPRE
  const [cargando, setcargando] = useState(false);
  const [error, seterror] = useState({});
  const clases = useModalStyle();

  useEffect(() => {
    if (open) {
      setmodificado(true);
    }
    // eslint-disable-next-line
  }, [item]);

  // ITEM DEL SOCKET SE UTILIZA COMO REFERENCIA
  const [itemSocket, setitemSocket] = useState({
    tipo: "",
    item: {},
  });
  // REFERENCIA DEL SOCKET
  // USEFFECT QUE ESCUCHUA LOS EVENTOS DEL SOCKET
  useEffect(() => {
    // Creates a WebSocket connection
    socket.current.on("server:guardadoExitoso", (data) => {
      if (data.tabla !== "proveedor") {
        return;
      }

      setitemSocket({
        tipo: "agregar",
        item: data,
      });
    });

    // Destroys the socket reference
    // when the connection is closed
    // eslint-disable-next-line
  }, []);
  // CUANDO CAMBIA EL OBJETO DEL SOCKET SE ESCUCHA AQUI Y SE DECIDE QUE HACER CON EL
  useEffect(() => {
    const { item, tipo } = itemSocket;

    if (tipo === "") {
      return;
    }

    if (usuario.rucempresa !== item.rucempresa) {
      return;
    }
    if (item.tabla !== "proveedor" && item.perUser !== usuario.usuario) {
      return;
    }

    const funcionAalerta = () => {
      if (item.msg === "") {
        return null;
      }
      if (!item.msg) {
        return null;
      }
      mostrarAlerta(item.msg, item.alert ? item.alert : "success");
    };

    if (tipo === "agregar") {
      setitem((prev) => ({
        ...prev,
        actaIdCliente: item.perId || "",
        actaDocCliente: item.perDocNumero || "",
        actaNombreCliente: item.perNombre || "",
        actaDireccionCliente: item.perDireccion || "",
        actaTelCliente: item.perCelular || [],
        actaEmailCliente: item.perEmail || [],
        actaCiudad: item.ciudad || "",
        actaSector: item.sector ? [item.sector] : [],
      }));
    }

    funcionAalerta();
    setitemSocket({ tipo: "", item: {} });
    // eslint-disable-next-line
  }, [itemSocket]);

  useEffect(() => {
    // const getequipoData = async () => {
    //   try {
    //     const resequipoData = await clienteAxios("/maquinarias/filtro/0");
    //     setequipoData(resequipoData.data.data);
    //   } catch (error) {
    //     setequipoData([]);
    //   }
    // };
    // if (!equipoData) {
    //   getequipoData();
    // }

    setitem(ObjectoActivo);
    setTimeout(() => {
      setmodificado(false);
    }, 500);

    // eslint-disable-next-line
  }, [ObjectoActivo]);

  const [planesData, setplanesData] = useState([]);
  useEffect(() => {
    const getplanesData = async () => {
      try {
        const resplanesData = await clienteAxios.get(
          `planes/filtroIsEstadoActual/0?tipo=${item.actaTipo}`
        );
        setplanesData(resplanesData.data.data);
      } catch (error) {
        setplanesData([]);
      }
    };
    getplanesData();
  }, [item.actaTipo]);
  const [ciudadesData, setciudadesData] = useState(null);
  const [sectoresData, setsectoresData] = useState([]);

  useEffect(() => {
    if (!ciudadesData) {
      const getciudadesData = async () => {
        try {
          const resciudadesData = await clienteAxios.get(`/ciudades/filtro/0`);

          setciudadesData(resciudadesData.data.data);
        } catch (error) {
          setciudadesData([]);
        }
      };
      getciudadesData();
    }
    // eslint-disable-next-line
  }, []);
  const [serviciosData, setserviciosData] = useState(null);
  useEffect(() => {
    if (!serviciosData) {
      const getserviciosData = async () => {
        try {
          const resserviciosData = await clienteAxios.get(
            `/servicios_visita/listar/todos`
          );
          console.log(resserviciosData.data);

          setserviciosData(resserviciosData.data);
        } catch (error) {
          setserviciosData([]);
        }
      };
      getserviciosData();
    }
  }, []);
  const [vendedoresData, setvendedoresData] = useState(null);
  useEffect(() => {
    if (!vendedoresData) {
      const getvendedoresData = async () => {
        try {
          const resvendedoresData = await clienteAxios.get(
            `/proveedores/filtro/0?search&estado=VENDEDOR&tabla=personas&listadoporperfil=true`
          );

          setvendedoresData(resvendedoresData.data.data);
        } catch (error) {
          setvendedoresData([]);
        }
      };
      getvendedoresData();
    }
  }, []);

  const defaultActive = (args) => {
    changeObjetoActivoState(objectDefault);
    setitem(objectDefault);
    setseccionActive("DATOS PRINCIPALESDATOS");
    setopen(false);
    seteditar(false);
    seterror({});
    setcargando(false);
    setseccionActive(seccionDefault);
    setopenDialogClose(false);
    setmodificado(false);
    setimagenes([]);
    settecnicos([]);
  };
  const handleChange = (e) => {
    const res = replaceCaracteres(e.target.value);
    setitem({
      ...item,
      [e.target.name]: res,
    });
  };

  const handleCrear = async () => {
    try {
      if (validacion(item, setitem, seterror)) {
        return mostrarAlerta("Faltan campos obligatorios", "error");
      }
      setcargando(true);
      const resDocumentos = await subirImagenes(imagenes, actaFotos);

      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...item,
          actaIdPersonaEntrega: usuario.id,
          actaEntDoc: usuario.codigo,
          actaEntNombre: usuario.nombre,
          actaFotos: resDocumentos,
          tabla: "acta_entrega_recepcion",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          actaUser: usuario.usuario,
          actaEmpresa: usuario.rucempresa,
        })
      );
      setcargando(false);
      return defaultActive({ eliminarForm: true });
    } catch (error) {
      setcargando(false);
      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  const handleEditar = async () => {
    try {
      if (validacion(item, setitem, seterror)) {
        return mostrarAlerta("Faltan campos obligatorios", "error");
      }
      setcargando(true);
      const resDocumentos = await subirImagenes(imagenes, actaFotos);
      console.log(
        trimPropsItem({
          ...item,
          actaFotos: resDocumentos,
          tabla: "acta_entrega_recepcion",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          actaUser: usuario.usuario,
          actaEmpresa: usuario.rucempresa,
          nombres_tecnicos: tecnicos.join(", "),
        })
      );

      socket.current.emit(
        "client:actualizarData",
        trimPropsItem({
          ...item,
          actaFotos: resDocumentos,
          tabla: "acta_entrega_recepcion",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          actaUser: usuario.usuario,
          actaEmpresa: usuario.rucempresa,
          nombres_tecnicos: tecnicos.join(", "),
        })
      );
      setcargando(false);
      return defaultActive({ eliminarForm: true });
    } catch (error) {
      setcargando(false);
      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  const handleGuardar = () => {
    if (editar) {
      handleEditar();
    } else {
      handleCrear();
    }
  };
  const [editarPersona, setEditarPersona] = useState(false);
  const [openPersona, setOpenPersona] = useState(false);
  const [ObjectoActivoPersona, setObjetoActivoPersona] = useState({
    perISPContratos: [],
    perNombreComercial: "",
    perEmpresa: [],
    perPais: "",
    perProvincia: "",
    perCanton: "",
    perTipoProveedor: "BIENES",
    perTiempoCredito: "0",
    perDocTipo: "",
    perDocNumero: "",
    perPerfil: [],
    perNombre: "",
    perDireccion: "",
    perObligado: "NO",
    perCredito: "NO",
    perTelefono: "0",
    perCelular: ["+593"],
    perEstado: "ACTIVO",
    perObsevacion: "",
    perEmail: ["sin@sincorreo.com"],
    perOtros: ["ZZZ9999"],
    perNickname: "",
    perUser: "",
    perFecReg: "",
    perFoto: "",
    actaUbicacion: {
      longitud: "",
      latitud: "",
    },
    perUbicacion: {
      longitud: "",
      latitud: "",
    },
    perDocumento: "",
    perGenero: "M",
    perRecomendacion: "OTROS",
    perFecNacimiento: "",
    perEspecialidad: "",
    perTitulo: "",
    perSenescyt: "",
    perPersonal: "INTERNO",
    validateEmail: 0,
  });
  const changeObjetoActivoStatePersona = (item) => {
    setObjetoActivoPersona(item);
    // dispatch({
    //   type: CHANGE_ACTIVO,
    //   payload: item,
    // });
  };
  if (!vendedoresData) {
    return null;
  }
  if (!ciudadesData) {
    return null;
  }
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <ModalPersona
          estadoRequest={"CLIENTE"}
          editar={editarPersona}
          seteditar={setEditarPersona}
          setopen={setOpenPersona}
          open={openPersona}
          ObjectoActivo={ObjectoActivoPersona}
          changeObjetoActivoState={changeObjetoActivoStatePersona}
          socket={socket} 
        />
        <ConfirmacionDialog
          open={openDialogClose}
          setopen={() => setopenDialogClose(false)}
          titulo={"Desea mantener los cambios?"}
          categoria="warning"
          botones={[
            {
              tooltip: "Mantener datos",
              texto: "SI",
              funcion: () => {
                defaultActive({ eliminarForm: false });
              },
              disabled: false,
              Icon: Check,
              color: "secondary",
              id: 1,
              ocultar: false,
              tipo: "boton",
              variante: "contained",
              size: "medium",
              sx: { marginRight: ".3rem" },
            },
            {
              tooltip: "Vaciar datos",
              texto: "NO",
              funcion: () => {
                defaultActive({ eliminarForm: true });
              },
              disabled: false,
              Icon: Close,
              color: "error",
              id: 2,
              ocultar: false,
              tipo: "boton",
              variante: "contained",
              size: "medium",
              sx: {},
            },
          ]}
        />
        <Draggable handle="#handle">
          <Box display="flex" justifyContent="center">
            <div
              style={{
                borderRadius: "15px",
                position: "absolute",
                overflow: "scroll",
                maxHeight: "95vh",
              }}
              className={clases.paper}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Typography
                    style={{ cursor: "-webkit-grab" }}
                    id="handle"
                    variant="button"
                    color="initial"
                    align="center"
                  >
                    {`${editar ? "Editar" : "Agregar"} Actividad.`}
                  </Typography>
                  <Tooltip title={"Guardar"} followCursor placement="top-start">
                    <IconButton
                      onClick={() => {
                        if (validacion(item, setitem, seterror)) {
                          return mostrarAlerta(
                            "Faltan campos obligatorios",
                            "error"
                          );
                        }

                        if (
                          ObjectoActivo.actaEstado !== "PROCESADO" &&
                          item.actaEstado === "PROCESADO" &&
                          !item.nombres_tecnicos &&
                          editar &&
                          seccionActive !== "TECNICOS"
                        ) {
                          return setseccionActive("TECNICOS");
                        }

                        if (
                          tecnicos.length === 0 ||
                          (tecnicos.some((tec) => tec.trim() === "") &&
                            seccionActive === "TECNICOS")
                        ) {
                          if (
                            ObjectoActivo.actaEstado !== "PROCESADO" &&
                            item.actaEstado === "PROCESADO"
                          ) {
                            seterror({ nombres_tecnicos: true });
                            return mostrarAlerta(
                              "Ingrese al menos un técnico",
                              "error"
                            );
                          } else {
                          }
                        }
                        handleGuardar();
                      }}
                    >
                      <Save color="secondary" />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Box display={"flex"} alignItems={"center"}>
                  <TextField
                    disabled={seccionActive === "TECNICOS"}
                    label="Estado"
                    error={error.actaEstado}
                    value={actaEstado}
                    name="actaEstado"
                    onChange={(e) => handleChange(e)}
                    size="small"
                    select
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ displayEmpty: true }}
                  >
                    <MenuItem value="">Seleccione.</MenuItem>
                    {[
                      "PENDIENTE",
                      "EN PROCESO",
                      "SUSPENDIDO",
                      "PROCESADO",
                      "ANULADO",
                    ].map((item) => {
                      return (
                        <MenuItem
                          key={item}
                          value={item}
                          sx={{
                            color:
                              item === "ANULADO"
                                ? "red"
                                : item === "EN PROCESO"
                                ? "blue"
                                : item === "SUSPENDIDO"
                                ? "purple"
                                : item === "PROCESADO"
                                ? "green"
                                : item === "PENDIENTE"
                                ? "orange"
                                : "",
                          }}
                        >
                          {item}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                  <IconButton
                    onClick={() => {
                      if (seccionActive === "TECNICOS") {
                        seterror({});
                        setseccionActive("DATOS PRINCIPALES");
                      } else {
                        defaultActive();
                      }
                    }}
                  >
                    <Close color={modificado ? "error" : "primary"} />
                  </IconButton>
                </Box>
              </Box>
              <Divider />
              <Box display="flex" justifyContent="space-between" width="100%">
                {seccionActive !== "TECNICOS" && (
                  <>
                    <Box display={"flex"} flexWrap={"wrap"}>
                      <Button
                        variant={
                          seccionActive === "DATOS PRINCIPALES"
                            ? "contained"
                            : error.actaDocCliente
                            ? "outlined"
                            : "null"
                        }
                        color={error.actaDocCliente ? "error" : "primary"}
                        disableElevation
                        className={clases.buttonTab}
                        size="small"
                        onClick={() => setseccionActive("DATOS PRINCIPALES")}
                      >
                        DATOS PRINCIPALES
                      </Button>
                      {/* {editar && (
                    <Button
                      variant={
                        seccionActive === "STATUS"
                          ? "contained"
                          : error.actaDocCliente
                          ? "outlined"
                          : "null"
                      }
                      color={error.actaDocCliente ? "error" : "primary"}
                      disableElevation
                      className={clases.buttonTab}
                      size="small"
                      onClick={() => setseccionActive("STATUS")}
                    >
                      STATUS
                    </Button>
                  )} */}

                      <Button
                        variant={
                          seccionActive === "EQUIPOS INSTALADOS"
                            ? "contained"
                            : error.prodPerDocumentoCliente
                            ? "outlined"
                            : "null"
                        }
                        color={
                          error.prodPerDocumentoCliente ? "error" : "primary"
                        }
                        disableElevation
                        className={clases.buttonTab}
                        size="small"
                        onClick={() => setseccionActive("EQUIPOS INSTALADOS")}
                      >
                        DETALLES
                      </Button>

                      <Button
                        variant={
                          seccionActive === "CARGOS ADICIONALES"
                            ? "contained"
                            : error.prodPerDocumentoCliente
                            ? "outlined"
                            : "null"
                        }
                        color={
                          error.prodPerDocumentoCliente ? "error" : "primary"
                        }
                        disableElevation
                        className={clases.buttonTab}
                        size="small"
                        onClick={() => setseccionActive("CARGOS ADICIONALES")}
                      >
                        CONEXIÓN
                      </Button>
                      <Button
                        variant={
                          seccionActive === "FIRMA CLIENTE"
                            ? "contained"
                            : error.actaEntDoc
                            ? "outlined"
                            : "null"
                        }
                        color={error.actaEntDoc ? "error" : "primary"}
                        disableElevation
                        className={clases.buttonTab}
                        size="small"
                        onClick={() => setseccionActive("FIRMA CLIENTE")}
                      >
                        FIRMA CLIENTE
                      </Button>
                      <Button
                        disabled={
                          actaEntFirma === "" && actaSeEncuentraCliente === "SI"
                        }
                        variant={
                          seccionActive === "FIRMA ENTREGA"
                            ? "contained"
                            : error.actaEntDoc
                            ? "outlined"
                            : "null"
                        }
                        color={error.actaEntDoc ? "error" : "primary"}
                        disableElevation
                        className={clases.buttonTab}
                        size="small"
                        onClick={() => setseccionActive("FIRMA ENTREGA")}
                      >
                        FIRMA ENTREGA
                      </Button>
                    </Box>
                  </>
                )}
              </Box>
              <Divider />
              <Box className={clases.container}>
                {seccionActive === "TECNICOS" && (
                  <>
                    {error.nombres_tecnicos && (
                      <Typography
                        variant="body1"
                        color="error"
                        fontWeight={"bold"}
                        textTransform={"uppercase"}
                      >
                        Ingrese al menos un tecnico
                      </Typography>
                    )}
                    <CamposAcumulablesTecnicos
                      settecnicos={settecnicos}
                      error={error.nombres_tecnicos}
                      tecnicos={tecnicos}
                    />
                  </>
                )}
                {seccionActive === "DATOS PRINCIPALES" && (
                  <>
                    <FormControl fullWidth className={clases.formD}>
                      <Autocomplete
                        filterOptions={(x) => x}
                        options={clientesData.map((item) => {
                          return (
                            item.perDocNumero +
                            "/+/" +
                            item.perNombre +
                            "/+/" +
                            item.perDocTipo
                          );
                        })}
                        getOptionLabel={(option) => {
                          return option.replace("/+/", " ").replace("/+/", " ");
                        }}
                        id="controlled-demo"
                        value={
                          (actaDocCliente + " " + actaNombreCliente).trim() ===
                          ""
                            ? ""
                            : actaDocCliente + " " + actaNombreCliente
                        }
                        paperprops
                        onChange={async (event, newValue) => {
                          if (newValue) {
                            const options = newValue.split("/+/");

                            const findItem = clientesData.find(
                              (clienteData) =>
                                clienteData.perDocNumero === options[0]
                            );

                            setitem({
                              ...item,
                              actaIdCliente: findItem.perId,
                              actaDocCliente: findItem.perDocNumero,
                              actaNombreCliente: findItem.perNombre,
                              actaDireccionCliente: findItem.perDireccion,
                              actaTelCliente: findItem.perCelular,
                              actaEmailCliente: findItem.perEmail,
                              actaCiudad: findItem.ciudad,
                              actaSector: findItem.sector
                                ? [findItem.sector]
                                : [findItem.sector],
                            });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            required
                            label={"Cliente"}
                            fullWidth
                            error={error.actaDocCliente}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  <IconButton
                                    size="small"
                                    aria-label=""
                                    onClick={async () => {
                                      // seteditarProveedor(false);
                                      setOpenPersona(true);
                                    }}
                                  >
                                    <AddCircleOutlineTwoTone color="primary" />
                                  </IconButton>

                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                            onChange={async (e) => {
                              if (e.target.value === "") {
                                return mostrarAlerta(
                                  "Ingrese un documento",
                                  "error"
                                );
                              }
                              try {
                                const res = await clienteAxios.get(
                                  `/proveedores/agregarperfil/0?search=${e.target.value}`
                                );
                                if (!res.data.data) {
                                  return mostrarAlerta(res?.data?.msg, "error");
                                }

                                setclientesData(res.data.data);
                              } catch (error) {}
                            }}
                          />
                        )}
                      />
                    </FormControl>
                    <TextField
                      disabled
                      label="Dirección Cliente"
                      error={error.actaDireccionCliente}
                      value={actaDireccionCliente}
                      name="actaDireccionCliente"
                      onChange={(e) => handleChange(e)}
                      className={clases.formD}
                      size="small"
                    />
                    <CamposAcumulables
                      error={error}
                      propiedadTexto={"actaEmailCliente"}
                      item={item}
                      setitem={setitem}
                      tipoCampos="text"
                      etiqueta={"Correos"}
                      etiquetaCampo={"Correo"}
                    />
                    <TextField
                      disabled
                      label="Tel. Cliente"
                      error={error.actaTelCliente}
                      value={actaTelCliente.join(", ")}
                      name="actaTelCliente"
                      onChange={(e) => handleChange(e)}
                      className={clases.formD}
                      size="small"
                    />
                    <FormControl fullWidth className={clases.formD}>
                      <Autocomplete
                        options={ciudadesData.map((item) => {
                          return item.ciuNombre;
                        })}
                        getOptionLabel={(option) => {
                          return option;
                        }}
                        id="controlled-demo"
                        value={actaCiudad}
                        paperprops
                        onChange={async (event, newValue) => {
                          if (newValue) {
                            const itemF = ciudadesData.find(
                              (ciudad) => ciudad.ciuNombre === newValue
                            );
                            setsectoresData(itemF.ciuSectores);
                            setitem({ ...item, actaCiudad: newValue });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            required
                            label="Ciudad"
                            name="actaCiudad"
                            error={error.actaCiudad}
                            fullWidth
                            InputProps={{
                              ...params.InputProps,
                            }}
                          />
                        )}
                      />
                    </FormControl>
                    <CamposAcumulablesSector
                      sectoresData={sectoresData}
                      actaSector={actaSector}
                      error={error.actaSector}
                      setitem={setitem}
                    />
                    {/* <FormControl fullWidth className={clases.formD}>
                      <Autocomplete
                        options={sectoresData.map((item) => {
                          return item.nombre;
                        })}
                        getOptionLabel={(option) => {
                          return option;
                        }}
                        id="controlled-demo"
                        value={actaSector}
                        paperprops
                        onChange={async (event, newValue) => {
                          if (newValue) {
                            setitem({ ...item, actaSector: newValue });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            required
                            label="Sector"
                            name="actaCiudad"
                            error={error.actaSector}
                            fullWidth
                            InputProps={{
                              ...params.InputProps,
                            }}
                          />
                        )}
                      />
                    </FormControl> */}
                    <TextField
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      size="small"
                      id="latitud"
                      label="Ubicación GPS"
                      value={
                        actaUbicacion.latitud + ", " + actaUbicacion.longitud
                      }
                      name="latitud"
                      error={error.latitud}
                      InputProps={{
                        endAdornment: (
                          <Box htmlFor="contained-button-documento" fullWidth>
                            <InputAdornment>
                              <Tooltip title="Mostrar Mapa" arrow>
                                <Box>
                                  <IconButton
                                    aria-label=""
                                    onClick={() => setopenMap(true)}
                                  >
                                    <Map color="secondary" />
                                  </IconButton>
                                </Box>
                              </Tooltip>
                            </InputAdornment>
                          </Box>
                        ),
                      }}
                    />
                    <DialogMap
                      open={openMap}
                      setopen={setopenMap}
                      campo={"actaUbicacion"}
                      setitem={setitem}
                      item={item}
                      editar={editar}
                    />
                    <TextField
                      label="Tipo"
                      error={error.actaTipo}
                      value={actaTipo}
                      name="actaTipo"
                      onChange={(e) => handleChange(e)}
                      className={clases.formD}
                      size="small"
                      select
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ displayEmpty: true }}
                    >
                      {["HOME", "EMPRESARIAL", "CORPORATIVO"].map((item) => {
                        return <MenuItem value={item}>{item} </MenuItem>;
                      })}
                    </TextField>
                    <TextField
                      label="Opción instalación:"
                      error={error.actaOpcion}
                      value={actaOpcion}
                      name="actaOpcion"
                      onChange={(e) => {
                        if (
                          e.target.value !== "Nueva Instalación" &&
                          usuario.rol.length === 1 &&
                          usuario.rol.includes("VENDEDOR") &&
                          usuario.ciudad === "QUININDE"
                        ) {
                          return;
                        }
                        handleChange(e);
                      }}
                      className={clases.formD}
                      size="small"
                      select
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ displayEmpty: true }}
                    >
                      {[
                        "Nueva Instalación",
                        "Visita Técnica",
                        "Soporte Remoto",
                      ].map((item) => {
                        return <MenuItem value={item}>{item} </MenuItem>;
                      })}
                    </TextField>
                    {/* <FormControl
                      component="fieldset"
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <FormLabel
                          component="span"
                          color="primary"
                          style={{
                            marginRight: ".5rem",
                            fontSize: ".8rem",
                            fontWeight: "bold",
                          }}
                          className={error.actaOpcion ? clases.error : ""}
                        >
                          Opción instalación:
                        </FormLabel>
                        <RadioGroup
                          onChange={(e) => handleChange(e)}
                          row
                          aria-label="gender"
                          value={actaOpcion}
                          name="actaOpcion"
                        >
                          <FormControlLabel
                            value="Nueva Instalación"
                            control={<Radio color="secondary" />}
                            label="Nueva Instalación"
                          />
                          <FormControlLabel
                            disabled={
                              usuario.rol.length === 1 &&
                              usuario.rol.includes("VENDEDOR") &&
                              usuario.ciudad === "QUININDE"
                            }
                            value="Visita Técnica"
                            control={<Radio color="secondary" />}
                            label="Visita Técnica"
                          />
                          <FormControlLabel
                            disabled={
                              usuario.rol.length === 1 &&
                              usuario.rol.includes("VENDEDOR") &&
                              usuario.ciudad === "QUININDE"
                            }
                            value="Soporte Remoto"
                            control={<Radio color="secondary" />}
                            label="Soporte Remoto"
                          />
                        </RadioGroup>
                      </Box>
                    </FormControl> */}
                    {/* <TextField
                      label="Visita Tecnica"
                      error={error.actaVisitaTecnica}
                      value={actaVisitaTecnica}
                      name="actaVisitaTecnica"
                      onChange={(e) => handleChange(e)}
                      className={clases.formD}
                      size="small"
                      select
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ displayEmpty: true }}
                    >
                      <MenuItem value="">Seleccione.</MenuItem>
                      {["EMPRESARIAL", "CORPORATIVO", "HOME"].map((item) => {
                        return <MenuItem value={item}>{item} </MenuItem>;
                      })}
                    </TextField> */}
                    {(actaOpcion === "Visita Técnica" ||
                      actaOpcion === "Soporte Remoto") && (
                      <>
                        {/* <TextField
                          label="Servicios Visita"
                          error={error.actaServiciosVisita}
                          value={actaServiciosVisita}
                          name="actaServiciosVisita"
                          onChange={(e) => handleChange(e)}
                          className={clases.formD}
                          size="small"
                          select
                          InputLabelProps={{ shrink: true }}
                          SelectProps={{ displayEmpty: true }}
                        >
                          <MenuItem value="">Seleccione.</MenuItem>
                          {[
                            "CAMBIO DE CLAVE",
                            "CAMBIO DE TECNOLOGIA",
                            "CONFIGURACION DE EQUIPOS",
                            "INTERMITENCIA",
                            "OTROS",
                            "REPARACION",
                            "RETIRO DE EQUIPOS",
                            "SIN ACCESO",
                            "TRASLADOS",
                          ].map((item) => {
                            return <MenuItem value={item}>{item} </MenuItem>;
                          })}
                        </TextField> */}
                        <FormControl fullWidth className={clases.formD}>
                          <Autocomplete
                            options={serviciosData.map((item) => {
                              return item.nombre;
                            })}
                            getOptionLabel={(option) => {
                              return option;
                            }}
                            id="controlled-demo"
                            value={actaServiciosVisita}
                            paperprops
                            onChange={async (event, newValue) => {
                              if (newValue) {
                                setitem({
                                  ...item,
                                  actaServiciosVisita: newValue,
                                });
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                required
                                label="Servicios"
                                name="actaServiciosVisita"
                                error={error.actaServiciosVisita}
                                fullWidth
                                InputProps={{
                                  ...params.InputProps,
                                }}
                              />
                            )}
                          />
                        </FormControl>
                      </>
                    )}
                    {actaOpcion === "Nueva Instalación" && (
                      <TextField
                        type="date"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Fecha Instalación"
                        error={error.actaFechaInstalacion}
                        value={actaFechaInstalacion.substr(0, 10)}
                        name="actaFechaInstalacion"
                        onChange={(e) => handleChange(e)}
                        className={clases.formD}
                        size="small"
                      />
                    )}

                    {/* <TextField
                      label="La solución de su requerimiento se realizó de manera:"
                      error={error.actaSolucionReq}
                      value={actaSolucionReq}
                      name="actaSolucionReq"
                      onChange={(e) => handleChange(e)}
                      className={clases.formD}
                      size="small"
                      select
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ displayEmpty: true }}
                    >
                      <MenuItem value="">Seleccione.</MenuItem>
                      {["EXCELENTE", "MUY BUENO", "BUENO", "MALO"].map(
                        (item) => {
                          return <MenuItem value={item}>{item} </MenuItem>;
                        }
                      )}
                    </TextField> */}
                    {actaOpcion === "Nueva Instalación" && (
                      <>
                        <FormControl fullWidth className={clases.formD}>
                          <Autocomplete
                            autoComplete="false"
                            options={vendedoresData.map(
                              (item) =>
                                item.perId +
                                "/+/" +
                                item.perDocNumero +
                                "/+/" +
                                item.perNombre
                            )}
                            getOptionLabel={(option) => {
                              return option
                                .replace("/+/", " ** ")
                                .replace("/+/", " ** ");
                            }}
                            id="controlled-demo"
                            value={
                              (
                                actaIdVendedor +
                                " " +
                                actaDocVendedor +
                                " " +
                                actaNombreVendedor
                              ).trim() === ""
                                ? ""
                                : actaIdVendedor +
                                  " ** " +
                                  actaDocVendedor +
                                  " ** " +
                                  actaNombreVendedor
                            }
                            paperprops
                            onChange={async (event, newValue) => {
                              if (newValue) {
                                const options = newValue.split("/+/");
                                setitem({
                                  ...item,
                                  actaIdVendedor: options[0],
                                  actaDocVendedor: options[1],
                                  actaNombreVendedor: options[2],
                                });
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                required
                                autoComplete={false}
                                label="Vendedor"
                                error={error.actaDocVendedor}
                                fullWidth
                                onChange={async (e) => {}}
                                InputProps={{
                                  ...params.InputProps,
                                }}
                              />
                            )}
                          />
                        </FormControl>
                        <FormControl fullWidth className={clases.formD}>
                          <Autocomplete
                            autoComplete="false"
                            options={planesData.map(
                              (item) =>
                                item.planNombre +
                                "/+/" +
                                item.planValor +
                                "/+/" +
                                item.planMegas +
                                "/+/" +
                                item.planId
                            )}
                            getOptionLabel={(option) => {
                              return option
                                .replace("/+/", " ** $")
                                .replace("/+/", " ** ")
                                .replace("/+/", " MB/+/")
                                .split("/+/")[0];
                            }}
                            id="controlled-demo"
                            value={
                              (
                                planNombreInternet +
                                " " +
                                planValorInternet +
                                " " +
                                planMegasInternet
                              ).trim() === ""
                                ? ""
                                : planNombreInternet +
                                  " ** $" +
                                  planValorInternet +
                                  " ** " +
                                  planMegasInternet +
                                  " MB"
                            }
                            paperprops
                            onChange={async (event, newValue) => {
                              if (newValue) {
                                const options = newValue.split("/+/");

                                setitem({
                                  ...item,
                                  planNombreInternet: options[0],
                                  planValorInternet: options[1],
                                  planMegasInternet: options[2],
                                  actaIdPlanInternet: options[3],
                                });
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                required
                                autoComplete={false}
                                label="Plan"
                                error={error.planNombreInternet}
                                fullWidth
                                onChange={async (e) => {}}
                                InputProps={{
                                  ...params.InputProps,
                                }}
                              />
                            )}
                          />
                        </FormControl>
                      </>
                    )}
                    <TextField
                      multiline
                      rows={2}
                      variant="outlined"
                      label="Observación Ventas"
                      error={error.actaCalidadIns}
                      value={actaCalidadIns}
                      name="actaCalidadIns"
                      onChange={(e) => handleChange(e)}
                      className={clases.formAllW}
                      size="small"
                    />
                    {editar && (
                      <TextField
                        multiline
                        rows={2}
                        variant="outlined"
                        label="Observación Técnico"
                        error={error.actaDetalleEstado}
                        value={actaDetalleEstado}
                        name="actaDetalleEstado"
                        onChange={(e) => handleChange(e)}
                        className={clases.formAllWidth}
                        size="small"
                      />
                    )}

                    <Dropzone
                      imagenes={imagenes}
                      setimagenes={(e) => {
                        setmodificoImagenes(true);
                        setimagenes(e);
                      }}
                      fotosItem={actaFotos}
                    />
                  </>
                )}
                {seccionActive === "STATUS" && <></>}
                {seccionActive === "EQUIPOS INSTALADOS" && (
                  <>
                    {" "}
                    <FormControl
                      component="fieldset"
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formAllW}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <FormLabel
                          component="span"
                          color="primary"
                          style={{
                            marginRight: ".5rem",
                            fontSize: ".8rem",
                            fontWeight: "bold",
                          }}
                          className={
                            error.actaClienteRegulador ? clases.error : ""
                          }
                        >
                          EL CLIENTE POSEE REGULADOR DE VOLTAJE:
                        </FormLabel>
                        <RadioGroup
                          onChange={(e) => handleChange(e)}
                          row
                          aria-label="gender"
                          value={actaClienteRegulador}
                          name="actaClienteRegulador"
                        >
                          <FormControlLabel
                            value="SI"
                            control={<Radio />}
                            label="SI"
                          />
                          <FormControlLabel
                            value="NO"
                            control={<Radio />}
                            label="NO"
                          />
                        </RadioGroup>
                      </Box>
                    </FormControl>
                    <FormControl
                      component="fieldset"
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <FormLabel
                          component="span"
                          color="primary"
                          style={{
                            marginRight: ".5rem",
                            fontSize: ".8rem",
                            fontWeight: "bold",
                          }}
                          className={error.actaConfigRouter ? clases.error : ""}
                        >
                          CONFIGURACION ROUTER:
                        </FormLabel>
                        <RadioGroup
                          onChange={(e) => handleChange(e)}
                          row
                          aria-label="gender"
                          value={actaConfigRouter}
                          name="actaConfigRouter"
                        >
                          <FormControlLabel
                            value="SI"
                            control={<Radio />}
                            label="SI"
                          />
                          <FormControlLabel
                            value="NO"
                            control={<Radio />}
                            label="NO"
                          />
                        </RadioGroup>
                      </Box>
                    </FormControl>
                    {actaConfigRouter === "SI" && (
                      <TextField
                        type="number"
                        label="Valor Router"
                        error={error.actaValorRouter}
                        value={actaValorRouter}
                        name="actaValorRouter"
                        onChange={(e) => handleChange(e)}
                        className={clases.formD}
                        size="small"
                      />
                    )}
                    <FormControl
                      component="fieldset"
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <FormLabel
                          component="span"
                          color="primary"
                          style={{
                            marginRight: ".5rem",
                            fontSize: ".8rem",
                            fontWeight: "bold",
                          }}
                          className={error.actaCambioPatch ? clases.error : ""}
                        >
                          CAMBIO DE PATCH CORD FIBRA:
                        </FormLabel>
                        <RadioGroup
                          onChange={(e) => handleChange(e)}
                          row
                          aria-label="gender"
                          value={actaCambioPatch}
                          name="actaCambioPatch"
                        >
                          <FormControlLabel
                            value="SI"
                            control={<Radio />}
                            label="SI"
                          />
                          <FormControlLabel
                            value="NO"
                            control={<Radio />}
                            label="NO"
                          />
                        </RadioGroup>
                      </Box>
                    </FormControl>
                    {actaCambioPatch === "SI" && (
                      <TextField
                        type="number"
                        label="Valor Patch"
                        error={error.actaValorPatch}
                        value={actaValorPatch}
                        name="actaValorPatch"
                        onChange={(e) => handleChange(e)}
                        className={clases.formD}
                        size="small"
                      />
                    )}
                    {editar && (
                      <>
                        <Divider className={clases.formAllW}>
                          <Typography variant="subtitle1" color="secondary">
                            EQUIPOS INSTALADOS
                          </Typography>
                        </Divider>
                        <Paper sx={{ width: "100%" }}>
                          <ToolBarTable
                            count={actaEquiposInstalados.length}
                            rowsPerPage={rowsPerPageEquiposInstalado}
                            page={pageEquiposInstalado}
                            handleChangePage={handleChangePageEquiposInstalado}
                            handleChangeRowsPerPage={
                              handleChangeRowsPerPageEquiposInstalado
                            }
                            ocultarPaginacion={false}
                            simplePagination={true}
                            botonesIzquierdos={[
                              {
                                tooltip: "Agregar",
                                texto: "Agregar",
                                funcion: () => {
                                  setitem((prev) => {
                                    const actaEquiposInstalados = [
                                      {
                                        ...objectDefaultEquiposInstalado,
                                        id: v4(),
                                        nuevo: true,
                                      },
                                      ...prev.actaEquiposInstalados.map(
                                        (itemEquiposInstalado) => ({
                                          ...itemEquiposInstalado,
                                          order: itemEquiposInstalado.order + 1,
                                        })
                                      ),
                                    ];
                                    return {
                                      ...prev,
                                      actaEquiposInstalados,
                                    };
                                  });
                                },
                                disabled: false,
                                Icon: AddCircle,
                                color: "primary",
                                id: 1,
                                ocultar: false,
                                tipo: "boton",
                                variante: "contained",
                                size: "medium",
                                sx: {},
                              },
                            ]}
                            botonesDerechos={[]}
                          />

                          <TableContainer>
                            <Table
                              stickyHeader
                              aria-label="sticky table"
                              size="small"
                            >
                              <TableHead>
                                <TablaCabecera
                                  columns={columnsEquiposInstalados}
                                  habilitarOrdenar={false}
                                />
                              </TableHead>

                              <TableBody component={"div"}>
                                {actaEquiposInstalados
                                  .sort((a, b) => a.order - b.order)
                                  .map((rowHijo, index) => {
                                    return (
                                      <RowEquiposInstalado
                                        editarPadre={editar}
                                        setitemPadre={setitem}
                                        itemPadre={item}
                                        rowHijo={rowHijo}
                                        index={index}
                                        key={rowHijo.id}
                                        // Draggable
                                        handleDragStart={handleDragStart}
                                        handleDragEnter={handleDragEnter}
                                        handleDragEnd={handleDragEnd}
                                      />
                                    );
                                  })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Paper>
                      </>
                    )}
                    <Divider className={clases.formAllW}>
                      <Typography variant="subtitle1" color="secondary">
                        CARGOS ADICIONALES
                      </Typography>
                    </Divider>{" "}
                    <Paper sx={{ width: "100%" }}>
                      <ToolBarTable
                        count={actaCargosAdicionales.length}
                        rowsPerPage={rowsPerPageCargo}
                        page={pageCargo}
                        handleChangePage={handleChangePageCargo}
                        handleChangeRowsPerPage={handleChangeRowsPerPageCargo}
                        ocultarPaginacion={false}
                        simplePagination={true}
                        botonesIzquierdos={[
                          {
                            tooltip: "Agregar",
                            texto: "Agregar",
                            funcion: () => {
                              setitem((prev) => {
                                const actaCargosAdicionales = [
                                  {
                                    ...objectDefaultCargo,
                                    id: v4(),
                                    nuevo: true,
                                  },
                                  ...prev.actaCargosAdicionales.map(
                                    (itemCargo) => ({
                                      ...itemCargo,
                                      order: itemCargo.order + 1,
                                    })
                                  ),
                                ];
                                return {
                                  ...prev,
                                  actaCargosAdicionales,
                                };
                              });
                            },
                            disabled: false,
                            Icon: AddCircle,
                            color: "primary",
                            id: 1,
                            ocultar: false,
                            tipo: "boton",
                            variante: "contained",
                            size: "medium",
                            sx: {},
                          },
                        ]}
                        botonesDerechos={[]}
                      />

                      <TableContainer>
                        <Table
                          stickyHeader
                          aria-label="sticky table"
                          size="small"
                        >
                          <TableHead>
                            <TablaCabecera
                              columns={columnsCargo}
                              habilitarOrdenar={false}
                            />
                          </TableHead>

                          <TableBody component={"div"}>
                            {actaCargosAdicionales
                              .sort((a, b) => a.order - b.order)
                              .map((rowHijo, index) => {
                                return (
                                  <RowCargo
                                    editarPadre={editar}
                                    setitemPadre={setitem}
                                    itemPadre={item}
                                    rowHijo={rowHijo}
                                    index={index}
                                    key={rowHijo.id}
                                    // Draggable
                                    handleDragStart={handleDragStart}
                                    handleDragEnter={handleDragEnter}
                                    handleDragEnd={handleDragEnd}
                                  />
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>
                  </>
                )}
                {seccionActive === "CARGOS ADICIONALES" && (
                  <>
                    <TextField
                      label="PPOE"
                      error={error.actaRedWifi}
                      value={actaRedWifi}
                      name="actaRedWifi"
                      onChange={(e) => handleChange(e)}
                      className={clases.formD}
                      size="small"
                    />
                    <TextField
                      label="Clave"
                      error={error.actaClave}
                      value={actaClave}
                      name="actaClave"
                      onChange={(e) => handleChange(e)}
                      className={clases.formD}
                      size="small"
                    />
                  </>
                )}
                {seccionActive === "FIRMA CLIENTE" && (
                  <>
                    <FormControl
                      component="fieldset"
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <FormLabel
                          component="span"
                          color="primary"
                          style={{
                            marginRight: ".5rem",
                            fontSize: ".8rem",
                            fontWeight: "bold",
                          }}
                          className={
                            error.actaSeEncuentraCliente ? clases.error : ""
                          }
                        >
                          Se encuentra el cliente:
                        </FormLabel>
                        <RadioGroup
                          onChange={(e) => handleChange(e)}
                          row
                          aria-label="gender"
                          value={actaSeEncuentraCliente}
                          name="actaSeEncuentraCliente"
                        >
                          <FormControlLabel
                            value="SI"
                            control={<Radio />}
                            label="SI"
                          />
                          <FormControlLabel
                            value="NO"
                            control={<Radio />}
                            label="NO"
                          />
                        </RadioGroup>
                      </Box>
                    </FormControl>
                    <Firma
                      setitem={setitem}
                      propiedad={"actaEntFirma"}
                      valor={actaEntFirma}
                      label={"Firma Cliente"}
                    />
                  </>
                )}
                {seccionActive === "FIRMA ENTREGA" && (
                  <>
                    <FormControl fullWidth className={clases.formAllW}>
                      <Autocomplete
                        filterOptions={(x) => x}
                        disabled
                        options={clientesData.map((item) => {
                          return (
                            item.perDocNumero +
                            "/+/" +
                            item.perNombre +
                            "/+/" +
                            item.perDocTipo
                          );
                        })}
                        getOptionLabel={(option) => {
                          return option.replace("/+/", " ").replace("/+/", " ");
                        }}
                        id="controlled-demo"
                        value={
                          (usuario.codigo + " " + usuario.nombre).trim() === ""
                            ? ""
                            : usuario.codigo + " " + usuario.nombre
                        }
                        paperprops
                        onChange={async (event, newValue) => {
                          if (newValue) {
                            const options = newValue.split("/+/");

                            const findItem = clientesData.find(
                              (clienteData) =>
                                clienteData.perDocNumero === options[0]
                            );
                            setitem({
                              ...item,
                              actaIdPersonaEntrega: findItem.perId,
                              actaEntDoc: findItem.perDocNumero,
                              actaEntNombre: findItem.perNombre,
                            });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            required
                            label={"Persona Entrega"}
                            fullWidth
                            error={error.actaEntDoc}
                            onChange={async (e) => {
                              if (e.target.value === "") {
                                return mostrarAlerta(
                                  "Ingrese un documento",
                                  "error"
                                );
                              }
                              try {
                                const res = await clienteAxios.get(
                                  `/proveedores/agregarperfil/0?search=${e.target.value}`
                                );
                                if (!res.data.data) {
                                  return mostrarAlerta(res?.data?.msg, "error");
                                }

                                setclientesData(res.data.data);
                              } catch (error) {}
                            }}
                          />
                        )}
                      />
                    </FormControl>
                    <Firma
                      setitem={setitem}
                      propiedad={"actaRecFirma"}
                      valor={actaRecFirma}
                      label={"Firma Entrega"}
                    />
                  </>
                )}
              </Box>
              <Divider />
              <Box display="flex" width="100%" justifyContent="space-between">
                <Box width="100%" display="flex" justifyContent="flex-end">
                  <Button
                    style={{ width: "30%" }}
                    variant="contained"
                    color="primary"
                    disabled={cargando}
                    onClick={() => {
                      if (validacion(item, setitem, seterror)) {
                        return mostrarAlerta(
                          "Faltan campos obligatorios",
                          "error"
                        );
                      }

                      if (
                        ObjectoActivo.actaEstado !== "PROCESADO" &&
                        item.actaEstado === "PROCESADO" &&
                        !item.nombres_tecnicos &&
                        editar &&
                        seccionActive !== "TECNICOS"
                      ) {
                        return setseccionActive("TECNICOS");
                      }

                      if (
                        tecnicos.length === 0 ||
                        (tecnicos.some((tec) => tec.trim() === "") &&
                          seccionActive === "TECNICOS")
                      ) {
                        if (
                          ObjectoActivo.actaEstado !== "PROCESADO" &&
                          item.actaEstado === "PROCESADO"
                        ) {
                          seterror({ nombres_tecnicos: true });
                          return mostrarAlerta(
                            "Ingrese al menos un técnico",
                            "error"
                          );
                        } else {
                        }
                      }
                      handleGuardar();
                    }}
                  >
                    Guardar
                  </Button>
                </Box>
              </Box>
            </div>
          </Box>
        </Draggable>
      </Box>
    </Modal>
  );
};

export default memo(ModalAnticipos);
