import React, { memo, useContext, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  DeleteForever,
  Edit,
  MenuBook,
  PictureAsPdf,
  Refresh,
} from "@mui/icons-material";
import {
  Box,
  TextField,
  MenuItem,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  DialogTitle,
  Dialog,
} from "@mui/material";
import AuthContext from "../../../../Context/Auth/AuthContext";
import Cargando from "../../../../components/Extra/Cargando";
import { usePermiso } from "../../../../hooks/usePermiso";
import ConfirmacionDialog from "../../../../components/Extra/ConfirmacionDialog";
import clienteAxios from "../../../../config/axios";
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  delete: {
    backgroundColor: theme.palette.error.main,
  },
  bgPrimary: {
    backgroundColor: theme.palette.primary.dark,
  },
  bgSecondary: {
    backgroundColor: theme.palette.secondary.dark,
  },
  success: {
    backgroundColor: theme.palette.success.main,
  },
}));
const ExportDialog = (props) => {
  const { tienePermiso, alertaPermiso } = usePermiso("Personas_Veterinaria");
  const classes = useStyles();
  const { usuario } = useContext(AuthContext);
  const {
    open,
    setopen,
    funcPdf,
    arrayExport,
    tipo,
    settipo,
    campoExport,
    tabla,
    socket,
    desdeOtroLugarMenu,
    rolesSeleccionados,
    rowsImportState,
    rows,
    funcExcel,
  } = props;
  const { mostrarAlerta } = useContext(AlertaContext);
  const [cargando, setcargando] = useState(false);
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  const [openConfirmResetDialog, setopenConfirmResetDialog] = useState(false);
  const handleEditarRoles = async () => {
    if (tienePermiso && !tienePermiso("editar")) {
      return alertaPermiso("editar");
    }
    try {
      await setcargando(true);

      const lista = arrayExport.map((item) => {
        return item[campoExport];
      });
      await clienteAxios.put("/roles/0", {
        lista,
        rolesSeleccionados,
        reset: false,
      });

      const rowsActualizados = rows
        .filter((itemRow) => {
          return lista.some((itemExport) => {
            return itemRow[campoExport] === itemExport;
          });
        })
        .map((itemRow) => {
          return {
            ...itemRow,
            perPerfil: [
              ...new Set([...itemRow.perPerfil, ...rolesSeleccionados]),
            ],
          };
        });

      rowsImportState(rowsActualizados);
      mostrarAlerta("Roles actualizados", "success");
      setcargando(false);
      setopen(false);
    } catch (error) {
      mostrarAlerta("Hubo un error", "error");
    }
  };
  const handleReset = async () => {
    if (tienePermiso && !tienePermiso("editar")) {
      return alertaPermiso("editar");
    }
    await setcargando(true);

    const lista = arrayExport.map((item) => {
      return item[campoExport];
    });
    await clienteAxios.put("/roles/0", {
      lista,
      rolesSeleccionados,
      reset: true,
    });

    const rowsActualizados = rows
      .filter((itemRow) => {
        return lista.some((itemExport) => {
          return itemRow[campoExport] === itemExport;
        });
      })
      .map((itemRow) => {
        return {
          ...itemRow,
          perPerfil: ["CLIENTE"],
        };
      });

    rowsImportState(rowsActualizados);
    mostrarAlerta("Roles actualizados", "success");
    setcargando(false);
    setopen(false);
  };
  const handleEliminar = async () => {
    if (tienePermiso && !tienePermiso("eliminar")) {
      return alertaPermiso("eliminar");
    }
    await setcargando(true);

    await setTimeout(async () => {
      const lista = arrayExport.map((item) => {
        return item[campoExport];
      });

      socket.current.emit("client:eliminarDataSeleccionado", {
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        tabla,
        lista,
      });
      setcargando(false);
      setopen(false);
    }, 500);
  };
  return (
    <>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        titulo={`¿Esta seguro de eliminar estos registros?`}
        funcion={handleEliminar}
      />{" "}
      <ConfirmacionDialog
        open={openConfirmResetDialog}
        setopen={setopenConfirmResetDialog}
        titulo={`Al reiniciar se colocará el usuario ya no tendra ROLES`}
        funcion={handleReset}
      />
      <Dialog
        onClose={() => setopen(false)}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <Box padding={2}>
          <DialogTitle id="simple-dialog-title">Opciones.</DialogTitle>
          {/* <TextField
            id=""
            label=""
            fullWidth
            value={tipo}
            onChange={(e) => {
              settipo(e.target.value);
            }}
            select
          >
            {[
              { value: "seleccion", label: "Seleccion" },
              { value: "todos", label: "Todos" },
            ].map((item) => {
              return <MenuItem value={item.value}>{item.label}</MenuItem>;
            })}
          </TextField> */}
          {cargando ? (
            <Cargando titulo="Cargando." />
          ) : (
            <>
              <List>
                {desdeOtroLugarMenu ? (
                  <>
                    <ListItem
                      button
                      disabled={cargando}
                      onClick={async () => {
                        if (rolesSeleccionados.length === 0) {
                          return mostrarAlerta(
                            "Seleccione al menos un rol",
                            "error"
                          );
                        }
                        handleEditarRoles();
                      }}
                    >
                      <ListItemAvatar>
                        <Avatar className={classes.bgSecondary}>
                          <Edit />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary="Agregar Roles" />
                    </ListItem>
                    <ListItem
                      button
                      disabled={cargando}
                      onClick={async () => {
                        setopenConfirmResetDialog(true);
                      }}
                    >
                      <ListItemAvatar>
                        <Avatar className={classes.delete}>
                          <Refresh />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary="Reiniciar Roles" />
                    </ListItem>{" "}
                  </>
                ) : (
                  <>
                    {" "}
                    <ListItem
                      button
                      disabled={cargando}
                      onClick={async () => {
                        await setcargando(true);

                        await setTimeout(async () => {
                          await funcExcel(true);

                          setcargando(false);
                          setopen(false);
                        }, 500);
                      }}
                    >
                      <ListItemAvatar>
                        <Avatar className={classes.success}>
                          <MenuBook />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary="Exportar (Todo)" />
                    </ListItem>
                    <ListItem
                      button
                      disabled={cargando}
                      onClick={async () => {
                        await setcargando(true);

                        await setTimeout(async () => {
                          await funcExcel();

                          setcargando(false);
                          setopen(false);
                        }, 500);
                      }}
                    >
                      <ListItemAvatar>
                        <Avatar className={classes.success}>
                          <MenuBook />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary="Exportar (Seleccionado)" />
                    </ListItem>
                    <ListItem
                      button
                      disabled={cargando}
                      onClick={async () => {
                        await setcargando(true);

                        await setTimeout(async () => {
                          await funcPdf();

                          setcargando(false);
                          setopen(false);
                        }, 500);
                      }}
                    >
                      <ListItemAvatar>
                        <Avatar className={classes.avatar}>
                          <PictureAsPdf />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary="Exportar (Seleccionado)" />
                    </ListItem>
                    {/* <ListItem
                      button
                      disabled={cargando}
                      onClick={async () => {
                        setopenConfirmDialog(true);
                      }}
                    >
                      <ListItemAvatar>
                        <Avatar className={classes.delete}>
                          <DeleteForever />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary="Eliminar" />
                    </ListItem>{" "} */}
                  </>
                )}
              </List>{" "}
            </>
          )}
        </Box>
      </Dialog>{" "}
    </>
  );
};

export default memo(ExportDialog);
