export const objectDefault = {
  idRegistro: 4,
  nombre_surtidor: "",
  lado: "A", //A O B
  user: "",
  fecReg: "",
  estacion1: {
    numero_pistola: 10,
    codigo_producto: "0101",
    nombre_producto: "EXTRA",
  },
  estacion2: {
    numero_pistola: 11,
    codigo_producto: "0185",
    nombre_producto: "SUPER",
  },
  estacion3: {
    numero_pistola: 12,
    codigo_producto: "0121",
    nombre_producto: "SUPER",
  },
};

export const columns = [
  // {
  //   id: "codigoCombustible",
  //   label: "Código Combustible",
  //   minWidth: 25,
  //   tipo: "string",
  //   propiedad: "codigoCombustible",
  // },
  {
    id: "fechaHora",
    label: "Fecha y Hora",
    minWidth: 25,
    tipo: "fecha",
    propiedad: "fechaHora",
  },
  {
    id: "nombreCombustible",
    label: "Combustible",
    minWidth: 25,
    tipo: "string",
    propiedad: "nombreCombustible",
  },
  {
    id: "pistola",
    label: "Pistola",
    minWidth: 25,
    tipo: "numero",
    propiedad: "pistola",
  },
  {
    id: "numeroTanque",
    label: "Tanque",
    minWidth: 25,
    tipo: "string",
    propiedad: "numeroTanque",
    variante: "select",
    options: [
      { value: 1, label: "SUPER" },
      { value: 2, label: "EXTRA" },
      { value: 3, label: "DIESEL" },
    ],
  },
  {
    id: "precioUnitario",
    label: "Precio Unitario",
    minWidth: 25,
    tipo: "",
    propiedad: "precioUnitario",
    ocultar: true,
  },
  {
    id: "valorTotal",
    label: "Valor Total",
    minWidth: 25,
    tipo: "",
    propiedad: "valorTotal",
    ocultar: true,
  },
  {
    id: "volTotal",
    label: "Total Galones",
    minWidth: 25,
    tipo: "",
    propiedad: "volTotal",
    ocultar: true,
  },
  // {
  //   id: "tiempo",
  //   label: "Tiempo",
  //   minWidth: 25,
  //   tipo: "",
  //   propiedad: "tiempo",
  // ocultar: true,
  // },

  {
    id: "totInicio",
    label: "MAR. INICIO",
    minWidth: 25,
    tipo: "",
    propiedad: "totInicio",
    ocultar: true,
  },
  {
    id: "totFinal",
    label: "MAR. Final",
    minWidth: 25,
    tipo: "",
    propiedad: "totFinal",
    ocultar: true,
  },
  {
    id: "facturado",
    label: "Facturado",
    minWidth: 25,
    tipo: "",
    propiedad: "facturado",
    ocultar: true,
  },
  {
    id: "usuario",
    label: "Usuario",
    minWidth: 25,
    tipo: "",
    propiedad: "usuario",
    ocultar: true,
  },
];
export const columnasHijo = [
  {
    id: "pistola",
    align: "left",
    minWidth: 150,
    tipo: "string",
    label: "pistola",
  },
  {
    id: "producto",
    align: "left",
    minWidth: 150,
    tipo: "string",
    label: "producto",
  },
];
