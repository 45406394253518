import React, { useState, memo } from "react";
import { makeStyles } from "@mui/styles";
import {
  Modal,
  Box,
  Button,
  TextField,
  Divider,
  Typography,
  IconButton,
  useTheme,
  MenuItem,
} from "@mui/material";

import Draggable from "react-draggable";

import { Cancel, Backspace } from "@mui/icons-material";
import CamposBusqueda from "./CamposBusqueda";

function getModalStyle() {
  return {
    borderRadius: "15px",
    position: "absolute",

    overflow: "scroll",
    maxHeight: "80vh",
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",

    width: "50%",
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "15px",
    marginTop: "5vh",
    [theme.breakpoints.down("md")]: {
      width: "70%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      marginTop: "2vh",
    },
  },
  margin: {
    margin: theme.spacing(1),
    width: "45%",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  ocultar: {
    display: "none",
  },
  inputPadding: {
    padding: theme.spacing(0.5),
    margin: theme.spacing(0),
    fontSize: "1rem",
  },
  formD: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "45%",
    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "0",
      width: "45%",
    },
  },
  formAllW: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "95%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
    },
  },
}));

const ModalB = (props) => {
  const {
    openMB,
    setopenMB,
    titulo,
    fullBuscador,
    setfullBuscador,
    arrayProps,
    handleChange,
    funcionB,
    buscando,
    funcionDefault,
  } = props;
  const [arrayOcultarProps, setarrayOcultarProps] = useState([]);
  const defaultActive = () => {
    setfullBuscador({
      ccFecReg1: "",
      ccFecReg2: "",
    });
    setopenMB(false);
  };
  const theme = useTheme();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  function getStylesMenuItems(name, permisos, theme) {
    return {
      fontWeight:
        permisos.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
      backgroundColor:
        permisos.indexOf(name) === -1 ? "white" : theme.palette.primary.main,
    };
  }
  //hook de estilos
  const clases = useStyles();
  //definiendo los estilos del modal
  const [modalStyle] = useState(getModalStyle);

  return (
    <Modal
      open={openMB}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <Draggable handle="#handle">
          <Box display="flex" justifyContent="center">
            <div style={modalStyle} className={clases.paper}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
                style={{ cursor: "-webkit-grab" }}
              >
                <Typography
                  id="handle"
                  variant="button"
                  color="initial"
                  align="center"
                >
                  {titulo}
                </Typography>
                <Box display="flex" alignItems="center">
                  {buscando ? (
                    <IconButton
                      aria-label=""
                      color="secondary"
                      autoFocus
                      onKeyPress={(e) => {}}
                      onClick={async () => {
                        await funcionDefault();
                      }}
                    >
                      <Backspace color="error" />
                    </IconButton>
                  ) : null}
                  <IconButton
                    aria-label=""
                    style={{ margin: "0px" }}
                    onClick={() => {
                      defaultActive();
                    }}
                  >
                    <Cancel color="secondary" />
                  </IconButton>
                </Box>
              </Box>

              <Box display="flex" justifyContent="space-around ">
                {/* <Box>
                {" "}
                <Typography
                  variant="subtitle1"
                  color="primary"
                  component="span"
                >
                  Desde
                </Typography>{" "}
              </Box>
              <Box>
                {" "}
                <Typography
                  variant="subtitle1"
                  color="primary"
                  component="span"
                >
                  Hasta
                </Typography>{" "}
              </Box> */}
              </Box>
              <Divider />

              <Box
                display="flex"
                justifyContent="space-between"
                alignItems=" stretch"
                width="100%"
              >
                <Box></Box>
              </Box>
              <Box
                display="flex"
                flexWrap="wrap"
                alignItems="flex-end"
                mb={2}
                p={1}
              >
                <TextField
                  variant="outlined"
                  className={clases.formAllW}
                  size="small"
                  label="Propiedad"
                  value={arrayOcultarProps}
                  onChange={(e) => {
                    setarrayOcultarProps(e.target.value);
                  }}
                  select
                  MenuProps={MenuProps}
                  // InputLabelProps={{ shrink: true }}
                  SelectProps={{
                    displayEmpty: true,
                    multiple: true,
                    renderValue: (selected) => {
                      return selected
                        .map((value, index) => {
                          return value;
                        })
                        .join(" -- ");
                    },
                  }}
                >
                  <MenuItem disabled value="">
                    Seleccione.
                  </MenuItem>
                  {[...arrayProps]
                    .sort((a, b) => a.orden - b.orden)
                    .map((item) => {
                      return (
                        <MenuItem
                          style={getStylesMenuItems(
                            item.label,
                            arrayOcultarProps,
                            theme
                          )}
                          value={item.label}
                        >
                          {item.label}
                        </MenuItem>
                      );
                    })}
                </TextField>
                {[...arrayProps]
                  .sort((a, b) => a.orden - b.orden)
                  .map((campoObjectItem) => {
                    const existe = arrayOcultarProps.some(
                      (item) => item === campoObjectItem.label
                    );
                    if (!existe) {
                      return null;
                    }
                    return (
                      <CamposBusqueda
                        campoObjectItem={campoObjectItem}
                        handleChange={handleChange}
                        fullBuscador={fullBuscador}
                      />
                    );
                  })}
                {/* <TextField
                InputLabelProps={{ shrink: true }}
                type="date"
                className={clases.formD}
                id="ccFecReg1"
                label="Fecha Registro"
                value={fullBuscador.ccFecReg1}
                name="ccFecReg1"
                onChange={(e) => handleChange(e)}
              />
              <TextField
                InputLabelProps={{ shrink: true }}
                type="date"
                className={clases.formD}
                id="ccFecReg2"
                label="Fecha Registro"
                value={fullBuscador.ccFecReg2}
                name="ccFecReg2"
                onChange={(e) => handleChange(e)}
              /> */}
              </Box>
              {/* <Divider /> */}
              <Box
                display="flex"
                width="100%"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box
                  width="100%"
                  display="flex"
                  justifyContent="flex-end"
                  mt={1}
                >
                  <Button
                    style={{ width: "30%" }}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      funcionB();
                    }}
                  >
                    Buscar
                  </Button>
                </Box>
              </Box>
            </div>
          </Box>
        </Draggable>
      </Box>
    </Modal>
  );
};

export default memo(ModalB);
