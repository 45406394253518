function restarPorcentaje(numero, porcentaje) {
  return tresDecimales(numero - (numero * porcentaje) / 100);
}

const tresDecimales = (numero) => {
  return Number(Number(numero).toFixed(4));
};

const dosDecimales = (numero) => {
  return Number(Number(numero).toFixed(2));
};

export const calcularProducto = ({ producto, formPorcentaje = 0, iva }) => {
  try {
    const {
      cantidad,
      valUnitarioInterno,
      descPorcentaje = 0,
      llevaIva,
      incluyeIva,
    } = producto;

    let resvalorUnitario = 0;

    if (llevaIva === "SI" && incluyeIva === "SI") {
      resvalorUnitario = tresDecimales(valUnitarioInterno / (1 + iva / 100));
      console.log({ resvalorUnitario });
    } else {
      resvalorUnitario = valUnitarioInterno;
    }

    let resdescuento = tresDecimales(resvalorUnitario * (descPorcentaje / 100));
    console.log({ resdescuento });

    let resprecioUnitarioConDescuento = tresDecimales(
      resvalorUnitario - resdescuento
    );
    console.log({ resprecioUnitarioConDescuento });

    let resprecioSubTotalProducto = tresDecimales(
      resprecioUnitarioConDescuento * cantidad
    );
    console.log({ resprecioSubTotalProducto });

    let resrecargo = tresDecimales(
      resprecioSubTotalProducto * (formPorcentaje / 100)
    );
    console.log({ resrecargo });

    resprecioSubTotalProducto = tresDecimales(
      resprecioSubTotalProducto + resrecargo
    );
    console.log({ resprecioSubTotalProducto });

    let resvalorIva = 0;
    if (llevaIva === "SI") {
      resvalorIva = tresDecimales(resprecioSubTotalProducto * (iva / 100));
    }
    console.log({ resvalorIva });

    console.log({
      valorUnitario: dosDecimales(resvalorUnitario),
      descuento: dosDecimales(resdescuento),
      precioSubTotalProducto: dosDecimales(resprecioSubTotalProducto),
      valorIva: dosDecimales(resvalorIva),
      recargoPorcentaje: dosDecimales(formPorcentaje),
      costoProduccion: dosDecimales(resvalorUnitario),
      recargo: dosDecimales(resrecargo),
    });

    const resProducto = {
      ...producto,
      valorUnitario: dosDecimales(resvalorUnitario),
      descuento: dosDecimales(resdescuento),
      precioSubTotalProducto: dosDecimales(resprecioSubTotalProducto),
      valorIva: dosDecimales(resvalorIva),
      recargoPorcentaje: dosDecimales(formPorcentaje),
      costoProduccion: dosDecimales(resvalorUnitario),
      recargo: dosDecimales(resrecargo),
    };
    return resProducto;
  } catch (error) {
    console.log({ error });
    return producto;
  }
};
